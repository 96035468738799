import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/all";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../Components/Buttons/ButtonBootstrap";
import { downloadInvoiceMessageAction } from "../../../Services/actions/BuyerSellerAction";
import { getBuyerSellerDataProcessService, getCustomerDonationDetails } from "../../../Services/apis/BuyerSellerAPIs";
import { BuyerSellerType } from "../../../Services/reducers/states/BuyerSellerType";
import { Collapse } from "../../../Shared/Collapse/Collapse";
import { currencyFormat } from "../../../Utilities/NumberUtility";

export const BuyerSellerFlowView = ({
  onRedirectReceived,
  onRedirectSupport,
}: {
  onRedirectReceived: () => void;
  onRedirectSupport: () => void;
}) => {
  const dispatch = useDispatch();
  const [customerDonationList, setCustomerDonationList] = useState<{
    donationDetails: {
      detailUid: string;
      packageDate: string;
      packageTag: string;
      quantity: number;
      retailer: string;
      retailerLicense: string;
    }[];
  }>({ donationDetails: [] });
  const [dealDonation, setDealDonation] = useState<any>({ });
  const store = useSelector((state: BuyerSellerType) => state.process);

  const [invoiceForm, setInvoiceForm] = useState<{
    details: {
      key: string;
      value: string;
      currency?: boolean;
      text?: boolean;
    }[];
    fees: { key: string; value: string; currency?: boolean }[];
  }>({
    details: [],
    fees: [],
  });

  const handleDownloadInvoice = () => {
    const orderId = store.invoice.invoiceId;
    const messageUid = store?.messageInvoice?.messageUid;
    dispatch(downloadInvoiceMessageAction(messageUid, orderId, () => {}));
  };

  const donationDetails = () => {
    getCustomerDonationDetails(store.keys)
      .then((response) => {
        setCustomerDonationList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const donationDeal = () => {
    getBuyerSellerDataProcessService(store.keys)
      .then((response) => {
        console.log('response', response)
        setDealDonation(response?.data?.deal || {})
        // setCustomerDonationList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  useEffect(() => {
    const fees: { key: string; value: string; currency?: boolean }[] = [
      {
        key: "Delivery",
        value: `${store.invoice.deliveryFees}`,
        currency: true,
      },
      { key: "Service", value: `${store.invoice.serviceFees}`, currency: true },
    ];

    const details: {
      key: string;
      value: string;
      currency?: boolean;
      text?: boolean;
    }[] = [
      {
        key: "Buyer",
        value: `${store.invoice.buyerFacilityName}`,
        currency: false,
        text: true,
      },
      {
        key: "",
        value: `${store.invoice.buyerFacilityLicense}`,
        currency: false,
        text: true,
      },
      {
        key: "Date",
        value: `${store.invoice.invoiceDate}`,
        currency: false,
        text: true,
      },
      {
        key: "Order ID",
        value: `${store.invoice.invoiceId}`,
        currency: false,
        text: true,
      },
    ];
    if (store?.messageOffer?.purchaseOfferPrice) {
      details.push({
        key: "Purchase Offer Price",
        value: `${store?.messageOffer?.purchaseOfferPrice}`,
        currency: true,
      });
      details.push({
        key: "Offer Quantity",
        value: `${store?.messageOffer?.offerQuantity}`,
      });
    }

    setInvoiceForm({ ...invoiceForm, details: details, fees: fees });
  }, [onRedirectSupport]);

  useEffect(() => {
    donationDeal()
  }, [])
  return (
    <React.Fragment>
      <div id={"resume-panel"}>
        <p className={"odt-p"}>
          <span
            className={"odt-font-24 odt-font-family-secondary-bold odt-font-p"}
          >
            {store.name}
          </span>
          <span className={"odt-font-14 odt-font-p"}>{store.label}</span>
          <span className={"odt-font-14 odt-font-p"}>{store.facilityName}</span>
          <span className={"odt-font-14 odt-font-p"}>
            {store.facilityLicenseNumber}
          </span>
        </p>
      </div>

      <div id={"invoice-panel"}>
        <Collapse
          id={"invoice-content"}
          header={(open: boolean, handleOpen: () => void) => {
            return (
              <div
                className={"d-flex justify-content-between odt-mouse-pointer"}
                onClick={handleOpen}
              >
                <span className={"odt-font-24 odt-font-family-primary-bold"}>
                  Invoice
                </span>
                <i className={"odt-font-24"}>
                  {" "}
                  {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}{" "}
                </i>
              </div>
            );
          }}
        >
          <div
            className={
              "row odt-b-invert-col odt-panel odt-font-15 odt-font-family-secondary"
            }
          >
            <div className={"odt-panel-p"}>
              <p className={"odt-p"}>
                {store.hasCoaUpload ? (
                  <span
                    className={"odt-font-regular odt-font-s"}
                  >{`Total THC %: ${store.totalThcPct}`}</span>
                ) : (
                  <span>{`Test Results not available`}</span>
                )}

                <span className={"odt-font-18 odt-font-regular odt-font-s"}>
                  {store.productCategory}
                </span>
                <span
                  className={"odt-font-20 odt-font-bold "}
                >{`$${currencyFormat(store.purchaseAmount, 2)}`}</span>
              </p>
              {invoiceForm &&
                invoiceForm.details.map((element, i) => (
                  <div
                    key={i}
                    className={"d-flex justify-content-between odt-font-active"}
                  >
                    <span>{element.key}</span>
                    <div>{`${element.currency ? "$" : ""} ${
                      element.text
                        ? element.value
                        : currencyFormat(element.value, 2)
                    }`}</div>
                  </div>
                ))}
            </div>
          </div>

          <Collapse
            id={"invoice-total-content"}
            className={"row odt-panel odt-font-15 odt-font-family-secondary"}
            header={(open: boolean, handleOpen: () => void) => {
              return (
                <div className={"odt-panel-s"} onClick={handleOpen}>
                  <div
                    className={
                      "d-flex justify-content-between odt-font-bold odt-font-p"
                    }
                  >
                    <div>{"Total Amount"}</div>
                    <div>{`$${currencyFormat(
                      store.invoice.orderTotal,
                      2
                    )}`}</div>
                  </div>
                </div>
              );
            }}
          >
            <div
              className={"odt-panel-p odt-font-active"}
              style={{ borderBottomLeftRadius: "25px" }}
            >
              <span>Fees</span>
              {invoiceForm &&
                invoiceForm.fees.map((element, i) => (
                  <div key={i} className={"d-flex justify-content-between"}>
                    <div>{element.key}</div>
                    <div>{`${element.currency ? "$" : ""} ${currencyFormat(
                      element.value,
                      2
                    )}`}</div>
                  </div>
                ))}
            </div>
          </Collapse>
        </Collapse>
     
           {
            dealDonation?.isDonation ? 
            <Collapse
            className="mt-3"
            id={"detail-content"}
            header={(open: boolean, handleOpen: () => void) => {
                return (
                <div
                    className={"d-flex justify-content-between odt-mouse-pointer"}
                    onClick={() => {
                    donationDetails();
                    return handleOpen();
                    }}
                >
                    <span className={"odt-font-24 odt-font-family-primary-bold"}>
                    Donation Details
                    </span>
                    <i className={"odt-font-24"}>
                    {" "}
                    {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}{" "}
                    </i>
                </div>
                );
            }}
            >
            <div
                className={
                "row odt-b-invert-col odt-font-15 odt-font-family-secondary"
                }
            >
                
                    {customerDonationList.donationDetails
                    ? customerDonationList.donationDetails.map(
                        (
                            {
                            packageDate,
                            packageTag,
                            quantity,
                            retailer,
                            retailerLicense,
                            },
                            index
                        ) => {
                            return (
                                <div key={index} className="odt-panel-p deal-details mt-3">
                                <div className={"d-flex justify-content-between my-1"}><span>Quantity</span><div>{quantity}</div></div>
                                <div className={"d-flex justify-content-between my-1"}><span>Package Date</span><div>{packageDate}</div></div>
                                <div className={"d-flex justify-content-between my-1"}><span>Package Tag</span><div>{packageTag}</div></div>
                                <div className={"d-flex justify-content-between my-1"}><span>Retailer</span><div>{retailer}</div></div>
                                <div className={"d-flex justify-content-between my-1"}><span>Retailer License</span><div>{retailerLicense}</div></div>
                            </div>
                            );
                        }
                        )
                    : "No Data"}
                
            </div>
            </Collapse>            
:null
           }

        {store?.messageInvoice?.messageUid ? (
          <Button
            classes={"odt-font-family-secondary"}
            mode={"primary"}
            text={"Download"}
            onClick={handleDownloadInvoice}
          />
        ) : null}
      </div>

      <div id={"shipping-details-panel"} style={{ paddingTop: "20px" }}>
        <span className={"odt-font-24 odt-font-family-primary-bold"}>
          Shipping detail
        </span>

        <div
          id={"deals-steps"}
          className={"odt-font-family-secondary position-relative z-index1"}
          style={{ paddingTop: "10px" }}
        >
          {store.dealSteps &&
            store.dealSteps.map((step, index) => (
              <div
                key={index}
                className={`odt-step-vertical odt-step-item-${
                  step.timestamp ? "active" : ""
                }`}
              >
                <div>
                  <div className={"odt-step-item-circle"}>
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div>
                  <p
                    className={"odt-p odt-font-14"}
                    style={{ paddingLeft: "6px" }}
                  >
                    <span className={`odt-font-${step.timestamp ? "p" : "tt"}`}>
                      {step.message}
                    </span>
                    {step.message === "Manifest Created" ? (
                      <a
                        href={step?.description2}
                        className={`odt-font-12 odt-font-${
                          step.timestamp ? "p" : "tt"
                        }`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {step.description}
                      </a>
                    ) : (
                      <span
                        className={`odt-font-12 odt-font-${
                          step.timestamp ? "p" : "tt"
                        }`}
                      >
                        {step.description}
                      </span>
                    )}

                    {step.timestamp ? (
                      <span className={"odt-font-13"}>
                        <Moment format={"MM-DD-YYYY HH:MM"}>
                          {step.timestamp}
                        </Moment>
                      </span>
                    ) : null}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div id={"options-panel"}>
        {store.mode === "buyer" && store.dealStatus !== "completed" ? (
          <Button
            classes={"odt-font-family-secondary"}
            mode={"primary"}
            text={"Received"}
            onClick={onRedirectReceived}
          />
        ) : null}

        <Button
          classes={"odt-font-family-secondary"}
          mode={"tertiary"}
          text={"Contact Support"}
          onClick={onRedirectSupport}
        />
      </div>
    </React.Fragment>
  );
};
