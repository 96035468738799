import {combineReducers} from 'redux';
import {buyerSellerReducer} from './BuyerSellerReducer';
import {utilityReducer} from "./UtilityReducer";
import {authReducer} from "./AuthReducer";

export const rootReducers = combineReducers({
        auth: authReducer,
        process: buyerSellerReducer,
        utility: utilityReducer
    }
);
