import React, {CSSProperties, useEffect, useState} from 'react';

export const ImageLoading = ({
                              src,
                              onClick,
                              alt,
                              style
                          }: { src: string, onClick?: () => void, alt?: string, style?: CSSProperties }) => {

    const [imageForm, setImageForm] = useState<{ loading: boolean }>({
        loading: true
    });

    const handleOnLoad = () => {
        setImageForm({...imageForm, loading: false});
    };

    useEffect(() => {
        setImageForm({...imageForm, loading: true});
    }, [src]);

    return (
        <div style={{width: '100%', height: '100%'}}>
            <img src={src} alt={`${alt}`}
                 onClick={onClick}
                 onLoad={handleOnLoad}
                 onError={handleOnLoad}
                 style={imageForm.loading ? {
                     display: 'none'
                 } : {
                     ...style,
                     display: ''
                 }}/>
        </div>
    );
};
