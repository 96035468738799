import React, { useEffect, useState } from "react";
import { Page } from "../../../Shared/Page/Page";
import { Button } from "../../../Components/Buttons/ButtonBootstrap";
import { useDispatch } from "react-redux";
import { getBillingDetailsList } from "../../../Services/apis/SettingsAPIs";
import { addNewBillingFormAction } from "../../../Services/actions/SettingsAction";
import BillingDetailForm from "./BillingDetailForm";

export const BillingDetails = () => {
  const dispatch = useDispatch();

  const [billingAddressList, setBillingAddressList] = useState<
    | [
        {
          billingAddress: string;
          billingCity: string;
          billingState: string;
          billingZip: string;
          phone: string;
          email: string;
          routingNumber: string;
          accountNumber: string;
          nameOnAccount: string;
          expirationMmyy: string;
          ccvn: string;
          bankAccountId: string;
          accountNumberCard: string;
          cardholderName: string;
          bankName: string;
          bankCityState: string;
        },
      ]
    | []
  >([]);

  useEffect(() => {
    handleGetBillingDetails();
  }, []);

  const handleGetBillingDetails = async () => {
    const res = await getBillingDetailsList();
    setBillingAddressList(res?.data);
  };

  const handleAddNewBillingForm = () => {
    const newBillingForm: any = [
      {
        billingAddress: "",
        billingCity: "",
        billingState: "",
        billingZip: "",
        phone: "",
        email: "",
        routingNumber: "",
        accountNumber: "",
        nameOnAccount: "",
        isPrimary: !billingAddressList?.length ? true : false,
        expirationMmyy: "",
        userUid: "",
        ccvn: "",
        bankAccountId: `${new Date().valueOf()}`,
      },
    ];
    const cloneBillingForm: any = [...billingAddressList, ...newBillingForm];
    setBillingAddressList(cloneBillingForm);
  };

  const handleSaveBillingForm = () => {
    const body = {
      billingDetails: [...billingAddressList],
    };
    dispatch(
      addNewBillingFormAction(body, () => {
        handleGetBillingDetails();
      }),
    );
  };

  return (
    <Page mode={"private"}>
      <div className={"container-fluid"}>
        <div
          className={"row odt-card-product-canvas-items"}
          style={{ paddingTop: "10px" }}
        >
          <h3 className={"odt-font-family-primary-bold"}>Settings</h3>
          <form>
            <div style={{ paddingTop: "30px" }}>
              <h3
                className={"odt-font-family-primary-bold"}
                style={{ paddingBottom: "20px" }}
              >
                Billing Details
              </h3>
              {billingAddressList?.map(
                ({
                  billingAddress = "",
                  billingCity = "",
                  billingState = "",
                  billingZip = "",
                  phone = "",
                  email = "",
                  routingNumber = "",
                  accountNumber = "",
                  nameOnAccount = "",
                  expirationMmyy = "",
                  ccvn = "",
                  bankAccountId = "",
                  accountNumberCard = "",
                  cardholderName = "",
                  bankName = "",
                  bankCityState = "",
                }) => {
                  return (
                    <BillingDetailForm
                      accountNumber={accountNumber}
                      accountNumberCard={accountNumberCard}
                      bankAccountId={bankAccountId}
                      bankCityState={bankCityState}
                      bankName={bankName}
                      billingAddressList={billingAddressList}
                      billingCity={billingCity}
                      billingState={billingState}
                      ccvn={ccvn}
                      email={email}
                      phone={phone}
                      routingNumber={routingNumber}
                      setBillingAddressList={setBillingAddressList}
                      expirationMmyy={expirationMmyy}
                      nameOnAccount={nameOnAccount}
                      cardholderName={cardholderName}
                      billingZip={billingZip}
                      billingAddress={billingAddress}
                    />
                  );
                },
              )}
            </div>
            {!billingAddressList?.length ? <div>No form Available</div> : ""}

            <div className={"row odt-no-padding "}>
              <div className="col-md-4 ">
                <Button
                  mode={"primary"}
                  text={"Add"}
                  classes={"odt-font-family-primary-bold"}
                  align={"text-end"}
                  style={{ background: "#183E43" }}
                  type={"button"}
                  onClick={handleAddNewBillingForm}
                />
              </div>
              {billingAddressList?.length ? (
                <div className="col-md-4 ">
                  <Button
                    mode={"primary"}
                    text={"Save"}
                    classes={"odt-font-family-primary-bold"}
                    align={"text-end"}
                    style={{ background: "#183E43" }}
                    type={"button"}
                    onClick={handleSaveBillingForm}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
    </Page>
  );
};
