import React, { useRef, useState } from "react";
import { Button } from "../../Components/Buttons/ButtonBootstrap";
import { SidebarMenu } from "../Navigation/Sidebar/SidebarMenu";
import { Avatar } from "../Profile/Avatar";
import { useHistory, useLocation } from "react-router-dom";
import { ScrollUtility } from "../../Utilities/ScrollUtility";
import { FIND_KEY_IN_FILTER } from "../../Containers/Marketplace/components/Filters";
import { AuthenticationData } from "../../Services/reducers/states/AuthenticationData";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import UploadBulkListingWizard from "../../Containers/UploadBulkListing/UploadBulkListingWizard";

export interface FacilityType {
  name: string;
  facilityDba: string;
  licenseNumber: string;
  logo: string;
  image: string;
  images?: { url: string }[];
  videos?: { url: string }[];
  bioBody?: string;
  bioHeadline?: string;
  tags?: string[];
}

export const Page = ({
  mode,
  data,
  children,
}: {
  mode: "public" | "private";
  data?: FacilityType;
  children: JSX.Element;
}) => {
  const location = useLocation();
  const history = useHistory();
  const productsRef = useRef(null);

  const store = useSelector((state: AuthenticationData) => state.auth.user);

  const [sidebarMenu, setSidebarMenu] = useState({
    expanded: false,
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleRedirectToProductsView = () => {
    const path: string = mode === "public" ? "/login" : "/products";
    if (path === location.pathname) ScrollUtility.scrollTo(productsRef, 100);
    else history.push(path);
  };
  const handleRedirectToUploadBulkListing = () => {
    /*  const path: string = mode === "public" ? "/login" : "/upload-bulk-listing";
    history.push(path); */
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const showTagsRender_ = () => {
    return (
      <React.Fragment>
        <div
          className={"row odt-font-family-secondary odt-no-padding-left-right"}
          style={{ width: "100%", maxWidth: "400px" }}
        >
          {data?.tags &&
            data.tags.map((tag, index) => {
              const item = FIND_KEY_IN_FILTER(tag);
              return index < 5 && item ? (
                <div
                  key={index}
                  className={"text-center"}
                  style={{
                    width: `${100 / 5}%`,
                    height: "118px",
                    marginRight: "0",
                  }}
                >
                  <div className={`d-flex justify-content-center`}>
                    <div className={`odt-card-product-filter-item-v3`}>
                      <img
                        alt={`tag-${index}-icon-facility`}
                        src={`/customized/images/icons/${item?.icon}`}
                        style={{
                          width: "55%",
                          height: "55%",
                          marginTop: "24%",
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className={
                      "text-center odt-font-family-secondary odt-font-14"
                    }
                    style={{ paddingTop: "10px" }}
                  >
                    {item?.label}
                  </div>
                </div>
              ) : null;
            })}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div
      className={"container-fluid odt-no-padding"}
      onClick={() => {
        if (sidebarMenu.expanded)
          setSidebarMenu({ ...sidebarMenu, expanded: false });
      }}
    >
      <SidebarMenu expanded={sidebarMenu.expanded}>
        <React.Fragment>
          <div
            className={`d-flex odt-no-padding ${
              mode === "public"
                ? "odt-banner-two-panels"
                : "odt-banner-two-panels-private"
            }`}
          >
            <div
              id={"banner-left-panel"}
              style={{ background: "#00000029" }}
              className={"col odt-no-padding"}
            >
              <img
                alt={"banner-right-listo"}
                src={
                  data?.image ||
                  "/customized/images/page-background-private.jpeg"
                }
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "fill",
                  cursor: "pointer",
                }}
              />
            </div>

            <div
              id={"banner-right-panel"}
              style={{ background: "#F2F5F5" }}
              className={`col odt-no-padding text-left-v1`}
            >
              {mode === "private" ? (
                <div
                  className={`d-flex justify-content-end`}
                  style={{ marginTop: "4%" }}
                >
                  <Avatar
                    className={"text-center"}
                    size={55}
                    onClick={() =>
                      setSidebarMenu({
                        ...sidebarMenu,
                        expanded: !sidebarMenu.expanded,
                      })
                    }
                  />
                </div>
              ) : null}

              <div className={mode === "private" ? "odt-banner-text" : ""}>
                {mode === "private" ? (
                  <React.Fragment>
                    {isModalOpen && (
                      <Modal
                        className={"odt-modal odt-custom-modal"}
                        overlayClassName={"odt-modal-overlay"}
                        isOpen={isModalOpen}
                        
                      >
                        <div
                          className={"row p-5"}
                          style={{
                            background: "#FFFFFF",
                            borderRadius: "50px",
                            height: "100%",
                          }}
                        >
                          <UploadBulkListingWizard
                            handleClose={handleCloseModal}
                          />
                        </div>
                      </Modal>
                    )}
                    <div
                      className="text-left-v1"
                      style={{ paddingBottom: "5px" }}
                    >
                      <img
                        src={"/customized/images/logo-coin-listo.svg"}
                        alt={"coin-listo"}
                        style={{ width: "12%", paddingBottom: "10px" }}
                      />
                      <span
                        className={"odt-font-family-primary-bold odt-font-30"}
                        style={{ position: "relative", left: "2%" }}
                      >
                        listo
                      </span>
                    </div>
                    <div className="text-start">
                      <h1
                        className={"odt-font-family-primary-bold"}
                        style={{ maxWidth: "400px" }}
                      >
                        A sourcing department in your pocket
                      </h1>
                      <p
                        className={"odt-font-family-secondary odt-font-14"}
                        style={{ maxWidth: "400px" }}
                      >
                        With Listo, you can do more with less people. We
                        simplify moving legacy, minority, and equity owned
                        products with all of the compliance, documentation,
                        transportation, and payments securely under one app.
                      </p>
                      <div className="button-box d-flex flex-wrap">
                        <Button
                          mode={"primary"}
                          text={"Shop Now"}
                          classes={"odt-font-family-primary-bold"}
                          style={{ width: "200px", background: "#183E43" }}
                          align={"text-start"}
                          onClick={handleRedirectToProductsView}
                        />
                        {store?.admin ? (
                          <>
                            <Button
                              mode={"primary"}
                              text={"Add Listing"}
                              disabled
                              classes={
                                "odt-font-family-primary-bold bulk-listing"
                              }
                              style={{ width: "160px", background: "#183E43" }}
                              align={"text-start"}
                              onClick={handleRedirectToProductsView}
                            />
                            <Button
                              mode={"primary"}
                              text={"Bulk Upload"}
                              classes={
                                "odt-font-family-primary-bold bulk-listing"
                              }
                              style={{ width: "160px", background: "#183E43" }}
                              align={"text-start"}
                              onClick={handleRedirectToUploadBulkListing}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}

                {mode === "public" ? (
                  <React.Fragment>
                    <img
                      className="position-absolute top-0 end-0 odt-banner-left-decorator"
                      alt={"coin-corner-right"}
                      src={"/customized/images/logo-icon-corner-right.svg"}
                    />

                    <div
                      id={"banner-right-content"}
                      className={"odt-banner-right-content"}
                    >
                      <div
                        className={
                          "d-inline-block text-left-v1 odt-banner-logo-text-v1"
                        }
                      >
                        {data?.logo ? (
                          <img
                            alt={`facility-logo`}
                            src={`${data?.logo}`}
                            width={60}
                            height={60}
                            style={{
                              display: "inline-block",
                              borderRadius: "100%",
                            }}
                          />
                        ) : null}
                        <p
                          className={
                            "odt-p odt-font-family-primary-bold align-top"
                          }
                          style={{
                            display: "inline-block",
                            paddingLeft: "10px",
                          }}
                        >
                          {data && data.facilityDba}{" "}
                          <span className={"odt-font-family-secondary"}>
                            {data && data.licenseNumber}
                          </span>
                        </p>
                      </div>

                      <p
                        className={
                          "odt-p odt-font-family-primary-bold odt-banner-text-v1"
                        }
                      >
                        {data && data.bioBody}
                      </p>

                      <p
                        className={"odt-p odt-font-family-secondary"}
                        style={{ width: "95%" }}
                      >
                        {data && data.bioHeadline}
                      </p>

                      {showTagsRender_()}

                      <Button
                        mode={"primary"}
                        text={"Shop Now"}
                        align={"text-start"}
                        classes={"odt-font-family-primary-bold"}
                        style={{ width: "200px", background: "#183E43" }}
                        onClick={handleRedirectToProductsView}
                      />
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>

          <div
            ref={productsRef}
            style={{ marginTop: "30px", paddingBottom: "30px" }}
          >
            {children}
          </div>
        </React.Fragment>
      </SidebarMenu>
    </div>
  );
};
