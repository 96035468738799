import React, {useEffect, useState} from 'react';
import {BsFillStarFill} from 'react-icons/all';

export const Rate = (
    {type = 'start', size, onClick}: { type: 'start', size: number, onClick: (index: number) => void }
) => {

    const THEME = {
        start: {
            icon: <BsFillStarFill/>,
            style: 'odt-start',
            active: 'odt-start-active',
        }
    };

    const [rateForm, setRateForm] = useState<{
        current: number, icons: { icon: any, style: string, active: string, custom: string }[]
    }>({
        current: 0,
        icons: []
    });

    useEffect(() => {
        setRateForm({
            ...rateForm,
            icons: Array.from({length: size}, () => {
                return {
                    icon: THEME[type].icon, style: THEME[type].style, active: THEME[type].active, custom: ''
                };
            })
        });
    }, []);

    const handleOnClick = (index: number) => {
        setRateForm({
            ...rateForm, current: index,
            icons: rateForm.icons.map((icon, it) => {
                icon.custom = ((it + 1) <= index) ? icon.active : '';
                return icon;
            })
        });
        onClick(index);
    };

    const handleMouseOver = (index: number) => {
        setRateForm({
            ...rateForm, icons: rateForm.icons.map((icon, it) => {
                icon.custom = ((it + 1) <= index) ? icon.active : '';
                return icon;
            })
        });
    };

    const handleMouseLeave = () => {
        if (rateForm.current === 0) {
            setRateForm({
                ...rateForm, icons: rateForm.icons.map((icon, it) => {
                    icon.custom = '';
                    return icon;
                })
            });
        } else
            handleOnClick(rateForm.current);
    };

    return (
        <React.Fragment>
            {rateForm.icons ? (
                rateForm.icons.map((element, index) => (
                    <i key={index} className={`${element.style} ${element.custom}`}
                       onClick={() => handleOnClick(index + 1)}
                       onMouseOver={() => handleMouseOver(index + 1)}
                       onMouseLeave={handleMouseLeave}> {element.icon} </i>
                ))
            ) : null}
        </React.Fragment>
    );
};
