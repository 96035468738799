export const OPEN_LOADING = 'OPEN_LOADING_FULLSCREEN';
export const CLOSE_LOADING = 'CLOSE_LOADING_FULLSCREEN';


export const showLoadingAction = () => {
    return (dispatch) => dispatch({type: OPEN_LOADING, payload: {}});
}

export const hideLoadingAction = () => {
    return (dispatch) => dispatch({type: CLOSE_LOADING, payload: {}});
}

