import React, { CSSProperties } from "react";
import { IconBaseProps } from "react-icons/lib/cjs/iconBase";

export interface ButtonType {
  mode?: "default" | "primary" | "secondary" | "tertiary";
  id?: string;
  text: string;
  onClick?: () => void;
  classes?: string;
  disabled?: boolean;
  icon?: IconBaseProps;
  style?: CSSProperties;
  type?: any;
  align?: "text-center" | "text-start" | "text-end";
}

const MODE_STYLE = {
  default: {
    button: "btn-primary odt-font-18",
  },
  primary: {
    button:
      "odt-button-primary odt-font-family-primary odt-font-18 odt-font-background-p",
  },
  secondary: {
    button:
      "odt-button-secondary odt-font-family-secondary odt-font-18 odt-font-background-s",
  },
  tertiary: {
    button: "odt-button-tertiary odt-font-18",
  },
};

export const Button = ({
  mode = "default",
  id,
  text,
  classes = "",
  disabled = false,
  icon,
  align = "text-center",
  style,
  type,
  onClick,
}: ButtonType) => {
  return (
    <div className={`${align} odt-padding-vertical`}>
      <button
        id={id}
        type={type || "submit"}
        className={`btn odt-button ${MODE_STYLE[mode].button} ${classes}`}
        style={style}
        disabled={disabled}
        onClick={onClick}
      >
        {icon && (
          <span className="btn-label">
            <i style={{ fontSize: "20px", paddingRight: "2px" }}>{icon}</i>
          </span>
        )}
        {text}
      </button>
    </div>
  );
};
