import React from 'react';
import {AuthContext} from '../Hooks/InternalHooks';
import {useDispatch, useSelector} from 'react-redux';
import IdleTimer from 'react-idle-timer';
import {revokedIntegrationKeys} from '../Services/actions/BuyerSellerAction';
import {UtilityData} from '../Services/reducers/states/UtilityData';


export const UtilityProvider: React.FC = ({children}) => {
    const dispatch = useDispatch();
    const store = useSelector((state: UtilityData) => state.utility);

    const TIMEOUT = 1000 * 30 * 15;
    const handleOnIdle = (e: Event) => dispatch(revokedIntegrationKeys());

    const AuthContextProvider = () => {
        const [authenticated, setAuthenticated] = React.useState(false);
        return {
            authenticated,
            setAuthenticated
        };
    };

    const auth = AuthContextProvider();

    return (
        <React.Fragment>
            {store.loading ? <div className={'odt-loading-fullscreen'}/> : <div/>}
            <AuthContext.Provider value={auth}>
                {
                    auth.authenticated ? <IdleTimer element={document}
                                                    onIdle={handleOnIdle}
                                                    debounce={250}
                                                    timeout={TIMEOUT}/> : <div/>
                }
                {children}
            </AuthContext.Provider>
        </React.Fragment>
    );
};
