import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button} from '../../../Components/Buttons/ButtonBootstrap';
import {InputSelectForm, InputTextAreaForm} from '../../../Components/Inputs/InputBootstrap';
import {Page} from '../../../Shared/Page/Page';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {createMessageContactSupportAction} from '../../../Services/actions/SettingsAction';
import {AuthenticationData} from '../../../Services/reducers/states/AuthenticationData';

export const ContactSupportView = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const storeAuth = useSelector((state: AuthenticationData) => state.auth);

    const [form, setForm] = useState<{
        details: string, supportId: string, facilityId: string,
    }>({
        details: '',
        supportId: '',
        facilityId: '',
    });

    const [supportTypes] = useState<{ options: { id: any, name: any }[] }>({
        options: [
            {id: 'BUG', name: 'Bug'},
            {id: 'FEATURE', name: 'Feature'},
            {id: 'ERROR', name: 'Error'},
            {id: 'REQUEST', name: 'Request'},
            {id: 'QUESTION', name: 'Question'},
        ]
    });

    const [facilitiesTypes, setFacilitiesTypes] = useState<{ options: { id: any, name: any }[] }>({
        options: []
    });

    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: 'onChange'
    });

    const handleNext = (data: { details: string }) => {
        dispatch(createMessageContactSupportAction(form.details, undefined, form.facilityId, form.supportId, () => {
            history.push('/settings');
        }));
    };

    const handleChangeField = (e: { target: any; }) => {
        const target = e.target;
        setForm({...form, [target.name]: target.value});
    };

    useEffect(() => {
        setFacilitiesTypes({
            options: storeAuth?.user?.facilities?.map((facility) => {
                return {id: facility.id, name: `${facility.name} - ${facility.licenseNumber}`};
            }) || []
        });

        setForm({
            ...form,
            facilityId: storeAuth?.user?.facilities?.length === 1 ? storeAuth?.user?.facilities[0].id : '0'
        });
    }, []);

    return (
        <Page mode={'private'}>
            <div className={'container-fluid'}>
                <div className={'row odt-card-product-canvas-items'} style={{paddingTop: '10px'}}>
                    <h3 className={'odt-font-family-primary-bold'}>Settings</h3>

                    <div style={{paddingTop: '20px'}}>
                        <h4 className={'odt-font-family-primary-bold'} style={{paddingBottom: '10px'}}>Contact
                            Support</h4>
                        <p className={'odt-font-family-secondary odt-font-18'}>
                            {'Leave us your message and we will contact you soon to give you a solution:'}
                        </p>

                        <form onSubmit={handleSubmit(handleNext)}>

                            <InputTextAreaForm control={{...register('details', {required: true})}}
                                               errors={errors}
                                               mode={'secondary'}
                                               classes={'odt-input-text-area-v2'}
                                               value={form.details}
                                               placeholder={'Leave us your message and we will contact you soon to give you a solution.'}
                                               onChange={handleChangeField}/>

                            <InputSelectForm control={{...register('supportId', {required: true})}}
                                             errors={errors}
                                             classes={'odt-font-family-secondary-bold odt-margin-top-20'}
                                             mode={'secondary'}
                                             value={form.supportId}
                                             options={supportTypes}
                                             placeholder={'Support selector'}
                                             onChange={handleChangeField}/>

                            <InputSelectForm control={{...register('facilityId', {required: true})}}
                                             errors={errors}
                                             classes={'odt-margin-top-20'}
                                             mode={'secondary'}
                                             value={form.facilityId}
                                             options={facilitiesTypes}
                                             placeholder={'Support selector'}
                                             onChange={handleChangeField}/>

                            <div style={{padding: '3% 0 2% 0'}}>
                                <Button mode={'primary'} text={'Send'} classes={'odt-font-family-primary-bold'}/>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </Page>);
};
