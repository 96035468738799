import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {refreshIntegrationKeys} from '../../Services/actions/BuyerSellerAction';

export const PublicNavigation = ({paths, children}: { paths: string[], children: React.FC }) => {
    const dispatch = useDispatch();
    const [publicNavigation, setPublicNavigation] = useState<{ processing: boolean }>({processing: false});

    useEffect(() => {
        const DEAL_PROCESS_STORE = '/deal/process';
        const refresh = paths && paths.filter(f => f === DEAL_PROCESS_STORE);
        if (refresh && !!refresh.length) {
            dispatch(refreshIntegrationKeys());
            setPublicNavigation({processing: true});
        } else {
            setPublicNavigation({processing: true});
        }
    }, []);

    const View = children;

    return (
        <React.Fragment>
            {publicNavigation.processing ? <View/> : <div/>}
        </React.Fragment>
    );
};
