import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from '../../../Components/Buttons/ButtonBootstrap';
import {InputSelectForm, InputTextAreaForm} from '../../../Components/Inputs/InputBootstrap';
import {BuyerSellerType} from '../../../Services/reducers/states/BuyerSellerType';
import {createMessageContactSupportAction} from '../../../Services/actions/SettingsAction';
import {addModal} from '../../../Shared/Dialog/Dialog';

export const ContactSupportView = ({onRedirectGoBack}: { onRedirectGoBack: () => void }) => {

    const dispatch = useDispatch();
    const store = useSelector((state: BuyerSellerType) => state.process);

    const [form, setForm] = useState<{
        comments: string, supportId: string, facilityId: string, listingId: string
    }>({
        comments: '',
        supportId: '',
        facilityId: '',
        listingId: '',
    });

    const [supportTypes] = useState<{ options: { id: any, name: any }[] }>({
        options: [
            {id: 'BUG', name: 'Bug'},
            {id: 'FEATURE', name: 'Feature'},
            {id: 'ERROR', name: 'Error'},
            {id: 'REQUEST', name: 'Request'},
            {id: 'QUESTION', name: 'Question'},
        ]
    });

    const [facilitiesTypes, setFacilitiesTypes] = useState<{ options: { id: any, name: any }[] }>({
        options: []
    });

    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: 'onChange'
    });

    const handleSubmitForm = (data: { details: string }) => {
        dispatch(createMessageContactSupportAction(form.comments, form.listingId, form.facilityId, form.supportId, (success: boolean) => {
            if (success) {
                addModal('modal-container-002', 'Support message was send successfully!');
            }
            onRedirectGoBack();
        }));
    };

    const handleChangeField = (e: { target: any; }) => {
        const target = e.target;
        setForm({...form, [target.name]: target.value});
    };

    useEffect(() => {
        setFacilitiesTypes({
            options: store?.facilities?.map((facility) => {
                return {id: facility.id, name: `${facility.name} - ${facility.licenseNumber}`};
            }) || []
        });

        setForm({
            ...form,
            listingId: `${store.listingUid}`,
            facilityId: store?.facilities?.length === 1 ? store?.facilities[0].id : '0'
        });
    }, []);

    return (
        <div className={'odt-font-family-secondary'}>

            <h2 className={'odt-font-30 odt-font-family-primary-bold'}>{'Contact support'}</h2>

            <h4 className={'odt-font-20 odt-font-family-secondary-bold odt-padding-top-20'}>{store?.name}</h4>
            <span className={'odt-font-18'}>{store?.facilityName}</span>

            <form onSubmit={handleSubmit(handleSubmitForm)} style={{padding: '8% 0 4% 0'}}>

                <InputTextAreaForm control={{...register('comments', {required: true})}}
                                   errors={errors}
                                   mode={'secondary'}
                                   classes={'odt-input-text-area-v2'}
                                   value={form.comments}
                                   placeholder={'Leave us your message and we will contact you soon to give you a solution.'}
                                   onChange={handleChangeField}/>

                <InputSelectForm control={{...register('supportId', {required: true})}}
                                 errors={errors}
                                 classes={'odt-font-family-secondary-bold odt-margin-top-20'}
                                 mode={'secondary'}
                                 value={form.supportId}
                                 options={supportTypes}
                                 placeholder={'Support selector'}
                                 onChange={handleChangeField}/>

                <InputSelectForm control={{...register('facilityId', {required: true})}}
                                 errors={errors}
                                 classes={'odt-margin-top-20'}
                                 mode={'secondary'}
                                 value={form.facilityId}
                                 options={facilitiesTypes}
                                 placeholder={'Support selector'}
                                 onChange={handleChangeField}/>

                <div style={{padding: '3% 0 2% 0'}}>
                    <Button mode={'secondary'} text={'Send'}/>
                    <Button mode={'tertiary'} text={'Back'} onClick={onRedirectGoBack}/>
                </div>

            </form>

        </div>
    );
};
