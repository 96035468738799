import React, {useEffect, useState} from 'react';

export const Carousel = ({children, startIndex}: { children: JSX.Element[], startIndex: number }) => {

    const [carouselForm, setCarouselForm] = useState<{ current: number }>({
        current: startIndex
    });

    const handleSwipeRight = () => {
        setCarouselForm({...carouselForm, current: ((carouselForm.current + 1) % children.length)});
    };

    const handleSwipeLeft = () => {
        setCarouselForm({
            ...carouselForm,
            current: (carouselForm.current - 1 === -1 ? children.length - 1 : carouselForm.current - 1)
        });
    };

    useEffect(() => {
        setCarouselForm({current: startIndex});
    }, [children]);

    return (
        <div className={'odt-carousel'}>

            {children && children.length > 1 ? (
                <div id={'header-selector-left-right'} className="position-relative">
                    <div className="position-absolute top-0 start-0 odt-carousel-btn odt-carousel-btn-left" onClick={handleSwipeLeft}>
                        <i style={{fontSize: '22px'}}>
                            <div className="text-center">
                                <img src={'/customized/images/icons/arrow_left.svg'} alt={'arrow-left-icon'} width={30}/>
                            </div>
                        </i>
                    </div>
                    <div className="position-absolute top-0 end-0 odt-carousel-btn odt-carousel-btn-right" onClick={handleSwipeRight}>
                        <i style={{fontSize: '22px'}}>
                            <div className="text-center">
                                <img src={'/customized/images/icons/arrow_right.svg'} alt={'arrow-right-icon'} width={30}/>
                            </div>
                        </i>
                    </div>
                </div>) : null}

            {children && children.map((element, index) => {
                return index === carouselForm.current ? (
                    <div key={index} className={'odt-carousel-item'}>
                        {element}
                    </div>
                ) : null;
            })}

        </div>

    );
};
