import {
    INITIALIZE_DATA_BUYER_SELLER,
    INITIALIZE_DATA_BUYER_SELLER_ERROR,
    INITIALIZE_EXTERNAL_DATA_BUYER_SELLER,
    INITIALIZE_EXTERNAL_DATA_BUYER_SELLER_ERROR,
    KEYS_PROCESS_BUYER_SELLER_INITIALIZE,
    KEYS_PROCESS_BUYER_SELLER_REFRESH,
    KEYS_PROCESS_BUYER_SELLER_REVOKED
} from '../actions/BuyerSellerAction';
import {optimizeImage} from '../../Utilities/ImageUtility';

export const initialize = {
    authorized: false,
    keys: {
        listing: '',
        deal: '',
        token: '',
        device: '',
    },
    mode: '',
    dealStatus: '',
    name: '',
    label: '',
    facilityName: '',
    facilityLicenseNumber: '',
    availableQuantity: 0,
    quantityType: '',
    quantity: '',
    unitOfMeasureAbbreviation: '',
    productCategory: '',
    listingPrice: 0,
    purchaseAmount: 0,
    priceType: '',
    listingUid: '',
    dealUid: '',
    dealSteps: [],
    hasCoaUpload: false,
    totalThcPct: 0,
    messages: [],
    messageInvoice: {
        messageUid: '',
    },
    messageOffer: undefined,
    invoice: {
        buyerFacilityName: '',
        buyerFacilityLicense: '',
        buyerFacilityUid: '',
        invoiceDate: '',
        orderTotal: 0,
        deliveryFees: 0,
        serviceFees: 0,
        invoiceId: '',
    },
    facilities: [],
}

export const buyerSellerReducer = (state = initialize, action) => {
    const TAG_TOKEN_BUYER_SELLER_PROCESS = 'process-access';
    switch (action.type) {

        case KEYS_PROCESS_BUYER_SELLER_INITIALIZE:
            localStorage.setItem(TAG_TOKEN_BUYER_SELLER_PROCESS, JSON.stringify({
                listing: action.payload.listing,
                deal: action.payload.deal,
                token: action.payload.token,
                device: action.payload.device
            }));
            return {
                ...state,
                authorized: false,
                keys: {
                    listing: action.payload.listing,
                    deal: action.payload.deal,
                    token: action.payload.token,
                    device: action.payload.device
                },
            };
        case KEYS_PROCESS_BUYER_SELLER_REVOKED:
            localStorage.removeItem(TAG_TOKEN_BUYER_SELLER_PROCESS);
            return {
                ...state,
                authorized: false
            };
        case KEYS_PROCESS_BUYER_SELLER_REFRESH:
            const keys = localStorage.getItem(TAG_TOKEN_BUYER_SELLER_PROCESS);
            if (keys) {
                const principal = JSON.parse(keys);
                return {
                    ...state,
                    authorized: true,
                    keys: {
                        listing: principal.listing,
                        deal: principal.deal,
                        token: principal.token,
                        device: principal.device
                    },
                };
            }
            return {...state, authorized: false};

        case INITIALIZE_DATA_BUYER_SELLER:

            const steps = (action.payload.deal?.dealDetails || []).sort((a, b) => {
                return a.order - b.order;
            });

            const invoiceMessage = action.payload.deal?.messages?.length && action.payload.deal?.messages?.filter(f => f.eventMessage === 'INVOICE');

            const offerMessage = action.payload.deal?.messages?.length && action.payload.deal?.messages?.filter(f => !!f.purchaseOfferPrice);

            return {
                ...state,
                mode: action.payload.deal?.isOwnListing ? 'seller' : 'buyer',
                dealStatus: action.payload.deal?.dealStatus,
                listingUid: action.payload.deal?.listingUid,
                dealSteps: steps,

                dealUid: action.payload.deal?.dealUid,
                name: action.payload.deal?.name,
                label: action.payload.deal?.inventoryItem?.label,
                facilityName: action.payload.deal?.inventoryItem?.facilityName,
                facilityLicenseNumber: action.payload.deal?.inventoryItem?.facilityLicenseNumber,
                availableQuantity: action.payload.deal?.availableQuantity,
                quantityType: action.payload.deal?.quantityType,
                quantity: action.payload.deal?.inventoryItem?.quantity,
                unitOfMeasureAbbreviation: action.payload.deal?.inventoryItem?.unitOfMeasureAbbreviation,
                productCategory: action.payload.deal?.inventoryItem?.productCategory,
                purchaseAmount: action.payload.deal?.purchaseAmount,
                priceType: action.payload.deal?.priceType,
                listingPrice: action.payload.deal?.listingPrice,
                hasCoaUpload: action.payload.deal?.hasCoaUpload,
                totalThcPct: action.payload.deal?.coaResult?.totalThcPct,
                messages: action.payload.deal?.messages,
                messageInvoice: (invoiceMessage && !!invoiceMessage.length && invoiceMessage[0]) || {},
                messageOffer: (offerMessage && !!offerMessage.length && offerMessage[0]) || undefined,
                invoice: {
                    buyerFacilityName: action.payload.deal?.buyerFacilityName,
                    buyerFacilityLicense: action.payload.deal?.buyerFacilityLicense,
                    buyerFacilityUid: action.payload.deal?.buyerFacilityUid,
                    invoiceDate: action.payload.deal?.invoiceDate,
                    orderTotal: action.payload.deal?.orderTotal || 0,
                    deliveryFees: action.payload.deal?.deliveryFees || 0,
                    serviceFees: action.payload.deal?.serviceFees || 0,
                    invoiceId: action.payload.deal?.invoiceId,
                },
                view: {
                    image: optimizeImage(action.payload.deal?.image?.url)
                },
                images: action.payload?.deal?.images,
                image: action.payload?.deal?.image,
            };
        case INITIALIZE_DATA_BUYER_SELLER_ERROR:
            return {
                ...state,
                authorized: false
            };

        case INITIALIZE_EXTERNAL_DATA_BUYER_SELLER:
            return {
                ...state,
                facilities: action.payload?.activeFacilities,
            };

        case INITIALIZE_EXTERNAL_DATA_BUYER_SELLER_ERROR:
            return {
                ...state,
                authorized: false
            };

        default:
            return state;
    }
}
