import {CLOSE_LOADING, OPEN_LOADING} from '../actions/UtilityAction';

export const initialize = {
    loading: false,
}

export const utilityReducer = (state = initialize, action) => {
    switch (action.type) {
        case OPEN_LOADING:
            return {
                ...state,
                loading: true,
            };
        case CLOSE_LOADING:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
