import React, {useEffect, useState} from 'react';
import {Page} from '../../Shared/Page/Page';
import {Product, ProductDealV1} from '../Deal/Components/Product';
import {getAllDealsCompletedService, getAllDealsPendingService} from '../../Services/apis/DealsAPIs';
import {hideLoadingAction, showLoadingAction} from '../../Services/actions/UtilityAction';
import {useDispatch} from 'react-redux';

export const DonationsView = () => {

    const dispatch = useDispatch();

    const [dealsForm, setDealsForm] = useState<{
        deals: ProductDealV1 []
    }>({
        deals: []
    });

    useEffect(() => {
        dispatch(showLoadingAction());
        Promise.all([getAllDealsPendingService(), getAllDealsCompletedService()]).then((response: { data: { deals: ProductDealV1[] } } []) => {
            const deals: ProductDealV1[] = [];

            response.forEach((status, index) => {
                deals.push(...status.data.deals.filter(f => f.isDonation).map((deal) => {
                    if (index === 0)
                        deal.currentStatus = 'in_process';
                    if (index === 1)
                        deal.currentStatus = 'completed';
                    const offerMessages = deal.messages && deal.messages.filter(f => !!f.purchaseOfferPrice);
                    deal.offerMessage = (offerMessages && !!offerMessages.length && offerMessages[0]) || undefined;
                    return deal;
                }));
            });

            console.log(deals);

            setDealsForm({
                ...dealsForm,
                deals: deals
            });
        }).catch((response) => {
            console.error(response);
        }).finally(() => dispatch(hideLoadingAction()));
    }, [dispatch]);

    return (
        <Page mode={'private'}>
            <div className={'container-fluid'}>
                <div className={'row odt-card-product-canvas-itemsv2'} style={{paddingTop: '30px'}}>
                    <h3 className={'odt-font-family-primary-bold'}>Deals</h3>
                    {dealsForm.deals && dealsForm.deals.map((product, i) => (
                        <div key={i} className={'col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3'}>
                            <Product data={product}/>
                        </div>))
                    }
                </div>
            </div>
        </Page>);
};
