import {currencyFormatDefault, formatDefault} from '../../../Utilities/NumberUtility';
import React, {useState} from 'react';
import {getHttpHeaderAuthorizationWithoutBearer, getHttpHeaderXDeviceId} from '../../../Services/apis/CommonsAPIs';
import {optimize} from '../../../Utilities/ImageUtility';

export interface ProductDealType {
    data: ProductDealV1;
}

export interface ProductDealV1 {
    listingUid: string,
    dealUid: string,
    currentStatus: 'in_process' | 'completed',
    inventoryItem: { facilityName: string, productName: string },
    listingPrice: number,
    availableQuantity: number,
    quantityType: string,
    dealStatus: string,
    image: { url: string },
    name: string,
    messages: { offerQuantity?: number, offerStatus?: string, offerUnits?: string, purchaseOfferPrice?: number } [],
    offerMessage: { offerQuantity?: number, offerStatus?: string, offerUnits?: string, purchaseOfferPrice?: number } | undefined,
    isDonation?: boolean
}

export const Product = ({data}: ProductDealType) => {

    const [productForm] = useState<{
        token: string,
        device: string
    }>({
        token: getHttpHeaderAuthorizationWithoutBearer(),
        device: getHttpHeaderXDeviceId()
    });

    return (
        <div className={'odt-card-product'} style={{cursor: 'pointer'}} onClick={() => {
            const url: string = `/deal/process/${productForm.token}/${productForm.device}/${data.listingUid}/${data.dealUid || ''}`;
            const tab = window.open(url, '_blank');
            tab && tab.focus();
        }}>
            <div className={'odt-card-product-body-v2 odt-font-family-secondary'}>

                <div className="text-temp">
                    <img src={optimize(data?.image?.url)}
                         alt={`Deal-${data?.inventoryItem?.productName}`}
                         style={{
                             width: '100%', height: '12rem',
                             objectFit: 'cover',
                             borderRadius: '15px',
                         }}/>
                </div>

                <div style={{marginTop: '4%'}}>
                    <div className={'text-center-v1 text-card'}>
                        <span className={'odt-font-family-secondary-bold odt-font-20'}> {data?.name} </span>
                        <span className={'odt-card-product-text odt-font-12'}>{data?.inventoryItem.facilityName}</span>
                        <span className={'odt-card-product-text odt-font-12'}>
                            {`${formatDefault(data?.offerMessage?.offerQuantity || data?.availableQuantity)} ${data?.quantityType}`}
                        </span>
                    </div>
                    <div className="position-static" style={{marginTop: '4%'}}>
                        <div className="position-static bottom-0 end-0">
                            <p className={'odt-p text-center-v1 text-card'} style={{marginBottom: '4%'}}>
                                {!data.isDonation ? (
                                    <span className={'odt-font-family-primary-bold odt-font-18 odt-font-p'}>{currencyFormatDefault(data?.listingPrice)}</span>
                                ) : null}
                                <span className={'odt-font-family-secondary-bold odt-font-15 odt-font-ff'}>
                                    {data?.currentStatus === 'in_process' ? 'In progress' : 'Completed'}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};
