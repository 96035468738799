import React from 'react';
import {SYMBOLS} from './ChartUtility';
import './index.css';


export const SingleBarChart = ({
                                   label,
                                   numerator,
                                   denominator = 100,
                                   symbol = 'percentage',
                                   fill = '#EF8667'
                               }: { label?: string, numerator: number, denominator?: number, symbol?: 'percentage', fill?: string }) => {

    const calc = () => {
        return (numerator || 1) / (denominator || 1) * 100;
    };

    return (
        <React.Fragment>
            {label ? (
                <div className={'d-block odt-padding-bottom-10'}>
                    <span className={'odt-font-family-secondary odt-font-22'}>
                        {label}<span className={'odt-font-family-secondary-bold'}>{` ${numerator}${SYMBOLS[symbol]}`}</span>
                    </span>
                </div>) : null}
            <div className={'d-block'}>
                <div className="row position-relative">
                    <div className={`odt-single-bar`} style={{width: `${denominator}%`}}/>
                    <div className={`odt-single-bar position-absolute`}
                         style={{width: `${calc()}%`, background: fill}}/>
                </div>
            </div>
        </React.Fragment>
    );
};


export const SingleMiniBarChart = ({
                                       width = 30,
                                       height = 16,
                                       fill = '#EF8667'
                                   }: { width?: number | string, height?: number | string, fill?: string }) => {


    return (
        <React.Fragment>
            <div className={`d-inline-block odt-single-mini-bar`}
                 style={{width: width, height: height, background: fill}}/>
        </React.Fragment>
    );
};
