import React, {useEffect, useState} from 'react';
import {BuyerSellerFlowView} from './BuyerSeller/BuyerSellerFlowView';
import {RateExperienceView} from './RateExperience/RateExperienceView';
import {ContactSupportView} from './ContactSupport/ContactSupportView';
import {
    initializeDataBuySellerProcessAction,
    initializeExternalDataBuySellerProcessAction
} from '../../Services/actions/BuyerSellerAction';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {BuyerSellerType} from '../../Services/reducers/states/BuyerSellerType';
import {optimize} from '../../Utilities/ImageUtility';

export const TermProcessContainerView = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const store = useSelector((state: BuyerSellerType) => state.process);

    const [form, setForm] = useState<{ view: 'default' | 'received' | 'support', loading: boolean }>({
        view: 'default',
        loading: true
    });

    useEffect(() => {
        dispatch(initializeExternalDataBuySellerProcessAction(store.keys));
        dispatch(initializeDataBuySellerProcessAction(store.keys, (success: boolean) => {
            if (success)
                setForm({...form, loading: false});
            if (!success)
                history.push('/login');
        }));
    }, [dispatch]);

    return form.loading ? (<div/>) : (
        <div className={'container-fluid odt-no-padding-left-right'}>
            <div className={'row odt-max-height odt-scroll-vertical odt-no-padding-left-right'}>
                <div id={'container-001'}
                     className={'col-md-6 col-lg-6 col-xl-6 col-xxl-6 odt-no-padding-left-right h-100'}>
                    <div className={'odt-full-background odt-font-45 align-bottom'} style={{
                        backgroundImage: `url("${optimize(store?.image?.url)  || ''}")`
                    }}>
                        <div className="d-flex align-items-end h-100 justify-content-center align-bottom">
                            <img src={'/customized/images/logo_coin_listo.png'} alt={'logo-coin-listo'}
                                 style={{width: '250px'}}/>
                        </div>
                    </div>
                </div>
                <div id={'container-002'}
                     className={'col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 odt-no-padding-left-right overflow-auto h-100'}>

                    <div id={'modal-container-002'} className={'position-fixed odt-w-half'}/>

                    <div className={'odt-padding-left-right-20 odt-font-family-secondary'} style={{paddingTop: '40px'}}>
                        {form.view === 'default' ? (
                            <BuyerSellerFlowView onRedirectReceived={() => setForm({...form, view: 'received'})}
                                                 onRedirectSupport={() => setForm({...form, view: 'support'})}/>
                        ) : null}

                        {form.view === 'received' ? (
                            <RateExperienceView onRedirectGoBack={() => setForm({...form, view: 'default'})}/>
                        ) : null}

                        {form.view === 'support' ? (
                            <ContactSupportView onRedirectGoBack={() => setForm({...form, view: 'default'})}/>
                        ) : null}

                    </div>

                </div>
            </div>
        </div>
    );
};
