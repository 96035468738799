import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Page} from '../../../Shared/Page/Page';
import {InputSearchInLineForm, InputTextForm, InputTextInLineForm} from '../../../Components/Inputs/InputBootstrap';
import {getBioFacilityTypeaheadSearchAction, updatePreferencesAction} from '../../../Services/actions/SettingsAction';
import {Button} from '../../../Components/Buttons/ButtonBootstrap';
import {useDispatch} from 'react-redux';

export const PreferencesView = () => {

    const dispatch = useDispatch();
    const [transporters, setTransporters] = useState<{ loading?: boolean, options: { id: any, name: any }[] }>();
    const [form, setForm] = useState<{
        preferredTransporterId: string,
        preferredTransporterCheck: boolean,
        rate: string,
        fee: string,
        invoices: string
    }>({
        preferredTransporterId: '',
        preferredTransporterCheck: false,
        rate: '',
        fee: '',
        invoices: ''
    });

    const {register, unregister, handleSubmit, formState: {errors}} = useForm({
        mode: 'onChange'
    });

    const handleNext = (data?: any) => {
        const body = {
            preferredTransporterId: data.preferredTransporterId,
            invoices: data.invoices.split(';'),
        };
        dispatch(updatePreferencesAction(body, () => {
        }));
    };

    const handleChangeField = (e: { target: any; }) => {
        const target = e.target;
        setForm({...form, [target.name]: target.value});

        if (target.name === 'preferredTransporterId')
            handleSearchTransporters(target.value);
    };

    const handleChangeCheckboxField = (e: { target: any; }) => {
        const target = e.target;
        if (target.name === 'preferredTransporterCheck') {
            target.checked ? unregister('preferredTransporterId') : register('preferredTransporterId');
        } // Dynamic unregister for form
        setForm({...form, [target.name]: target.checked});
    };

    const handleClickOption = (value?: string) => {
        if (value) {
            setForm({...form, 'preferredTransporterId': value});
            setTransporters(undefined);
        }
    };

    const handleSearchTransporters = (keyword: string) => {
        if (keyword && keyword.length > 2) {
            setTransporters({loading: true, options: []});
            getBioFacilityTypeaheadSearchAction(keyword).then(response => {
                const options = response.data.facilities.map((element: any) => {
                    element.id = element.guuid;
                    element.name = element.licenseType + ' ' + element.licenseNumber;
                    return element;
                });
                setTransporters({loading: false, options: options});
            }).catch(error => console.error(error));
        } else
            setTransporters({loading: false, options: []});
    };

    return (
        <Page mode={'private'}>
            <div className={'container-fluid'}>
                <div className={'row odt-card-product-canvas-items'} style={{paddingTop: '10px'}}>
                    <h3 className={'odt-font-family-primary-bold'}>Settings</h3>

                    <form onSubmit={handleSubmit(handleNext)}>
                        <div style={{paddingTop: '30px'}}>
                            <h3 className={'odt-font-family-primary-bold'} style={{paddingBottom: '20px'}}>Preferences</h3>

                            <div style={{marginBottom: '25px'}}>
                                <InputSearchInLineForm
                                    control={{...register('preferredTransporterId', {required: true})}}
                                    errors={errors}
                                    value={form.preferredTransporterId}
                                    options={transporters}
                                    autocomplete={false}
                                    mode={'primary_inline'}
                                    placeholder={'Enter the name or license of your preferred transporter'}
                                    onChange={handleChangeField}
                                    onClickOption={handleClickOption}/>

                                <InputTextForm control={{...register('preferredTransporterCheck', {required: false})}}
                                               errors={errors}
                                               type={'checkbox'}
                                               value={form.preferredTransporterCheck}
                                               placeholder={'I have a transporter License'}
                                               onChange={handleChangeCheckboxField}/>
                            </div>

                            {form.preferredTransporterCheck && (<div style={{marginBottom: '20px'}}>
                                <InputTextInLineForm control={{...register('rate', {required: true})}}
                                                     errors={errors}
                                                     label={'Rate cost per mile:'}
                                                     type={'number'}
                                                     numberType={'currency'}
                                                     value={form.rate}
                                                     autocomplete={false}
                                                     mode={'primary_inline'}
                                                     onChange={handleChangeField}/>
                            </div>)}

                            {form.preferredTransporterCheck && (<div style={{marginBottom: '20px'}}>
                                <InputTextInLineForm control={{...register('fee', {required: true})}}
                                                     errors={errors}
                                                     label={'Fee - General cost:'}
                                                     type={'number'}
                                                     numberType={'currency'}
                                                     value={form.fee}
                                                     autocomplete={false}
                                                     mode={'primary_inline'}
                                                     onChange={handleChangeField}/>
                            </div>)}

                            <div style={{marginBottom: '20px'}}>
                                <InputTextInLineForm control={{...register('invoices', {required: true})}}
                                                     errors={errors}
                                                     value={form.invoices}
                                                     placeholder={'Invoice-Purchase Order Notification Email'}
                                                     autocomplete={false}
                                                     mode={'primary_inline'}
                                                     onChange={handleChangeField}/>
                            </div>

                        </div>

                        <div className={'row odt-no-padding'}>
                            <div className="col-md-4 offset-md-8">
                                <Button mode={'primary'} text={'Send'} classes={'odt-font-family-primary-bold'}
                                        align={'text-end'} style={{background: '#183E43'}}/>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </Page>
    );
};
