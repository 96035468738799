import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button} from '../../../Components/Buttons/ButtonBootstrap';
import {InputTextAreaForm} from '../../../Components/Inputs/InputBootstrap';
import {Rate} from '../../../Shared/Rate/Rate';
import {addModal} from '../../../Shared/Dialog/Dialog';
import {useDispatch, useSelector} from 'react-redux';
import {createMessageFeedbackAction} from '../../../Services/actions/BuyerSellerAction';
import {BuyerSellerType} from '../../../Services/reducers/states/BuyerSellerType';

export const RateExperienceView = ({onRedirectGoBack}: { onRedirectGoBack: () => void }) => {

    const dispatch = useDispatch();
    const storeProcess = useSelector((state: BuyerSellerType) => state.process);
    const [form, setForm] = useState<{ dealUuid: string, rate: number, comments: string }>({
        dealUuid: '',
        rate: 0,
        comments: '',
    });

    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: 'onChange'
    });

    const handleSubmitForm = (data: { details: string }) => {
        dispatch(createMessageFeedbackAction(form.dealUuid, form.rate, form.comments, (success: boolean) => {
            if (success) {
                addModal('modal-container-002', 'Thanks for your feedback!');
            }
            onRedirectGoBack();
        }));
    };

    const handleChangeField = (e: { target: any; }) => {
        const target = e.target;
        setForm({...form, [target.name]: target.value});
    };

    useEffect(() => {
        setForm({
            ...form,
            dealUuid: `${storeProcess.dealUid}`,
        });
    }, []);

    return (
        <div className={'odt-font-family-secondary'}>

            <p className={'odt-p'}>
                <span className={'odt-font-30 odt-font-family-primary-bold'}>{'Great!'}</span>
                <span className={'odt-font-18'}>{'How was your experience?'}</span>
            </p>

            <form onSubmit={handleSubmit(handleSubmitForm)}>

                <div className={'odt-panel-v2'}>

                    <div className={`d-flex justify-content-center`} style={{padding: '8% 0 2% 0'}}>
                        <Rate type={'start'} size={5} onClick={(value) => setForm({...form, rate: value})}/>
                    </div>

                    <div className={'odt-panel-v2-field'}>
                        <InputTextAreaForm control={{...register('comments', {required: true})}}
                                           errors={errors}
                                           mode={'secondary'}
                                           classes={'odt-input-text-area-v2'}
                                           value={form.comments}
                                           placeholder={'More to share? Tell us here.'}
                                           onChange={handleChangeField}/>
                    </div>

                </div>

                <div style={{padding: '3% 0 2% 0'}}>
                    <Button mode={'secondary'} text={'Send'}/>
                    <Button mode={'tertiary'} text={'Back'} onClick={onRedirectGoBack}/>
                </div>

            </form>

        </div>
    );
};
