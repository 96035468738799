import React, {useEffect, useState} from 'react';

export const Tabs = ({
                         children,
                         headers,
                         theme = 'default',
                     }: { children: JSX.Element[], headers: { title: string, action: () => void, enable: boolean }[], theme?: 'default' | 'light' }) => {

    const TABS_THEME = {
        default: {
            tabs: 'odt-nav-tabs',
            tab: 'odt-nav-tab',
            inactive: '',
            active: 'odt-nav-tab-active'
        },
        light: {
            tabs: 'odt-nav-tabs-light',
            tab: 'odt-nav-tab-light',
            inactive: 'odt-nav-tab-light-inactive odt-font-family-secondary',
            active: 'odt-nav-tab-light-active odt-font-family-secondary-bold '
        }
    };

    const [currentTabForm, setCurrentTabForm] = useState<{ index: number }>({
        index: 0,
    });

    const [tabsForm, setTabsForm] = useState<{ headers: { title: string, action: () => void }[] }>({
        headers: [],
    });

    const isActiveTab = (index: number) => {
        return currentTabForm.index === index;
    };

    useEffect(() => {
        const tabs = headers.filter(f => f.enable).map((tab) => {
            return {title: tab.title, action: tab.action};
        });
        setTabsForm({headers: tabs});
    }, [headers]);

    return (
        <div className={`${TABS_THEME[theme].tabs}`}>
            <ul className={'nav nav-tabs text-center odt-font-family-primary-bold'}>
                {tabsForm.headers && tabsForm.headers.map((header, index) => (
                    <li key={index}
                        className={`nav-item ${TABS_THEME[theme].tab} ` + (isActiveTab(index) ? TABS_THEME[theme].active : TABS_THEME[theme].inactive)}
                        style={{cursor: 'pointer', width: `${100 / tabsForm.headers.length}%`}}
                        onClick={() => {
                            header.action();
                            setTabsForm({...tabsForm});
                            setCurrentTabForm({index: index});
                        }}>
                        <a className={'nav-link odt-font-p'} data-bs-toggle={'tab'}
                           style={{color: 'inherit'}}>{header.title}</a>
                    </li>
                ))}
            </ul>

            <div className={'tab-content'}>
                {children && children.map((element, index) => (
                    <div key={index} className={'tab-pane fade ' + (isActiveTab(index) ? 'show active' : '')}>
                        {element}
                    </div>
                ))}
            </div>
        </div>
    );
};

export const Tab = ({children}: { children: JSX.Element | JSX.Element[] }) => {
    return (
        <div className={'odt-font-family-secondary'} style={{paddingTop: '20px'}}>
            {children}
        </div>
    );
};
