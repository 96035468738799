import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { InputTextInLineForm } from "../../../Components/Inputs/InputBootstrap";
import { getCityStateAction } from "../../../Services/actions/SettingsAction";
import { emailRegex, zipRegex } from "../../../Utilities/Constant";

interface BillingDetailProps {
  billingAddress: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  phone: string;
  email: string;
  routingNumber: string;
  accountNumber: string;
  nameOnAccount: string;
  expirationMmyy: string;
  ccvn: string;
  bankAccountId: string;
  accountNumberCard: string;
  cardholderName: string;
  bankName: string;
  bankCityState: string;
  billingAddressList: any;
  setBillingAddressList: any;
}

interface ZipCode {
  state: string;
  county: string;
  city: string;
  zipcode: string;
}

const BillingDetailForm = ({
  billingAddress = "",
  billingCity = "",
  billingState = "",
  billingZip = "",
  phone = "",
  email = "",
  routingNumber = "",
  accountNumber = "",
  nameOnAccount = "",
  expirationMmyy = "",
  ccvn = "",
  bankAccountId = "",
  accountNumberCard = "",
  cardholderName = "",
  bankName = "",
  bankCityState = "",
  billingAddressList = [],
  setBillingAddressList,
}: BillingDetailProps) => {
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handleChangeField = async (
    e: { target: any },
    bankAccountId: string,
  ) => {
    const target = e.target;
    const id = bankAccountId;
    let currentForm = billingAddressList?.find(
      (address: any) => address.bankAccountId === id,
    );
    const updatedForm: any = { ...currentForm, [target.name]: target.value };
    const handlePopulateCityState = (data: ZipCode) => {
      updatedForm["billingCity"] = data?.city;
      updatedForm["billingState"] = data?.state;
    };
    if (target.name === "billingZip" && target.value.length === 5) {
      const body = {
        zipcode: target.value,
      };
      await dispatch(getCityStateAction(body, handlePopulateCityState));
    }
    const cloneBillingAddressList: any = billingAddressList?.map(
      (address: any) => {
        if (address.bankAccountId === id) {
          return updatedForm;
        }
        return address;
      },
    );
    setBillingAddressList(cloneBillingAddressList);
  };

  return (
    <div
      key={bankAccountId}
      className={"col-9 mt-4"}
      style={{
        padding: "15px 30px",
        border: "0.5px solid #ccc",
        borderRadius: "10px",
      }}
    >
      <div className={"d-flex align-items-top justify-content-between"}>
        <div style={{ marginBottom: "20px", marginRight: "15px" }}>
          <div
            className="d-flex align-top justify-content-between"
            style={{
              marginBottom: "5px",
            }}
          >
            <label style={{ marginRight: "10px" }}>Billing Address</label>
            <InputTextInLineForm
              control={{
                ...register("billingAddress", {
                  required: true,
                  value: billingAddress,
                }),
              }}
              errors={errors}
              value={billingAddress}
              placeholder={""}
              autocomplete={false}
              mode={"primary_inline"}
              onChange={(e) => handleChangeField(e, bankAccountId)}
            />
          </div>
          <div
            className="d-flex align-top justify-content-between"
            style={{
              marginBottom: "5px",
            }}
          >
            <label style={{ marginRight: "10px" }}>City</label>
            <InputTextInLineForm
              control={{
                ...register("billingCity", {
                  value: billingCity,
                }),
              }}
              errors={errors}
              value={billingCity}
              placeholder={""}
              autocomplete={false}
              mode={"primary_inline"}
              onChange={(e) => handleChangeField(e, bankAccountId)}
              enable={false}
            />
          </div>
          <div
            className="d-flex align-top justify-content-between"
            style={{
              marginBottom: "5px",
            }}
          >
            <label style={{ marginRight: "10px" }}>State</label>
            <InputTextInLineForm
              control={{
                ...register("billingState", {
                  value: billingState,
                }),
              }}
              errors={errors}
              value={billingState}
              placeholder={""}
              autocomplete={false}
              mode={"primary_inline"}
              onChange={(e) => handleChangeField(e, bankAccountId)}
              enable={false}
            />
          </div>
          <div
            className="d-flex align-top justify-content-between"
            style={{
              marginBottom: "5px",
            }}
          >
            <label style={{ marginRight: "10px" }}>Zip</label>
            <InputTextInLineForm
              control={{
                ...register("billingZip", {
                  required: true,
                  value: billingZip,
                  pattern: zipRegex,
                }),
              }}
              errors={errors}
              value={billingZip}
              placeholder={""}
              autocomplete={false}
              mode={"primary_inline"}
              onChange={(e) => handleChangeField(e, bankAccountId)}
              type={"number"}
            />
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <div
            className="d-flex align-top justify-content-between"
            style={{
              marginBottom: "5px",
            }}
          >
            <label style={{ marginRight: "10px" }}>Phone#</label>
            <InputTextInLineForm
              control={{
                ...register("phone", { value: phone, required: true }),
              }}
              errors={errors}
              value={phone}
              placeholder={""}
              autocomplete={false}
              mode={"primary_inline"}
              onChange={(e) => handleChangeField(e, bankAccountId)}
              type={"number"}
            />
          </div>
          <div
            className="d-flex align-top justify-content-between"
            style={{
              marginBottom: "5px",
            }}
          >
            <label style={{ marginRight: "10px" }}>email</label>
            <InputTextInLineForm
              control={{
                ...register("email", {
                  required: true,
                  value: email,
                  pattern: emailRegex,
                }),
              }}
              errors={errors}
              value={email}
              placeholder={""}
              autocomplete={false}
              mode={"primary_inline"}
              onChange={(e) => handleChangeField(e, bankAccountId)}
              type={"email"}
            />
          </div>
        </div>
      </div>

      <div className={"d-flex align-items-top justify-content-between"}>
        <div>
          <h6 className={"text-center odt-font-family-primary-bold"}>
            Checking/ Saving Account
          </h6>
          <div
            style={{
              padding: "15px",
              marginRight: "15px",
              border: "0.5px solid #ccc",
              borderRadius: "3px",
              minHeight: "280px",
            }}
          >
            <div>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>Checking</label>
                <label style={{ marginRight: "10px" }}>Saving</label>
              </div>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label
                  style={{
                    marginRight: "10px",
                    display: "block",
                  }}
                >
                  Name on Acct
                </label>
                <InputTextInLineForm
                  control={{
                    ...register("nameOnAccount", {
                      required: true,
                      value: nameOnAccount,
                    }),
                  }}
                  errors={errors}
                  value={nameOnAccount}
                  placeholder={""}
                  autocomplete={false}
                  mode={"primary_inline"}
                  onChange={(e) => handleChangeField(e, bankAccountId)}
                />
              </div>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>Bank Name</label>
                <InputTextInLineForm
                  control={{
                    ...register("bankName", {
                      required: true,
                      value: bankName,
                    }),
                  }}
                  errors={errors}
                  value={bankName}
                  placeholder={""}
                  autocomplete={false}
                  mode={"primary_inline"}
                  onChange={(e) => handleChangeField(e, bankAccountId)}
                />
              </div>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>Account Number</label>
                <InputTextInLineForm
                  control={{
                    ...register("accountNumber", {
                      required: true,
                      value: accountNumber,
                    }),
                  }}
                  errors={errors}
                  value={accountNumber}
                  placeholder={""}
                  autocomplete={false}
                  mode={"primary_inline"}
                  onChange={(e) => handleChangeField(e, bankAccountId)}
                  type={"number"}
                />
              </div>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>Bank Routing#</label>
                <InputTextInLineForm
                  control={{
                    ...register("routingNumber", {
                      required: true,
                      value: routingNumber,
                    }),
                  }}
                  errors={errors}
                  value={routingNumber}
                  placeholder={""}
                  autocomplete={false}
                  mode={"primary_inline"}
                  onChange={(e) => handleChangeField(e, bankAccountId)}
                  type={"number"}
                />
              </div>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>Bank City/State</label>
                <InputTextInLineForm
                  control={{
                    ...register("bankCityState", {
                      required: true,
                      value: bankCityState,
                    }),
                  }}
                  errors={errors}
                  value={bankCityState}
                  placeholder={""}
                  autocomplete={false}
                  mode={"primary_inline"}
                  onChange={(e) => handleChangeField(e, bankAccountId)}
                />
              </div>
              <div className="px-3 py-2">
                <img
                  src={"/customized/images/billing bank details.png"}
                  alt={"routing-account-sample"}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <h6 className={"text-center odt-font-family-primary-bold"}>
            Credit Card
          </h6>
          <div
            style={{
              padding: "15px",
              border: "0.5px solid #ccc",
              borderRadius: "3px",
              minHeight: "360px",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>Visa</label>
                <label style={{ marginRight: "10px" }}>MasterCard</label>
              </div>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>Amex</label>
                <label style={{ marginRight: "10px" }}>Discover</label>
              </div>

              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>Cardholder Name</label>
                <InputTextInLineForm
                  control={{
                    ...register("cardholderName", {
                      required: true,
                      value: cardholderName,
                    }),
                  }}
                  errors={errors}
                  value={cardholderName}
                  placeholder={""}
                  autocomplete={false}
                  mode={"primary_inline"}
                  onChange={(e) => handleChangeField(e, bankAccountId)}
                />
              </div>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>Account Number</label>
                <InputTextInLineForm
                  control={{
                    ...register("accountNumberCard", {
                      required: true,
                      value: accountNumberCard,
                    }),
                  }}
                  errors={errors}
                  value={accountNumberCard}
                  placeholder={""}
                  autocomplete={false}
                  mode={"primary_inline"}
                  onChange={(e) => handleChangeField(e, bankAccountId)}
                  type={"number"}
                />
              </div>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>ccvn</label>
                <InputTextInLineForm
                  control={{
                    ...register("ccvn", { value: ccvn, required: true }),
                  }}
                  errors={errors}
                  value={ccvn}
                  placeholder={""}
                  autocomplete={false}
                  mode={"primary_inline"}
                  onChange={(e) => handleChangeField(e, bankAccountId)}
                  type={"number"}
                />
              </div>
              <div
                className={"d-flex align-items-start justify-content-between"}
                style={{ marginBottom: "5px" }}
              >
                <label style={{ marginRight: "10px" }}>Exp. Date</label>
                <InputTextInLineForm
                  control={{
                    ...register("expirationMmyy", {
                      required: true,
                      value: expirationMmyy,
                    }),
                  }}
                  errors={errors}
                  value={expirationMmyy}
                  placeholder={""}
                  autocomplete={false}
                  mode={"primary_inline"}
                  onChange={(e) => handleChangeField(e, bankAccountId)}
                  // type={"date"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetailForm;
