import React from 'react';
import ReactDOM from 'react-dom';

const uuid = require('react-uuid');

export const addModal = (parentId: string, message: string, fullscreen?: boolean) => {
    const parent = document.getElementById(parentId);
    if (parent) {

        const timeout = setTimeout(() => {
            // @ts-ignore
            ReactDOM.unmountComponentAtNode(document.getElementById(parentId));
        }, 1000 * 10);

        const id = `${uuid()}`;
        const handleOnClose = () => {
            if (timeout) {
                clearTimeout(timeout);
                // @ts-ignore
                ReactDOM.unmountComponentAtNode(document.getElementById(parentId));
            }
        };
        const modal = <Message id={id} message={message} fullscreen={fullscreen} onClose={handleOnClose} />;
        ReactDOM.render(modal, parent);
    }
};


const Message = ({
                     id,
                     message,
                     onClose,
                     fullscreen = false
                 }: { id: string, message: string, onClose: () => void, fullscreen?: boolean }) => {
    return (
        <div id={id} className={'w-100'}>

            <div className={`odt-loading${fullscreen ? '-fullscreen' : ''}`}/>

            <div className="d-flex justify-content-center odt-font-family-secondary odt-font-22" style={{marginBottom: '50%'}}>

                <div className="odt-dialog">
                    <div className={'odt-dialog-header'}>
                        <div className={'position-relative'}>
                            <div className={'position-absolute end-0 odt-mouse-pointer'} onClick={onClose} >
                                <div className="text-center">
                                    <img src={'/customized/images/icons/close.icon.svg'} alt={'close-icon'} width={30}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'odt-dialog-body text-center align-middle'}>
                        <span>{`${message}`}</span>
                    </div>
                </div>
            </div>

        </div>);
};
