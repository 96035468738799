import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Page} from '../../../Shared/Page/Page';
import {hideLoadingAction, showLoadingAction} from '../../../Services/actions/UtilityAction';
import {
    addCryptoToWalletByBioFacilityAction,
    addTagBioFacilityAction,
    getBioFacilityInfoAction,
    getCryptoWalletByBioFacilityAction,
    getTagsByBioFacilityInfoAction,
    removeTagBioFacilityAction,
    updateBioFacilityInfoAction,
    uploadFileBioFacilityInfoAction
} from '../../../Services/actions/SettingsAction';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IoIosArrowDown, IoIosArrowUp, IoQrCodeOutline} from 'react-icons/all';
import {FILTERS} from '../../Marketplace/components/Filters';
import {AuthenticationData} from '../../../Services/reducers/states/AuthenticationData';
import {Button} from '../../../Components/Buttons/ButtonBootstrap';
import {InputTextInLineForm} from '../../../Components/Inputs/InputBootstrap';

export const FacilityDetailView = () => {

    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();
    const store = useSelector((state: AuthenticationData) => state.auth.user);

    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [form, setForm] = useState<{
        renderForm_: boolean,
        facility?: {
            id: string,
            licenseNumber: string,
            logo: string,
            name: string,
            organization: string
        },
        bioHeadline: string,
        bioJsonData: {},
        bioTextData: string,
        bioUid: string,
        dbaName: string,
        photosOptions: { name: string, icon: string, file?: any }[],
    }>({
        renderForm_: false,
        bioHeadline: '',
        bioJsonData: {},
        bioTextData: '',
        bioUid: '',
        dbaName: '',
        facility: {
            id: '',
            licenseNumber: '',
            logo: '',
            name: '',
            organization: ''
        },
        photosOptions: [{name: 'Add photo', icon: '/customized/images/icons/AddPhotos.svg'}],
    });
    const [formFilters, setFormFilters] = useState<{
        filters: { key: string, label: string, icon?: string, tags: { label: string, key: string, icon?: string, selected?: boolean }[] }[],
        filtersSelected: string[],
    }>({
        filters: FILTERS,
        filtersSelected: []
    });
    const [formCrypto, setFormCrypto] = useState<{
        cryptoOptions: { id: string, name: string, selected: boolean }[],
        walletType: string,
        walletCode: string,
    }>({
        cryptoOptions: [
            {id: 'bitcoin', name: 'Bitcoin', selected: false},
            {id: 'ethereum', name: 'Ethereum', selected: false},
            {id: 'litecoin',name: 'Litecoin', selected: false},
            {id: 'none', name: 'Not Accepted', selected: false}
        ],
        walletType: '',
        walletCode: '',
    });

    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: 'onChange'
    });

    const handleChangeFieldForm = (e: { target: any; }) => {
        const target = e.target;
        setForm({...form, [target.name]: target.value});
    };

    const handleChangeFieldFormCrypto = (e: { target: any; }) => {
        const target = e.target;
        setFormCrypto({...formCrypto, [target.name]: target.value});
    };

    const handleFileUpload = (index: number) => {
        if (index === 0 && hiddenFileInput.current)
            hiddenFileInput?.current.click();
    };


    const handleSubmitFormAction = (data?: any) => {
        const bio = {
            id: form.facility?.id,
            bioHeadline: data.bioHeadline,
            bioTextData: data.bioTextData,
            dbaName: data.dbaName,
            enabled: true
        };
        dispatch(updateBioFacilityInfoAction(bio, () => {
        }));

        const crypto = {
            type: formCrypto.walletType,
            wallet: formCrypto.walletCode,
        };
        dispatch(addCryptoToWalletByBioFacilityAction(crypto, () => {}));

    };

    const handleChangeFileUploadAction = (e: { target: any; }) => {
        const fileUpload = e.target.files[0];

        dispatch(uploadFileBioFacilityInfoAction(form.facility?.id, fileUpload, () => {
        }));

        setForm({
            ...form, photosOptions: [
                ...form.photosOptions,
                {
                    name: '',
                    icon: URL.createObjectURL(fileUpload),
                    file: fileUpload
                }
            ]
        });
    };

    const handleAddOrRemoveTagBioFacilityAction = (filterIndex: number, filterIndexTag: number) => {
        const tag = formFilters.filters[filterIndex].tags[filterIndexTag].key;
        const selected = formFilters.filters[filterIndex].tags[filterIndexTag].selected;
        if (selected) {
            dispatch(removeTagBioFacilityAction(form.facility?.id, tag, (data: any) => {
                formFilters.filters[filterIndex].tags[filterIndexTag].selected = !formFilters.filters[filterIndex].tags[filterIndexTag].selected;
                setFormFilters({...formFilters, filters: formFilters.filters});
            }));
        } else {
            dispatch(addTagBioFacilityAction(form.facility?.id, tag, (data: any) => {
                formFilters.filters[filterIndex].tags[filterIndexTag].selected = !formFilters.filters[filterIndex].tags[filterIndexTag].selected;
                setFormFilters({...formFilters, filters: formFilters.filters});
            }));
        }
    };


    const photosOwnerFacilityRender_ = () => {
        return (
            <div style={{marginBottom: '5px'}}>
                <h2 className={'odt-font-family-primary-bold'} style={{paddingBottom: '30px'}}>{'Photos:'}</h2>
                <div style={{height: '100%'}}>
                    {form.photosOptions && (form.photosOptions.map((element, index) => (
                        <div key={index} className={'d-inline-block align-top odt-item-tag-v2'} onClick={() => handleFileUpload(index)}>
                            <div className={`d-flex justify-content-center`}>
                                <div className={`text-center odt-item-tag-image`}>
                                    {index === 0 ? (
                                            <img src={`${element.icon}`} style={{
                                                width: '60%', height: '60%', marginTop: '20%',
                                            }} alt={`add-photo-${index}`}/>)
                                        : (
                                            <img src={element.icon} alt={`photo-${index}`} style={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '50%',
                                                objectFit: 'cover'
                                            }}/>)}

                                    {index === 0 ? (
                                        <input type={'file'} multiple ref={hiddenFileInput}
                                               onChange={handleChangeFileUploadAction}
                                               style={{display: 'none'}}/>) : null}
                                </div>
                            </div>
                            {element.name ? (
                                <div className={'text-center odt-font-family-secondary odt-font-18v2 odt-wrap-text '}
                                     style={{
                                         paddingTop: '15px',
                                         paddingBottom: '30px',
                                     }}>{element.name}</div>) : null}
                        </div>
                    )))}
                </div>
            </div>
        )
    }

    const tagsOwnerFacilityRender_ = () => {
        return (
            <React.Fragment>
                {formFilters.filters.map((element, index) => (
                    element.tags && !!element.tags.length ? (
                        <div key={index} style={{paddingBottom: '30px'}}>
                            <h2 className={'odt-font-family-primary-bold'} style={{paddingBottom: '30px'}}>{`${element.label}:`}</h2>
                            {element.tags.map((tag, indexTag) => (
                                <div key={indexTag} className={'d-inline-block text-center odt-item-tag'} >
                                    <div className={`d-flex justify-content-center`} onClick={() => handleAddOrRemoveTagBioFacilityAction(index, indexTag)}>
                                        <div className={`odt-item-tag-image ${formFilters.filters[index].tags[indexTag].selected ? 'odt-is-active-v2' : ''}`}>
                                            <img alt={`tag-${index}-facility`}
                                                 src={`/customized/images/icons/${tag.icon}`} style={{
                                                width: '60%',
                                                height: '60%',
                                                marginTop: '20%'
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={'odt-item-tag-description odt-font-family-secondary odt-font-18v2'}>{tag.label}</div>
                                </div>
                            ))}
                        </div>
                    ) : null
                ))}
            </React.Fragment>
        );
    };

    const cryptosOwnerFacilityRender_ = () => {
      return (
          <div style={{marginBottom: '25px'}}>
              <div className="d-flex justify-content-between" style={{cursor: 'pointer'}} >
                  <h2 className={'odt-font-family-primary-bold'} style={{paddingBottom: '25px'}}>{'Crypto:'}</h2>
              </div>

              {formCrypto.cryptoOptions && formCrypto.cryptoOptions.map((element, index) => (
                  <React.Fragment key={index}>
                      <div key={index} style={{marginBottom: '5px', cursor: 'pointer'}} onClick={() => {
                          setFormCrypto({
                              ...formCrypto,
                              walletType: formCrypto.cryptoOptions[index].id,
                              cryptoOptions: formCrypto.cryptoOptions.map((crypto, it) => {
                                  crypto.selected = it === index;
                                  return crypto;
                              })
                          });
                      }}>
                          <div className="d-flex justify-content-between">
                              <span className={'odt-font-family-secondary odt-font-16'} style={{paddingLeft: 'inherit'}}>{element.name}</span>
                              <i className={'text-end'} style={{fontSize: '16px', paddingRight: '10px'}}> {element.selected ? <IoIosArrowUp/> : <IoIosArrowDown/>}  </i>
                          </div>
                          <div className={'odt-card-product-separator'}/>
                      </div>
                      {formCrypto.cryptoOptions[index].selected ?
                          (<div style={{paddingLeft: '2px', paddingBottom: '20px'}}>
                              <span className={'odt-font-family-secondary-bold odt-font-14'}>{'Wallet Address'}</span>
                              <InputTextInLineForm control={{...register('walletCode', {required: true})}}
                                                   classes={'odt-font-14'}
                                                   errors={errors}
                                                   value={formCrypto.walletCode}
                                                   placeholder={'Bitcoin wallet address'}
                                                   icon={<IoQrCodeOutline/>}
                                                   autocomplete={false}
                                                   mode={'default_inline'}
                                                   onChange={handleChangeFieldFormCrypto}/>
                          </div>) : null
                      }
                  </React.Fragment>
              ))}

          </div>
      )
    }

    useEffect(() => {
        dispatch(showLoadingAction());

        const facilityId = params.id;
        const facilities = store.facilities.filter(f => f.id === facilityId);
        const facility = facilityId && !!facilities.length ? facilities[0] : {
            name: '',
            logo: '',
            licenseNumber: '',
            organization: ''
        };

        Promise.all(getBioFacilityInfoAction(facilityId)).then((response) => {

            const facilityBio = !!response.length && response.length >= 1 ? response[0].data?.facilityBio : {};
            const facilityData = !!response.length && response.length >= 1 ? response[1].data?.facilityData : {};

            const photos = [{name: 'Add photo', icon: '/customized/images/icons/AddPhotos.svg'}];
            facilityData.images?.forEach((image: any) => {
                photos.push({name: '', icon: image.url});
            });

            setForm({
                ...form,
                renderForm_: true,
                bioHeadline: facilityBio.bioHeadline,
                bioTextData: facilityBio.bioTextData,
                bioUid: facilityBio.bioUid,
                dbaName: facilityBio.dbaName,
                bioJsonData: (facilityBio && facilityBio.bioJsonData && JSON.parse(facilityBio.bioJsonData)) || {},
                facility: {
                    id: params.id,
                    name: facility.name,
                    logo: facility.logo,
                    licenseNumber: facility.licenseNumber,
                    organization: facility.organization
                },
                photosOptions: photos,
            });

            dispatch(getTagsByBioFacilityInfoAction(facilityId, (data: { tag: string } []) => {
                const filtersSelected = data.map((element, index) => element.tag);

                formFilters.filters.map((element, index) => {
                    if (!!element.tags.length) {
                        const tags: { label: string, key: string, icon?: string, selected?: boolean }[] = element.tags;
                        tags.map((elementA, indexA) => {
                            if (filtersSelected.includes(elementA.key))
                                formFilters.filters[index].tags[indexA].selected = true;
                        });
                    }
                });

                setFormFilters({...formFilters, filters: formFilters.filters});
            }));

            dispatch(getCryptoWalletByBioFacilityAction(facilityId, (data: any) => {

            }));

        }).catch((response) => {
            console.error(response);
        }).finally(() => dispatch(hideLoadingAction()));
    }, [dispatch]);

    return (
        <Page mode={'private'}>
            <div className={'container-fluid'}>
                <div className={'row odt-card-product-canvas-items'} style={{paddingTop: '10px'}}>
                    <h3 className={'odt-font-family-primary-bold'}>Settings</h3>

                    {form.renderForm_ ? (
                        <form onSubmit={handleSubmit(handleSubmitFormAction)}>

                            <div style={{paddingTop: '30px'}}>
                                <h3 className={'odt-font-family-primary-bold'} style={{paddingBottom: '20px'}}>
                                    Facility info</h3>

                                {form.facility && !!form.facility.name.length && (
                                    <div className={'odt-font-family-primary-bold'} style={{marginBottom: '40px'}}>
                                        <div className={'d-inline-block'}>
                                            <div className={'odt-card-profile-icon'}>
                                                <img src={form.facility.logo}
                                                     style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
                                            </div>
                                        </div>
                                        <div className={'d-inline-block align-middle'} style={{marginLeft: '10px'}}>
                                            <h4>{form.facility.name}</h4>
                                            <span className={'odt-font-20'}
                                                  style={{paddingLeft: 'inherit'}}>{form.facility.licenseNumber}</span>
                                        </div>
                                    </div>)}

                                <div style={{marginBottom: '25px'}}>
                                    <h2 className={'odt-font-family-primary-bold'}>{'Doing Business As:'}</h2>
                                    <InputTextInLineForm control={{...register('dbaName', {required: true})}}
                                                         errors={errors}
                                                         value={form.dbaName}
                                                         autocomplete={false}
                                                         mode={'primary_inline'}
                                                         onChange={handleChangeFieldForm}/>
                                </div>

                                <div style={{marginBottom: '25px'}}>
                                    <h2 className={'odt-font-family-primary-bold'}>{'Bio:'}</h2>
                                    <h4 className={'odt-font-family-primary-bold'}>{'Headline:'}</h4>
                                    <InputTextInLineForm control={{...register('bioHeadline', {required: true})}}
                                                         errors={errors}
                                                         value={form.bioHeadline}
                                                         autocomplete={false}
                                                         mode={'primary_inline'}
                                                         onChange={handleChangeFieldForm}/>
                                </div>

                                <div style={{marginBottom: '25px'}}>
                                    <h2 className={'odt-font-family-primary-bold'}>{'Body:'}</h2>
                                    <InputTextInLineForm control={{...register('bioTextData', {required: true})}}
                                                         errors={errors}
                                                         value={form.bioTextData}
                                                         autocomplete={false}
                                                         mode={'primary_inline'}
                                                         onChange={handleChangeFieldForm}/>
                                </div>

                                {photosOwnerFacilityRender_()}

                                {tagsOwnerFacilityRender_()}

                                {cryptosOwnerFacilityRender_()}

                            </div>

                            <div className={'row odt-no-padding'}>
                                <div className="col-md-4 offset-md-8">
                                    <Button mode={'primary'} text={'Send'} classes={'odt-font-family-primary-bold'} align={'text-end'} />
                                </div>
                            </div>

                        </form>
                    ) : null}

                </div>
            </div>
        </Page>
    );
};
