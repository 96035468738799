export const currencyFormatDefault = (value: (number | undefined)) => {
    return '$' + (value ? value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '');
};

export const currencyFormat = (value: (number | string | undefined), decimals: number) => {
    let amount = 0;
    if (typeof value === 'string' && isNumber(value))
        amount = Number(value);
    if (typeof value === 'number')
        amount = value;
    if (amount === 0)
        return '0';
    return (amount) ? amount.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '';
};

export const formatDefault = (value: (number | undefined)) => {
    return (value ? value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '');
};

export const isNumber = (value: string | undefined) => {
    if (value)
        return /^-?[\d.]+(?:e-?\d+)?$/.test(value);
    return false;
};
