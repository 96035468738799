import {RefObject} from 'react';

export class ScrollUtility {

    static scrollTo(element: RefObject<any>, delay: number, behavior: ScrollBehavior = 'smooth') {
        if (typeof window === 'undefined')
            return;

        window.requestAnimationFrame(() => {
            let offset = element.current && element.current.offsetTop ? element.current.offsetTop : 0;
            try {
                let bodyRect = document.body.getBoundingClientRect();
                let bodyStyle = window.getComputedStyle(document.body, null);

                // need to handle the padding for the top of the body
                let paddingTop = parseFloat(bodyStyle.getPropertyValue('padding-top'));

                let elementRect = element.current.getBoundingClientRect();
                offset = elementRect.top - paddingTop - bodyRect.top;
            } catch (exception) {
                console.error(exception);
            }

            if (delay) {
                setTimeout(() => {
                    window.scrollTo({top: offset, left: 0, behavior: behavior});
                }, delay);
            } else {
                window.scrollTo({top: offset, left: 0, behavior: behavior});
            }
        });
    }

    static scrollIntoView(element: RefObject<any>, delay: number, behavior: ScrollBehavior = 'smooth') {
        if (delay) {
            setTimeout(() => {
                element.current.scrollIntoView({behavior});
            }, delay);
        } else element.current.scrollIntoView({behavior});
    }

    static setScrollPosition({top = 0, left = 0} = {}, delay: number, behavior: ScrollBehavior = 'smooth') {
        if (delay) {
            setTimeout(() => {
                window.scroll({top, left, behavior});
            }, delay);
        } else {
            window.scroll({top: top, left: left, behavior});
        }
    }

    static scrollTop(delay: number, behavior: ScrollBehavior = 'smooth') {
        if (typeof window === 'undefined') return;

        window.requestAnimationFrame(() => {
            if (delay) {
                setTimeout(() => {
                    window.scroll({top: 0, left: 0, behavior});
                }, delay);
            } else window.scroll({top: 0, left: 0, behavior});
        });
    }
}
