import React from 'react';
import './index.css';

import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import {COLORS} from './ChartUtility';


ChartJS.register(ArcElement, Tooltip, Legend);


export interface PieDataChartType {
    label: string,
    shortLabel: string,
    value: number,
    fill: string
}

export const PieChart = ({
                             className = '',
                             width = 300,
                             data,
                             summary
                         }: {
    className: string,
    width?: number,
    data: PieDataChartType[],
    summary?: (data: PieDataChartType[]) => JSX.Element
}) => {

    const DATA_CHART = {
        labels: (data && data.map((item) => `${item.shortLabel}`)) || [],
        datasets: [
            {
                label: '',
                data: (data && data.map((item) => item.value)) || [],
                backgroundColor: (data && data.map((item) => item.fill)) || COLORS,
            },
        ],
    };

    return (
        <div className={`${className}`}>
            <div style={{width: `${width}px`, height: `100%`, margin: '0 auto', paddingBottom: '30px'}}>
                <Pie data={DATA_CHART} options={{plugins: {legend: {display: false}}}} style={{cursor: 'pointer'}}/>
            </div>
            {summary && summary(data)}
        </div>
    );
};
