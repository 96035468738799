import {useDispatch} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {InputTextForm} from '../../Components/Inputs/InputBootstrap';
import {SubmitHandler, useForm} from 'react-hook-form';
import {Button} from '../../Components/Buttons/ButtonBootstrap';
import {Link} from '../../Components/Links/LinkBootstrap';
import {
    forgotPasswordAuthAction,
    userAuthAction
} from '../../Services/actions/AuthenticationAction';
import {useHistory} from 'react-router-dom';
import {useAuthContext} from '../../Hooks/InternalHooks';
import {addModal} from '../../Shared/Dialog/Dialog';

export const AuthView = (): JSX.Element => {

    const history = useHistory();
    const dispatch = useDispatch();
    const {setAuthenticated} = useAuthContext();

    const {register, handleSubmit, setFocus, formState: {errors, isDirty, isValid}} = useForm({mode: 'onChange'});
    const [formLogin, setFormLogin] = useState<{ username: string, password: string, message: string }>({
        username: '',
        password: '',
        message: ''
    });


    const handleAuthentication: SubmitHandler<any> = (data: any) => {
        setFormLogin({...formLogin, message: ''});
        dispatch(userAuthAction(data.username, data.password, (state: boolean) => {
            setAuthenticated(state);
            if (state)
                history.push('/products');
            else {
                setFormLogin({...formLogin, message: 'User or password are incorrect'});
            }
        }));
    };

    const handleForgotPasswordAuthentication = () => {
        dispatch(forgotPasswordAuthAction(formLogin.username, (state: boolean) => {
            setAuthenticated(false);

            addModal('modal-auth', `${state? 'Please, check your inbox mail.': 'The user entered not exist.'}`, true);
            setFormLogin({...formLogin, message: ''});
        }));
    };

    const handleChangeFormField = (e: { target: any; }) => {
        const target = e.target;
        setFormLogin({...formLogin, [target.name]: target.value});
    };

    useEffect(() => {
        setFocus('username');
    }, [setFocus]);

    return (
        <div className="container-fluid odt-background-coin">

            <div id={'modal-auth'} className={'position-fixed odt-w-full'}/>

            <div className="row odt-max-height">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6"/>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <form className={'odt-form-login'} onSubmit={handleSubmit(handleAuthentication)}>
                        <div className="text-center">
                            <img src={'/customized/images/logo_coin_listo_v2.png'} alt={'coin-listo-v2'}
                                 className={'odt-img-form-login'} style={{width: '120px', paddingBottom: '20px'}}/>
                        </div>

                        <InputTextForm control={{...register('username', {required: true})}}
                                       errors={errors}
                                       mode={'primary'}
                                       label={'Username'}
                                       value={formLogin.username}
                                       placeholder={'Username'}
                                       onChange={handleChangeFormField}/>
                        <InputTextForm control={{...register('password', {required: true})}}
                                       errors={errors}
                                       mode={'primary'}
                                       label={'Password'}
                                       type={'password'}
                                       value={formLogin.password}
                                       placeholder={'Password'}
                                       onChange={handleChangeFormField}/>

                        <Link mode={'secondary'} text={'I forgot my password'} className={'text-end odt-font-p'}
                              onClick={() => handleForgotPasswordAuthentication()}/>

                        <Button mode={'primary'} text={'Login'} disabled={!isDirty || !isValid}/>
                        {formLogin.message && (
                            <div className="text-center">
                                <span
                                    className={'text-danger odt-text-danger odt-font-family-secondary odt-font-14'}>{formLogin.message}</span>
                            </div>
                        )}

                        <div className="text-center">
                            <a href="https://apps.apple.com/app/get-listo/id1563484915" target='_blank'><img src={'/customized/images/logo_download_appstore.svg'} alt={'appstore'}
                                 className={'odt-img-form-login'}
                                 style={{width: '70%', height: '70%', paddingTop: '25px'}}/></a>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};
