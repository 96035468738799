import {post} from '../../Utilities/HttpClientUtility';
import {getHttpHeaders} from './CommonsAPIs';
import moment from 'moment';

export const getBioFacilityTypeaheadSearch = (keyword: string = '') => {
    const body = {
        search: keyword
    };

    const headers = getHttpHeaders();

    return post(`/api/facility/typeahead_search`, body, headers);
};

export const getBioFacilityInfoService = (facilityId: string = '') => {
    const body = {
        facilityId: facilityId
    };

    const headers = getHttpHeaders();

    return post(`/api/facility/bioForFacility`, body, headers);
};

export const getExtraDataFacilityInfoService = (facilityId: string) => {
    const body = {
        facilityId: facilityId
    };

    const headers = getHttpHeaders();

    return post(`/api/facility/facilityData`, body, headers);
};


export const getTagsByBioFacilityInfoService = (facilityId: string = '') => {
    const body = {
        facilityUid: facilityId
    };

    const headers = getHttpHeaders();

    return post(`/api/facility/getFacilityTags`, body, headers);
};

export const addTagBioFacilityService = (facilityId: string = '', tag: string = '') => {
    const body = {
        facilityUid: facilityId,
        tag: tag
    };

    const headers = getHttpHeaders();

    return post(`/api/facility/addFacilityTag`, body, headers);
};

export const removeTagBioFacilityService = (facilityId: string = '', tag: string = '') => {
    const body = {
        facilityUid: facilityId,
        tag: tag
    };

    const headers = getHttpHeaders();

    return post(`/api/facility/removeFacilityTag`, body, headers);
};


export const getCryptoWalletByBioFacilityService = (facilityId: string = '') => {
    const body = {
        facilityUid: facilityId
    };

    const headers = getHttpHeaders();

    return post(`/api/facility/getFacilityCrypto`, body, headers);
};

export const addCryptoToWalletByBioFacilityService = (data = {facilityId: '', walletCode: '', walletType: ''}) => {
    const body = {
        facilityUid: data.facilityId,
        cryptoWallet: data.walletCode,
        cryptoType: data.walletType,
        updatedAt: moment().format('YYYY-MM-DD'),
    };

    const headers = getHttpHeaders();

    return post(`/api/facility/addFacilityCrypto`, body, headers);
};

export const removeCryptoToWalletByBioFacilityService = (data = {facilityId: '', accountUid: ''}) => {
    const body = {
        facilityUid: data.facilityId,
        accountUid: data?.accountUid
    };

    const headers = getHttpHeaders();

    return post(`/api/facility/removeFacilityCrypto`, body, headers);
};


export const updateBioFacilityInfo = (data = {id: '', bioHeadline: '', bioTextData: '', dbaName: ''}) => {
    const body = {
        facilityUid: data.id,
        bioHeadline: data.bioHeadline,
        bioTextData: data.bioTextData,
        dbaName: data.dbaName,
        enabled: true
    };

    const headers = getHttpHeaders();

    return post(`/api/facility/facilityBio`, body, headers);
};

export const uploadFilesBioFacilityInfoService = (data = {id: '', file: ''}) => {

    const formData = new FormData();
    formData.append('facilityId', data.id);
    formData.append('facilityImage', data.file);

    const headers = {
        ...getHttpHeaders(),
        'Content-Type': 'multipart/form-data'
    };

    return post(`/api/customer/upload_facility_image`, formData, headers);
};

export const updatePreferencesService = (preferences = {preferredTransporterId: '', invoices: ''}) => {
    const body = {
        preferredTransporterLicense: preferences.preferredTransporterId,
        invoicePoNotificationEmails: preferences.invoices
    };

    const headers = getHttpHeaders();

    return post(`/api/admin/updateSettings`, body, headers);
};

export const getBillingDetailsList = () => {
    const headers = {
        ...getHttpHeaders()
    };
    return post(`/api/customer/get_billing_methods`,null,headers);
};

export const getCityState = (body= {zipcode : ""}) => {
    const headers = getHttpHeaders();
    return post(`/api/customer/zipcode_lookup`, body, headers);
};

export const addNewBillingForm = (body= {  billingDetails: [
    {
      billingAddress: "",
      billingCity: "",
      billingState: "",
      phone: "",
      email: "",
      routingNumber: "",
      accountNumber: "",
      nameOnAccount: "",
      isPrimary: true,
      expirationMmyy: "",
      userUid: "",
      ccvn: "",
      bankAccountId: "", 
    },
  ]}) => {
    const headers = getHttpHeaders();
    return post(`/api/customer/add_billing_methods`, body, headers);
};


