import {Redirect, useParams} from 'react-router-dom';
import React from 'react';
import {useDispatch} from 'react-redux';
import {initializeIntegrationKeys} from '../../Services/actions/BuyerSellerAction';

export const TermProcessView = () => {
    const {listing, deal, token, device} = useParams<{ listing: string, deal: string, token: string, device: string, }>();
    const dispatch = useDispatch();
    if (listing && token && device) {
        dispatch(initializeIntegrationKeys(listing, deal, token, device));
        return (<Redirect to={'/deal/process'}/>);
    }
    return (<Redirect to={'/'}/>);
};
