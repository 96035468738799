import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk'
import {createLogger} from 'redux-logger'
import {rootReducers} from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loggerMiddleware = createLogger({
    // to collapse certain type of log action e.x., DONATE
    collapsed: (getState, action) => true,
    // to log time
    duration: true,
    timestamp: true,
});
export const store = createStore(
    rootReducers,
    composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware))
);
