import React, {useState} from 'react';
import {Button} from '../../../Components/Buttons/ButtonBootstrap';
import {CgPlayListSearch} from 'react-icons/all';
import {InputSearchV1} from '../../../Components/Inputs/InputBootstrap';
import {Collapse} from 'react-collapse';

export const FILTERS: { key: string, label: string, icon?: string, tags: { label: string, key: string, icon?: string }[] }[] = [
    {
        key: 'new',
        icon: 'WhatsNew.svg',
        label: 'Whats New', tags: []
    },
    {
        key: 'values',
        icon: 'Values.svg',
        label: 'Values',
        tags: [
            {label: 'Equity', key: 'equity', icon: 'Equity.svg'},
            {label: 'Women Owned', key: 'womenOwned', icon: 'WomenOwned.svg'},
            {label: 'Minority Owned', key: 'minorityOwned', icon: 'MinorityOwned.svg'},
            {label: '2nd Generation', key: 'secondGeneration', icon: 'SecondGeneration.svg'},
            {label: '3rd Generation', key: 'thirdGeneration', icon: 'ThirdGeneration.svg'},
            {label: 'Queer Owned', key: 'queerOwned', icon: 'QueerOwned.svg'}]
    },
    {
        key: 'methods',
        icon: 'Method.svg',
        label: 'Method',
        tags: [
            {label: 'Indoor', key: 'indoor', icon: 'Indoor.svg'},
            {label: 'Mixed Light', key: 'mixedLight', icon: 'MixedLight.svg'},
            {label: 'Sun Grown', key: 'sunGrown', icon: 'SunGrown.svg'}]
    },
    {
        key: 'attributes',
        icon: 'Attributes.svg',
        label: 'Attributes',
        tags: [
            {label: 'Earth Sun', key: 'earthSun', icon: 'EarthSun.svg'}
        ]
    },
    {
        key: 'category',
        icon: 'Categories.svg',
        label: 'Categories', tags: []
    },
    {
        key: 'brand',
        icon: 'Brands.svg',
        label: 'Brands', tags: []
    },
    {
        key: 'location',
        icon: 'Region.svg',
        label: 'Region', tags: []
    },
    {
        key: 'mine',
        icon: 'My-Listings.svg',
        label: 'My Listings', tags: []
    }
];

export const FIND_KEY_IN_FILTER = (key: string): { key: string, label: string, icon: string } | undefined => {
    let outcome: { key: string, label: string, icon: string } | undefined = undefined;
    FILTERS.forEach(f => {
        if (f.key === key)
            outcome = {key: f.key, label: f.label, icon: f.icon || ''};
        let find = f.tags.filter(f1 => f1.key === key);
        if (find && !!find.length)
            outcome = {key: find[0].key, label: find[0].label, icon: find[0].icon || ''};
    });
    return outcome;
};


export const Filters = ({
                            type = 'basic',
                            onSearchFilter
                        }: { type?: 'basic' | 'advance', onSearchFilter: (keyword: string, type?: string, tags?: string[], showLoading?: boolean) => void }) => {

    const [filterForm, setFilterForm] = useState<{
        keywords: string, filters: boolean, type: string, typeActive: string, tagActive: string, tags: { label: string, key: string, icon?: string }[]
    }>({
        keywords: '',
        filters: false,
        type: '',
        tags: [],
        typeActive: 'new',
        tagActive: ''
    });

    const handleSearch = (e: { target: any; }) => {
        const {value} = e.target;
        setFilterForm({...filterForm, keywords: value});

        if (filterForm.keywords) {
            const keys = filterForm.tags.map((it) => it.key);
            onSearchFilter(value, filterForm.type, keys, false);
        }
    };

    const FilterItem = ({
                            id,
                            label,
                            icon,
                            onClick
                        }: { id: string, label: string, icon?: string, onClick: () => void }) => {
        return (
            <div className={'odt-filter-item text-center'} onClick={onClick} style={{height: '120px', cursor: 'pointer'}}>
                {icon && (
                    <React.Fragment>
                        <div className={`d-flex justify-content-center`}>
                            <div
                                className={`odt-card-product-filter-item ${id === filterForm.typeActive ? 'odt-is-active' : ''}`}>
                                <img src={`/customized/images/icons/${icon}`}
                                     style={{width: '60%', height: '60%', marginTop: '20%'}}/>
                            </div>
                        </div>
                        <div className={'odt-font-family-secondary odt-font-14'}
                             style={{paddingTop: '10px'}}>{label}</div>
                    </React.Fragment>)}
            </div>);
    };

    const OptionItem = ({
                            id,
                            label,
                            icon,
                            onClick
                        }: { id: string, label: string, icon?: string, onClick: () => void }) => {
        return (
            <div className={`odt-card-product-filter-option-item ${id === filterForm.tagActive ? 'odt-is-active' : ''}`}
                 style={{width: '100%', cursor: 'pointer'}} onClick={onClick}>
                {icon && (
                    <div className={'text-start'}>
                        <img src={`/customized/images/icons/${icon}`} className={'odt-test'}
                             style={{width: '35px', float: 'left', paddingRight: '10px'}}/>
                    </div>)}
                <div className={'text-start odt-font-15'}>{label}</div>
            </div>);
    };

    return (
        <React.Fragment>


            <div className={'col col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'}>
                {type === 'advance' ? (
                    <Button mode={'primary'} text={'Filters'}
                            classes={'odt-card-product-button-filter odt-background-00'}
                            icon={<CgPlayListSearch/>}
                            onClick={() => setFilterForm({...filterForm, filters: !filterForm.filters})}/>
                ) : null}
            </div>

            <div className={'col col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'}/>

            <div className={'col col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'}>
                <InputSearchV1 classes={'odt-font-family-primary odt-font-16'} value={filterForm.keywords}
                               placeholder={'Enter your search request'}
                               onChange={handleSearch}/>
            </div>


            {type === 'advance' ? (
                <Collapse isOpened={filterForm.filters}>
                    <div className={'odt-card-product-canvas-filter-options'}>

                        <div className={'row odt-no-padding-left-right odt-font-family-secondary'}
                             style={{width: '100%', paddingBottom: '20px'}}>
                            {FILTERS.map((item, index) => (
                                <FilterItem key={index}
                                            id={item.key}
                                            label={item.label}
                                            icon={item.icon}
                                            onClick={() => {
                                                setFilterForm({
                                                    ...filterForm,
                                                    type: item.key,
                                                    tags: item.tags,
                                                    typeActive: item.key
                                                });
                                                if (!item.tags.length) {
                                                    const keys = item.tags.map((it) => it.key);
                                                    onSearchFilter(filterForm.keywords, item.key, keys, true);
                                                }
                                            }}/>
                            ))}
                        </div>

                        {(filterForm.tags && filterForm.tags.length !== 0) ? (
                            <div className={`odt-items-container-auto-scroll-horizontal odt-font-family-secondary`}>
                                <div className={'odt-items-auto-scroll-horizontal'} style={{height: '90px'}}>
                                    {filterForm.tags && filterForm.tags.map((item, index) => (
                                        <div key={index} className={'odt-item-auto-scroll-horizontal'}>
                                            <OptionItem id={item.key}
                                                        label={item.label} icon={item.icon} onClick={() => {
                                                setFilterForm({
                                                    ...filterForm,
                                                    tagActive: item.key
                                                });
                                                onSearchFilter(filterForm.keywords, filterForm.type, [item.key], true);
                                            }}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}


                    </div>
                </Collapse>
            ) : null}

        </React.Fragment>
    );
};
