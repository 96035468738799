import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '../../../Components/Buttons/ButtonBootstrap';
import { hideLoadingAction, showLoadingAction } from '../../../Services/actions/UtilityAction';
import { getMenuListFromExternalLink } from '../../../Services/apis/ProducstsAPIs';
import { ListingType, Product } from '../../Marketplace/components/Product';

const MenuView = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {facility} = useParams<{ facility: string }>();
    const search = useLocation().search;
    const session = new URLSearchParams(search).get('session') || '';

    const [productForm, setProductForm] = useState<{
        listings:ListingType[];
        menuName:string;
        count: number;
        total: number
    }>({
        listings:[],
        menuName:'',
        count:0,
        total:0
    });

    useEffect(() => {
        dispatch(showLoadingAction());
        getMenuListFromExternalLink(session, facility).then((response: {
                data: {
                    listings:any,
                    menuName: any;
                };
            }) => {
                setProductForm({
                    ...productForm,
                    listings:response?.data?.listings || [],
                    menuName:response?.data?.menuName || '',
                    count:response.data?.listings?.length || 0,
                    total:response.data?.listings?.length || 0,
                });
            }
        )
            .catch((response: any) => {
                console.error(response);
                history.push('/login');
            })
            .finally(() => dispatch(hideLoadingAction()));
    }, [dispatch]);

    const showProductsRender_ = (item: ListingType,count:number,total:number) => {
        return (
            <React.Fragment>
                <div className={'col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3'}>
                    <Product data={item} mode={'public'} show={true}/>
                </div>
                {count < total ? (
                    <div className={'row odt-no-padding'}>
                        <div className={'col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}/>
                        <div className={'col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                            <Button mode={'secondary'} text={'View More'} classes={'odt-background-01'}
                                    style={{marginTop: '20px'}}/>
                        </div>
                        <div className={'col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}/>
                    </div>
                ) : null}
            </React.Fragment>
        );
    };

  return (
    <>
        <div className='d-flex'>
            <div style={{background: '#F2F5F5'}} className={`col py-md-5 text-left-v1 odt-banner-right-content`} >
                <h1 className='odt-font-family-secondary-bold'>{productForm?.menuName || "Curated Custom Menu"}</h1>
                <img className="position-absolute top-0 end-0 odt-banner-left-decorator" alt={'coin-corner-right'} src={'/customized/images/logo-icon-corner-right.svg'}/>
            </div>
        </div>
        <div className={'container-fluid'}>
            <div className={'col-xl-12 col-md-12 col-sm-12 col-12'}>

                <div className={'row odt-card-product-canvas-items'}>
                    {productForm?.listings&& productForm?.listings?.map((item, index) => (
                        <React.Fragment key={index}>
                            {showProductsRender_(item, productForm?.count,productForm?.total)}
                        </React.Fragment>
                    ))}
                </div>

            </div>
        </div>
    </>
  )
}

export default MenuView