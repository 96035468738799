import {
    createMessageFeedbackService,
    downloadInvoicePurchaseOrderService,
    getBuyerSellerDataProcessService,
    getFacilityTypeaheadSearchService,
    saveConfirmationPaymentInfoService,
    saveConfirmationTransporterService
} from '../apis/BuyerSellerAPIs';
import {CLOSE_LOADING, OPEN_LOADING} from './UtilityAction';
import {SETTINGS_RENDER_ERROR} from './SettingsAction';
import {refreshAuthService} from '../apis/AuthenticationAPIs';
import {getBuyerSellerHttpHeaders} from '../apis/CommonsAPIs';
import environment from '../../Configuration/environment.config';

export const KEYS_PROCESS_BUYER_SELLER_INITIALIZE = 'INITIALIZE_PROCESS_KEYS';
export const KEYS_PROCESS_BUYER_SELLER_REVOKED = 'REVOKED_PROCESS_KEYS';
export const KEYS_PROCESS_BUYER_SELLER_REFRESH = 'REFRESH_PROCESS_KEYS';

export const INITIALIZE_DATA_BUYER_SELLER = 'LOAD_DATA_SELLER_BUYER';
export const INITIALIZE_DATA_BUYER_SELLER_ERROR = 'LOAD_DATA_BUYER_SELLER_ERROR';
export const INITIALIZE_EXTERNAL_DATA_BUYER_SELLER = 'LOAD_EXTRA_DATA_SELLER_BUYER';
export const INITIALIZE_EXTERNAL_DATA_BUYER_SELLER_ERROR = 'LOAD_EXTRA_DATA_SELLER_BUYER_ERROR';

export const initializeIntegrationKeys = (listingId, dealId ,authorization, deviceId) => {
    return (dispatch) => dispatch({
        type: KEYS_PROCESS_BUYER_SELLER_INITIALIZE,
        payload: {listing: listingId, deal: dealId, token: authorization, device: deviceId}
    });
};

export const revokedIntegrationKeys = () => {
    return (dispatch) => dispatch({type: KEYS_PROCESS_BUYER_SELLER_REVOKED, payload: {}});
};

export const refreshIntegrationKeys = () => {
    return (dispatch) => dispatch({type: KEYS_PROCESS_BUYER_SELLER_REFRESH, payload: {}});
};

export const initializeDataBuySellerProcessAction = (keys = {
    listing: '', deal: '', token: '', device: ''
}, callback) => {
    return (dispatch) => {
        dispatch({type: OPEN_LOADING, payload: {}});
        getBuyerSellerDataProcessService(keys)
            .then(response => {
                dispatch({type: INITIALIZE_DATA_BUYER_SELLER, payload: response.data});
                callback(true);
            })
            .catch(error => {
                console.error(error);
                dispatch({type: INITIALIZE_DATA_BUYER_SELLER_ERROR, payload: {}});
                dispatch({type: KEYS_PROCESS_BUYER_SELLER_REVOKED, payload: {}});
                callback(false);
            }).finally(() => {
            dispatch({type: CLOSE_LOADING, payload: {}});
        });
    };
};

export const initializeExternalDataBuySellerProcessAction = (keys = {listing: '', deal: '', token: '', device: ''}) => {
    return (dispatch) => {
        refreshAuthService(getBuyerSellerHttpHeaders())
            .then(response => {
                dispatch({type: INITIALIZE_EXTERNAL_DATA_BUYER_SELLER, payload: response.data});
            })
            .catch((error => {
                console.error(error);
                dispatch({type: INITIALIZE_EXTERNAL_DATA_BUYER_SELLER_ERROR, payload: {}});
            }));
    }
}

const getFacilityTypeaheadSearchAction = (keyword, keys = {id: '', token: '', device: ''}) => {
    return getFacilityTypeaheadSearchService(keyword, keys);
};

export const saveConfirmationTransporterAction = (transporterLicense, haveTransporterLicense, keys = {
    id: '', token: '', device: ''
}) => {
    return (dispatch) => {
        dispatch({type: OPEN_LOADING, payload: {}});
        saveConfirmationTransporterService(transporterLicense, haveTransporterLicense, keys).then(response => {
            getBuyerSellerDataProcessService(keys).then(resp => {
                dispatch({type: INITIALIZE_DATA_BUYER_SELLER, payload: resp.data});
                dispatch({type: CLOSE_LOADING, payload: {}});
            }).catch(error => {
                console.error(error);
                dispatch({type: INITIALIZE_DATA_BUYER_SELLER_ERROR, payload: {}});
                dispatch({type: KEYS_PROCESS_BUYER_SELLER_REVOKED, payload: {}});
                dispatch({type: CLOSE_LOADING, payload: {}});
            });
        }).catch(error => {
            console.error(error);
            dispatch({type: CLOSE_LOADING, payload: {}});
        });
    };
};

export const saveConfirmationPaymentInfoAction = (accountNumber, routingNumber, nameOnAccount, billingAddress, billingCity, billingState, phone, email, keys = {
    id: '', token: '',device: ''
}) => {
    return (dispatch) => {
        dispatch({type: OPEN_LOADING, payload: {}});
        saveConfirmationPaymentInfoService(accountNumber, routingNumber, nameOnAccount, billingAddress, billingCity, billingState, phone, email, keys).then(response => {
            getBuyerSellerDataProcessService(keys).then(resp => {
                dispatch({type: INITIALIZE_DATA_BUYER_SELLER, payload: resp.data});
                dispatch({type: CLOSE_LOADING, payload: {}});
            }).catch(error => {
                console.error(error);
                dispatch({type: INITIALIZE_DATA_BUYER_SELLER_ERROR, payload: {}});
                dispatch({type: KEYS_PROCESS_BUYER_SELLER_REVOKED, payload: {}});
                dispatch({type: CLOSE_LOADING, payload: {}});
            });
        }).catch(error => {
            console.error(error);
            dispatch({type: CLOSE_LOADING, payload: {}});
        });
    };
};

export const downloadInvoiceMessageAction = (messageUid = '', orderId = '', callback) => {
    return (dispatch) => {
        dispatch({type: OPEN_LOADING, payload: {}});
        downloadInvoicePurchaseOrderService(messageUid)
            .then((response) => new Blob([response.data], {type: 'application/pdf'}))
            .then((blob) => {

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice-${orderId}.pdf`);

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

                callback('success');
                dispatch({type: CLOSE_LOADING, payload: {}});
            }).catch(error => {
            console.error(error);
            callback('failed');
            dispatch({type: CLOSE_LOADING, payload: {}});
        });
    };
};

export const createMessageFeedbackAction = (dealUid, dealRating, dealReview, callback) => {
    return (dispatch) => {
        dispatch({type: OPEN_LOADING, payload: {}});
        createMessageFeedbackService(dealUid, dealRating, dealReview).then(response => {
            callback(true);
        }).catch(response => {
            callback(false);
            console.error(response);
            dispatch({type: SETTINGS_RENDER_ERROR, payload: {}});
        }).finally(() => {
            dispatch({type: CLOSE_LOADING, payload: {}});
        });
    };
};

export const handleLaunchAppleStoreAction = () => {
    setTimeout(function () {
        const url = environment().ITUNES_ROOT_PATH;
        const tab = window.open(url, '_blank');
        tab && tab.focus();
    }, 25);
}
