import { post } from "../../Utilities/HttpClientUtility";
import { getHttpHeaders } from "./CommonsAPIs";

export const getValidateBulkUploadResponse = (body: any) => {
  const headers = getHttpHeaders();
  return post(`/api/customer/validate_bulk_upload`, body, headers);
};
export const getConfirmBulkUploadResponse = (body: any) => {
  const headers = getHttpHeaders();
  return post(`/api/customer/confirm_bulk_upload`, body, headers);
};