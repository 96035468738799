import React, {useEffect, useState} from 'react';
import {searchListingsDetailsByProductService} from '../../../../Services/apis/ProducstsAPIs';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {FacilityType, Page} from '../../../../Shared/Page/Page';
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/all';
import {formatDefault} from '../../../../Utilities/NumberUtility';
import {Tab, Tabs} from '../../../../Shared/Tabs/Tabs';
import {Button} from '../../../../Components/Buttons/ButtonBootstrap';
import {SingleBarChart, SingleMiniBarChart} from '../../../../Shared/Chart/SingleBarChart';
import {PieChart, PieDataChartType} from '../../../../Shared/Chart/PieChart';
import parse from 'html-react-parser';
import {ImageDecorator} from 'react-viewer/lib/ViewerProps';
import {useQueryParamsHook} from '../../../../Hooks/InternalHooks';
import Viewer from 'react-viewer';
import {Collapse} from 'react-collapse';
import {optimize} from '../../../../Utilities/ImageUtility';
import {COLORS} from '../../../../Shared/Chart/ChartUtility';
import {hideLoadingAction, showLoadingAction} from '../../../../Services/actions/UtilityAction';
import {handleLaunchAppleStoreAction} from '../../../../Services/actions/BuyerSellerAction';


export const DetailPublicView = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const {listing, session} = useParams<{ listing: string, session: string }>();
    const queries = useQueryParamsHook({session: `${session}`});

    const [viewerForm, setViewerForm] = React.useState<{ visible: boolean, position: number }>({
        visible: false,
        position: 0
    });

    const [detailForm, setDetailForm] = useState<{
        facility: FacilityType | undefined,
        name: string,
        productName: string,
        label: string,
        packagedDate: string,
        availableQuantity: number,
        quantityType: string,
        images: { url: string, mediaType: string, mediaOrder: number }[],
        description: string,
        options: {
            collapse: boolean
        },
        showTestResult: boolean
    }>({
        facility: undefined,
        name: '',
        productName: '',
        label: '',
        packagedDate: '',
        availableQuantity: 0,
        quantityType: '',
        images: [],
        description: '',
        options: {
            collapse: false
        },
        showTestResult: false
    });

    const [charts, setCharts] = React.useState<{
        bars: { label: string, value: number, fill: string }[],
        pie: {
            data: { label: string, shortLabel: string, value: number, fill: string }[]
        }
    }>({
        bars: [],
        pie: {data: []}
    });

    useEffect(() => {
        dispatch(showLoadingAction());
        searchListingsDetailsByProductService(queries.session, listing).then((response: {
                data: {
                    facility: { logoUrl: string, name: string, facilityDba: string, licenseNumber: string, bioBody: string, bioHeadline: string, images: { url: string }[], tags: string[] },
                    listing: {
                        name: string,
                        productName: string,
                        label: string,
                        packagedDate: string,
                        availableQuantity: number,
                        quantityType: string,
                        images: { url: string, mediaType: string, mediaOrder: number }[]
                        videos: { url: string }[],
                        description: string,
                        testResults: { resultType: string, TestTypeName: string, resultUnit: string, TestResultLevel: number, shortName?: string } [],
                    }
                }
            }) => {

                const images = response.data?.listing && response.data?.listing?.images?.map((image) => {
                    return {...image, url: optimize(image.url, 768)};
                });

                setDetailForm({
                    ...detailForm,
                    facility: {
                        logo: response.data?.facility?.logoUrl,
                        image: !!response.data?.facility?.images?.length ? response.data?.facility?.images[0].url : '',
                        name: response.data?.facility?.name,
                        facilityDba: response.data?.facility?.facilityDba,
                        licenseNumber: response.data?.facility?.licenseNumber,
                        bioBody: response.data?.facility?.bioBody,
                        bioHeadline: response.data?.facility?.bioHeadline,
                        tags: response.data?.facility?.tags,
                    },
                    productName: response.data?.listing?.productName,
                    name: response.data?.listing?.name,
                    label: response.data?.listing?.label,
                    packagedDate: response.data?.listing?.packagedDate,
                    availableQuantity: response.data?.listing?.availableQuantity,
                    quantityType: response.data?.listing?.quantityType,
                    description: response.data?.listing?.description,
                    images: images,
                    showTestResult: !!response?.data?.listing?.testResults,
                });

                if(response?.data?.listing?.testResults) {
                    const bars = response?.data?.listing?.testResults?.filter(f => f.resultType === '%').map((item,it) => {
                        return {label: item.TestTypeName, value: item.TestResultLevel, fill: COLORS[it]};
                    });
                    const pie = response?.data?.listing?.testResults?.filter(f => f.resultType !== '%').map((item, it) => {
                        return {
                            label: `${item.TestTypeName} <span class="odt-font-family-secondary-bold">${item.TestResultLevel} ${item.resultUnit}</span>`,
                            shortLabel: `${item.TestTypeName}`,
                            value: item.TestResultLevel,
                            fill: COLORS[it]
                        };
                    });
                    setCharts({ bars: bars , pie: { data: pie}});
                }
            }
        ).catch((response: any) => {
            console.error(response);
            history.push('/login');
        }).finally(() => dispatch(hideLoadingAction()));
    }, [dispatch]);

    const viewerImageRender_ = (images: { url: string; normally?: boolean }[]) => {
        const outcome: ImageDecorator[] = [];
        let index = 0;
        images.forEach(image => {
            outcome.push({
                src: image.url,
                alt: `product-image-${++index}`
            });
        });
        return outcome;
    };

    const optionsButtonRender_ = () => {
        return (
            <div className={'odt-panel-button'}>
                <Button classes={'odt-font-family-primary-bold'} mode={'primary'}
                        text={'request a sample'} onClick={() => handleLaunchAppleStoreAction()}/>

                <Button classes={'odt-font-family-primary-bold'} mode={'primary'} text={'make an offer'}
                        style={{background: '#000000'}}/>
            </div>
        );
    };

    return (
        <Page mode={'public'} data={detailForm.facility}>
            <div className={'container-fluid'}>
                <div className={'row'} style={{height: '100%', background: '#FFFFFF'}}>
                    <div className={'col-xl-12 col-md-12 col-sm-12 col-12 odt-padding-left-right'} >

                        <div className={'text-left-v1 odt-padding-bottom-15'}>
                            <h2 className={'odt-font-family-primary-bold'}>{detailForm?.name}</h2>
                            <p className={'odt-p odt-font-family-secondary'}>
                                {detailForm?.facility?.licenseNumber ? (
                                    <span style={{cursor: 'pointer'}} onClick={() => setDetailForm({
                                        ...detailForm, options: {collapse: !detailForm.options.collapse}
                                    })}>
                                        {detailForm?.facility?.licenseNumber}
                                        <i style={{
                                            fontSize: '25px',
                                            paddingLeft: '5px'
                                        }}> {detailForm.options.collapse ? <MdKeyboardArrowUp/> :
                                            <MdKeyboardArrowDown/>}</i>
                                    </span>) : null}
                                <Collapse isOpened={detailForm.options.collapse}>
                                    <span>{detailForm?.productName}</span>
                                    <span>{detailForm?.packagedDate}</span>
                                    <span>{detailForm?.label}</span>
                                    <span>{`${formatDefault(detailForm?.availableQuantity)} ${detailForm?.quantityType}`}</span>
                                </Collapse>
                            </p>
                        </div>

                        <Tabs theme={'light'} headers={[{
                            title: 'Listing',
                            action: () => {
                            },
                            enable: true
                        }, {
                            title: 'Test Results',
                            action: () => {
                            },
                            enable: detailForm.showTestResult
                        }]}>
                            <Tab>

                                <div id={'multimedia-area'} className={'row col-xl-12 col-md-12 col-sm-12 col-12 odt-banner-images'}>

                                    <div
                                        className={`col-xl-${detailForm?.images?.length === 1 ? '12' : '7'} col-md-${detailForm?.images?.length === 1 ? '12' : '7'} col-sm-${detailForm?.images?.length === 1 ? '12' : '7'} col-${detailForm?.images?.length === 1 ? '12' : '7'} odt-no-padding h-100 h-100`}
                                        ref={(el) => {
                                            if (el) el.style.setProperty('padding-right', `${detailForm.images.length !== 1 ? '4' : '0'}px`, 'important');
                                        }}>
                                        {detailForm?.images && !!detailForm?.images?.length ? (
                                            <img onClick={() => setViewerForm({visible: true, position: 0})}
                                                 src={detailForm.images[0].url} alt={`product-primary-image`}
                                                 style={{
                                                     width: '100%',
                                                     height: '100%',
                                                     objectFit: 'cover',
                                                     cursor: 'pointer',
                                                     borderRadius: (detailForm.images.length === 1 ? '50px' : '50px 0 0 50px'),
                                                 }}/>
                                        ) : null}
                                    </div>

                                    {detailForm?.images?.length > 1 ? (
                                        <div className={'col-xl-5 col-md-5 col-sm-5 col-5 odt-no-padding'}
                                             style={{height: '100%'}}>
                                            {detailForm.images.map((image, index) => {
                                                const height = 100 / (detailForm.images.length - 1);
                                                return (index >= 1) ? (
                                                    <div key={index} className={'d-block'}
                                                         style={{height: `${height}%`, width: '100%'}}
                                                         ref={(el) => {
                                                             if (el) el.style.setProperty('padding-top', `${index !== 1 ? '3' : '0'}px`, 'important');
                                                         }}>
                                                        <img onClick={() => setViewerForm({
                                                            visible: true,
                                                            position: index
                                                        })}
                                                             src={image.url} alt={`product-${index}-image`}
                                                             style={{
                                                                 width: '100%',
                                                                 height: '100%',
                                                                 objectFit: 'cover',
                                                                 cursor: 'pointer',
                                                                 borderRadius: `0 ${index === 1 ? '50px' : '0'} ${index === detailForm.images.length - 1 ? '50px' : '0'} 0`,
                                                             }}/>
                                                    </div>
                                                ) : null;
                                            })}
                                        </div>
                                    ) : null}


                                    <Viewer
                                        visible={viewerForm.visible}
                                        onClose={() => setViewerForm({visible: false, position: 0})}
                                        images={viewerImageRender_(detailForm.images)}
                                        activeIndex={viewerForm.position}
                                        noNavbar={false}
                                        noImgDetails={true}
                                        noFooter={false}
                                        onMaskClick={() => setViewerForm({visible: false, position: 0})}/>

                                </div>

                                <p className={'odt-p'}>
                                    <span>{detailForm?.description}</span>
                                </p>

                                {optionsButtonRender_()}

                            </Tab>
                            <Tab>

                                <h2 className={'odt-font-family-primary-bold odt-padding-top-30 odt-padding-bottom-10'}>{'Phyto Facts'}</h2>

                                <div id={'bars-chars-area'}>
                                    <h3 className={'odt-font-family-primary odt-padding-bottom-20'}>{'General'}</h3>
                                    {charts && charts.bars.map((chart, index) => (
                                        <div key={index} className={'odt-padding-bottom-15'} style={{width: '96%'}}>
                                            <SingleBarChart label={chart.label} numerator={chart.value}
                                                            fill={chart.fill}/>
                                        </div>
                                    ))}
                                </div>

                                <div id={'pie-chars-area'}>
                                    <PieChart data={charts.pie.data}
                                              className={'odt-padding-top-30 odt-padding-bottom-30'}
                                              summary={(data: PieDataChartType[]) => {
                                                  return (
                                                      <div className={'text-left-v1'}>
                                                          <h2 className={'odt-font-family-primary-bold'}>{'Cannabinoids'}</h2>
                                                          {data && data.map((item, index) => (
                                                              <div key={index}
                                                                   className={'odt-font-family-secondary odt-font-22'}>
                                                                  <div className={'d-inline-block'}
                                                                       style={{paddingRight: '15px'}}>{parse(`${item.label}`)}</div>
                                                                  <SingleMiniBarChart width={100}
                                                                                      fill={item.fill}/>
                                                              </div>
                                                          ))}
                                                      </div>
                                                  );
                                              }}/>
                                </div>

                                {optionsButtonRender_()}

                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </Page>
    );
};
