import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Avatar} from '../../Profile/Avatar';
import {useAuthContext} from '../../../Hooks/InternalHooks';
import {useDispatch} from 'react-redux';
import {invalidateAuthenticationAction} from '../../../Services/actions/AuthenticationAction';

export const SidebarMenu = ({
                                children,
                                expanded
                            }: { expanded?: boolean, actions?: { name: string }[], children: JSX.Element }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const {setAuthenticated} = useAuthContext();

    const [navigationForm] = useState<{
        tree: { name: string, path: string, icon: string, action?: () => void }[]
    }>({
        tree: [
            {name: 'Marketplace', path: '/products', icon: 'store-solid.svg'},
            {name: 'Deals', path: '/deals', icon: 'box.svg'},
            {name: 'Donations', path: '/donations', icon: 'donation.svg'},
            {name: 'Settings', path: '/settings', icon: 'users-config-regular.svg'},
            {name: 'Log out', path: '/', icon: 'logout.svg', action: () => {
                    setAuthenticated(false);
                    dispatch(invalidateAuthenticationAction());
                }
            }
        ]
    });

    const handleRedirectTo = (path: string) => {
        history.push(path);
    };

    return (
        <React.Fragment>
            <main className={'odt-main'}>
                <div className={expanded ? 'odt-sidebar odt-font-family-primary odt-font-ss' : 'odt-sidebar-hidden'}>

                    <nav style={{backgroundColor: '#183E43', height: '100%'}}>

                        <div className={'odt-font-24'} style={{width: '100%', marginTop: '40px'}}>

                            <Avatar className={'text-center'} size={80} title={'bottom'} text={'Welcome'} onClick={() => {}}/>

                            <div className={'odt-font-22'} style={{width: '100%', paddingRight: '25px'}}>
                                <ul className={'odt-navbar-list'}>
                                    {navigationForm.tree.map((element, index) => (
                                        <li key={index} onClick={() => {
                                            element.action && element.action();
                                            handleRedirectTo(element.path);
                                        }}>
                                            <Link to={element.path}>
                                                {element.name} <img alt={`img-${index}-sidebar`}
                                                                    src={`/customized/images/icons/navbar/${element.icon}`}
                                                                    width={24}
                                                                    style={{marginLeft: '0.01em'}}/>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </nav>
                </div>

                <section>
                    {children}
                </section>
            </main>
        </React.Fragment>
    );
};
