import {
    AUTHENTICATION_ERROR,
    AUTHENTICATION_SUCCESS,
    INVALIDATE_AUTHENTICATION,
    REFRESH_AUTHENTICATION_FAILED,
    REFRESH_AUTHENTICATION_SUCCESS
} from "../actions/AuthenticationAction";
import {getHttpHeaderAuthorization, getHttpHeaderXDeviceId} from "../apis/CommonsAPIs";

export const initialize = {
    authorized: false,
    redirect: false,
    user: {
        token: '',
        admin: false,
        username: '',
        fullname: '',
        supportDonation: '',
        TermsAndConditions: {},
        PrivacyPolicy: {},
        facilities: [],
    }
}

export const authReducer = (state = initialize, action) => {
    const TAG_TOKEN_API = 'access';
    switch (action.type) {
        case AUTHENTICATION_SUCCESS:
            const token = action.payload.bearer_token;
            const device = action.payload.device;
            localStorage.setItem(TAG_TOKEN_API, JSON.stringify({
                token: token, device: device
            }));
            return {
                ...state,
                authorized: true,
                user: {
                    admin: action.payload.admin,
                    username: action.payload.username,
                    fullname: action.payload.fullname,
                    supportDonation: action.payload.supportDonation,
                    facilities: action.payload.activeFacilities,
                    token: token,
                    device: device
                }
            };

        case AUTHENTICATION_ERROR:
            localStorage.setItem(TAG_TOKEN_API, JSON.stringify({}));
            return {
                ...state,
                authorized: false,
                user: {}
            };

        case REFRESH_AUTHENTICATION_SUCCESS:
            return {
                ...state,
                authorized: true,
                user: {
                    admin: action.payload.admin,
                    username: action.payload.username,
                    fullname: action.payload.fullname,
                    supportDonation: action.payload.supportDonation,
                    facilities: action.payload.activeFacilities,
                    token: getHttpHeaderAuthorization(),
                    device: getHttpHeaderXDeviceId()
                }
            };

        case REFRESH_AUTHENTICATION_FAILED:
            localStorage.setItem(TAG_TOKEN_API, JSON.stringify({}));
            return {
                ...state,
                authorized: false,
                user: {}
            };

        case INVALIDATE_AUTHENTICATION:
            localStorage.setItem(TAG_TOKEN_API, JSON.stringify({}));
            return {
                ...state,
                authorized: false,
                user: {}
            };

        default:
            return state;
    }
}
