import React, {useState} from 'react';
import {Page} from '../../Shared/Page/Page';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AuthenticationData} from '../../Services/reducers/states/AuthenticationData';
import {MdKeyboardArrowRight} from 'react-icons/all';

export const SettingView = () => {

    const history = useHistory();
    const store = useSelector((state: AuthenticationData) => state.auth.user);

    const [settingsForm] = useState<{
      options: { name: string; path: string }[];
    }>({
      options: [
        { name: "Preferences", path: "/settings/preference" },
        { name: "Billing Details", path: "/settings/billing-details" },
        { name: "Contact support", path: "/settings/support" },
      ],
    });

    const handleRedirect = (path: string) => history.push(path);

    return (
        <Page mode={'private'}>
            <div className={'container-fluid'}>
                <div className={'row odt-card-product-canvas-items'} style={{paddingTop: '10px'}}>
                    <h3 className={'odt-font-family-primary-bold'}>Settings</h3>

                    {store && store.facilities && !!store.facilities.length && (
                        <div style={{paddingTop: '30px'}}>
                            <h4 className={'odt-font-family-primary-bold'} style={{paddingBottom: '20px'}}>Your Facilities</h4>
                            {store.facilities.map((element, index) => (
                                <div key={index} style={{marginBottom: '10px', cursor: 'pointer'}} onClick={() => handleRedirect(`/settings/facility/${element.id}`)}>

                                    <div className="d-flex justify-content-between">
                                        <span className={'odt-font-family-secondary odt-font-18'} style={{paddingLeft: 'inherit', marginTop: '18px'}}>{element.name}</span>
                                        <div className={'odt-font-family-secondary-bold odt-fontv2'} >
                                            <span className={'badge badge-pill badge-primary odt-font-p '} >{element.licenseNumber}</span>
                                            <i className={'text-end'} style={{fontSize: '30px',paddingRight: '2px', paddingLeft: '2px'}}><MdKeyboardArrowRight/></i>
                                        </div>
                                    </div>
                                    <div className={'odt-card-product-separator'}/>
                                </div>
                            ))}
                        </div>
                    )}

                    {settingsForm.options && (
                        <div style={{paddingTop: '25px'}}>
                            <h4 className={'odt-font-family-primary-bold'} style={{paddingBottom: '20px'}}>Your App Info</h4>
                            {settingsForm.options.map((element, index) => (
                                <div key={index} style={{marginBottom: '10px', cursor: 'pointer'}} onClick={() => handleRedirect(element.path)}>
                                    <div className="d-flex justify-content-between">
                                        <span className={'odt-font-family-secondary odt-font-18'} style={{paddingLeft: 'inherit', marginTop: '18px'}}>{element.name}</span>
                                        <i className={'text-end'} style={{fontSize: '30px', paddingRight: '2px'}}><MdKeyboardArrowRight/></i>
                                    </div>
                                    <div className={'odt-card-product-separator'}/>
                                </div>
                            ))}
                        </div>
                    )}

                </div>
            </div>
        </Page>);
};
