import {post} from '../../Utilities/HttpClientUtility';
import {getHttpHeaders} from './CommonsAPIs';

export const getBuyerSellerDataProcessService = (keys: { listing: string, deal: string, token: string, device: string }) => {
    const body: { listingId: string, dealUid?: string } = {
        listingId: keys.listing
    };

    if (keys.deal) body['dealUid'] = keys.deal;

    const headers = {
        'Authorization': `Bearer ${keys.token}`,
        'X-device-id': keys.device
    };
    return post(`/api/customer/dealForListing`, body, headers);
};


export const getFacilityTypeaheadSearchService = (keyword: string, keys = {id: '', token: '', device: ''}) => {
    const body = {
        search: keyword
    };
    const headers = {
        'Authorization': `Bearer ${keys.token}`,
        'X-device-id': keys.device
    };
    return post(`/api/facility/typeahead_search`, body, headers);
};


export const getDataBankInformationByUserService = (keys = {id: '', token: '', device: ''}) => {
    const body = {
        listingId: keys.id
    };
    const headers = {
        'Authorization': `Bearer ${keys.token}`,
        'X-device-id': keys.device
    };
    return post(`/api/admin/settings`, body, headers);
};


export const saveConfirmationTransporterService = (transporterLicense: string, haveTransporterLicense = false,
                                                   keys = {id: '', token: '', device: ''}) => {
    const body = {
        listingId: keys.id,
        selectedTransporterLicense: haveTransporterLicense ? '' : transporterLicense,
        haveTransporterLicense: haveTransporterLicense
    };
    const headers = {
        'Authorization': `Bearer ${keys.token}`,
        'X-device-id': keys.device
    };
    return post(`/api/customer/confirmTransporter`, body, headers);
};

export const saveConfirmationPaymentInfoService = (accountNumber: string, routingNumber: string, nameOnAccount: string, billingAddress: string, billingCity: string, billingState: string, phone: string, email: string,
                                                   keys = {id: '', token: '', device: ''}) => {
    const body = {
        listingId: keys.id,
        bankDetails: {
            accountNumber: accountNumber,
            routingNumber: routingNumber,
            nameOnAccount: nameOnAccount,
            billingAddress: billingAddress,
            billingCity: billingCity,
            billingState: billingState,
            phone: phone,
            email: email
        }
    };
    const headers = {
        'Authorization': `Bearer ${keys.token}`,
        'X-device-id': keys.device
    };
    return post(`/api/customer/confirmPaymentInfo`, body, headers);
};

export const downloadInvoicePurchaseOrderService = (messageUid: string) => {

    const body = {'messageUid': `${messageUid}`};

    const headers = getHttpHeaders();

    return post(`/api/customer/purchase_order_for_offer`, body, headers, 'blob');
};


export const createMessageContactSupportService = (comments: string, listingUid: string, facilityUid: string, requestType: string) => {

    const body: {
        message: string,
        facilityUid: string,
        requestType: string,
        listingUid?: string
    } = {
        'message': `${comments}`,
        'facilityUid': `${facilityUid}`,
        'requestType': `${requestType}`,
    };

    if (listingUid) body.listingUid = `${listingUid}`;

    const headers = getHttpHeaders();

    return post(`/api/admin/supportRequest`, body, headers);
};


export const createMessageFeedbackService = (dealUid: string, dealRating: number, dealReview: string) => {

    const body: {
        dealUid: string,
        dealRating: number,
        dealReview: string,
    } = {
        'dealUid': `${dealUid}`,
        'dealRating': dealRating,
        'dealReview': `${dealReview}`,
    };

    const headers = getHttpHeaders();

    return post(`/api/customer/deal_feedback`, body, headers);
};

export const getCustomerDonationDetails  = (keys: { listing: string, deal: string, token: string, device: string }) => {
    const body: { listingId: string, dealUid?: string } = {
        listingId: keys.listing,
        dealUid:keys.deal
    };
    const headers = {
        'Authorization': `Bearer ${keys.token}`,
        'X-device-id': keys.device
    };
    return post(`/api/customer/donationDetails`, body, headers);
};