import React, {useEffect, useState} from 'react';
import {useAuthContext} from '../../Hooks/InternalHooks';
import {Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {refreshAuthAction} from '../../Services/actions/AuthenticationAction';

export const PrivateNavigation = ({children}: { children: React.FC }) => {
    const dispatch = useDispatch();
    const {authenticated, setAuthenticated} = useAuthContext();
    const [navigation, setNavigation] = useState<{ processing: boolean }>({processing: authenticated});

    useEffect(() => {
        if (!authenticated) {
            setNavigation({processing: false});
            dispatch(refreshAuthAction((isAuth: boolean) => {
                setAuthenticated(isAuth);
                setNavigation({processing: true});
            }));
        }
    }, [authenticated]);

    const View = children;

    if (navigation.processing) {
        return authenticated ? (
            <React.Fragment>
                <View/>
            </React.Fragment>) : <Redirect to={'/login'}/>;
    }
    return (<div/>);
};
