import {post} from '../../Utilities/HttpClientUtility';
import {getHttpHeaders} from './CommonsAPIs';

export const getAllDealsPendingService = () => {
    const body = {
        lastItem: 0,
        perPage: 15,
        filter: {
            type: 'pending'
        }
    };

    const headers = getHttpHeaders();

    return post(`/api/customer/deals`, body, headers);
};

export const getAllDealsCompletedService = () => {
    const body = {
        lastItem: 0,
        perPage: 15,
        filter: {
            type: 'completed'
        }
    };

    const headers = getHttpHeaders();

    return post(`/api/customer/deals`, body, headers);
};
