import {CLOSE_LOADING, OPEN_LOADING} from './UtilityAction';
import {forgotPasswordAuthentication, refreshAuthService, userAuthService} from '../apis/AuthenticationAPIs';
import uuid from 'react-uuid'
import {getHttpHeaders} from '../apis/CommonsAPIs';

export const AUTHENTICATION_SUCCESS = 'LOAD_AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_ERROR = 'LOAD_AUTHENTICATION_ERROR';
export const REFRESH_AUTHENTICATION_SUCCESS = 'LOAD_REFRESH_AUTHENTICATION_SUCCESS';
export const REFRESH_AUTHENTICATION_FAILED = 'LOAD_REFRESH_AUTHENTICATION_FAILED';
export const INVALIDATE_AUTHENTICATION = 'INVALIDATE_AUTHENTICATION';


export const userAuthAction = (username, password, callback) => {
    return (dispatch) => {
        dispatch({type: OPEN_LOADING, payload: {}});
        const key = `device-${uuid()}`;
        userAuthService(username, password, key).then(response => {
            const data = {
                ...response.data,
                device: key
            };
            dispatch({type: AUTHENTICATION_SUCCESS, payload: data});
            callback(true);
        }).catch(response => {
            console.error(response);
            dispatch({type: AUTHENTICATION_ERROR, payload: {}});
            callback(false);
        }).finally(() => {
            dispatch({type: CLOSE_LOADING, payload: {}});
        });
    };
};

export const forgotPasswordAuthAction = (username, callback) => {
    return (dispatch) => {
        dispatch({type: OPEN_LOADING, payload: {}});
        forgotPasswordAuthentication(username).then(response => {
            callback(true);
        }).catch(response => {
            console.error(response);
            callback(false);
        }).finally(() => {
            dispatch({type: CLOSE_LOADING, payload: {}});
        });
    };
};

export const refreshAuthAction = (callback) => {
    return (dispatch) => {
        const headers = getHttpHeaders();
        if (headers['X-device-id'] === 'undefined') {
            callback(false);
        } else {
            refreshAuthService().then(response => {
                const data = {
                    ...response.data,
                };
                dispatch({type: REFRESH_AUTHENTICATION_SUCCESS, payload: data});
                callback(true);
            }).catch(response => {
                dispatch({type: REFRESH_AUTHENTICATION_FAILED});
                callback(false);
            })
        }
    };
};

export const invalidateAuthenticationAction = () => {
    return (dispatch) => {
        dispatch({type: INVALIDATE_AUTHENTICATION});
    };
};
