import {get, post} from '../../Utilities/HttpClientUtility';
import {getHttpHeaders} from './CommonsAPIs';

export const searchListingService = (keyword: string = '', type: string = 'new', tags?: string[] | undefined, company?: string | undefined) => {
    const body: {
        lastItem: number,
        perPage: number,
        filter: {
            search: string,
            type: string,
            tags: string[] | undefined
            group?: string
        }
    } = {
        lastItem: 0,
        perPage: 15,
        filter: {
            search: keyword,
            type: type || 'new',
            tags: tags
        }
    };

    if (company !== undefined) body.filter.group = company;

    const headers = getHttpHeaders();

    return post(`/api/customer/listings`, body, headers);
};

export const getListingsDetailService = (id: string = '') => {
    const body = {
        listingId: id
    };

    const headers = getHttpHeaders();

    return post(`/api/customer/listing_for_id`, body, headers);
};

export const searchListingsPublicService = (session: string = '', facility: string = '') => {
    return get(`/view/facility/${session}/${facility}`);
};

export const getMenuListFromExternalLink = (session: string = '', facility: string = '') => {
    return get(`/view/custom/${session}/${facility}`);
};

export const searchListingsDetailsByProductService = (session: string = '', listing: string = '') => {
    return get(`/view/listing/${session}/${listing}`);
};
