import React, {useEffect, useState} from 'react';
import {ListingType, Product} from './components/Product';
import {Filters} from './components/Filters';
import {Page} from '../../Shared/Page/Page';
import {searchListingService} from '../../Services/apis/ProducstsAPIs';
import {useDispatch} from 'react-redux';
import {hideLoadingAction, showLoadingAction,} from '../../Services/actions/UtilityAction';
import {Button} from '../../Components/Buttons/ButtonBootstrap';

export const ProductView = () => {

    const dispatch = useDispatch();

    const [productForm, setProductForm] = useState<{
        items: { company: string | undefined, products: ListingType[], count: number, total: number }[],
        filters: { keyword: string, type?: string, tags?: string[] }
    }>({
        items: [],
        filters: {keyword: ''}
    });

    useEffect(() => {
        dispatch(showLoadingAction());
        searchListingService('', 'new')
            .then((response) => {
                const data = response.data.listings;
                setProductForm({
                    ...productForm,
                    items: [{
                        company: undefined,
                        products: data.new,
                        count: data?.new?.length || 0,
                        total: data?.new?.length || 0
                    }]
                });
            })
            .catch((response) => {
                console.error(response);
            })
            .finally(() => dispatch(hideLoadingAction()));
    }, [dispatch]);

    const mergeListings = (old: ListingType[], news: ListingType[]) => {
        const outcome: ListingType[] = old;
        const uuids = outcome.map(item => item.listingUid);

        news.forEach(item => {
            if (!uuids.includes(item.listingUid)) {
                uuids.push(item.listingUid);
                outcome.push(item);
            }
        });

        return outcome;
    };

    const handleSearchFilter = (keyword: string, type?: string, tags?: string[], showLoading?: boolean) => {
        if (showLoading)
            dispatch(showLoadingAction());
        searchListingService(keyword, type, tags).then((response) => {
            const data = response.data.listings;
            const pagination = response.data?.summary;
            if (!data.length) {
                const items: any[] = [];
                for (const [key, value] of Object.entries(data)) {
                    let total = (pagination && pagination[key]) ? pagination[key] : 0;
                    items.push({company: key, products: value, count: (value as []).length, total});
                }

                setProductForm({
                    ...productForm,
                    items: items,
                    filters: {keyword: keyword, type: type, tags: tags}
                });
            }
        }).catch((response) => {
            console.error(response);
        }).finally(() => {
            if (showLoading)
                dispatch(hideLoadingAction());
        });
    };

    const handleSearchFilterByCompany = (company: string | undefined) => {
        dispatch(showLoadingAction());
        searchListingService(productForm.filters.keyword, productForm.filters.type, productForm.filters.tags, company)
            .then((response) => {
                const data = response.data?.listings;
                productForm.items.map((element, index) => {
                    if (element.company === company) {

                        const fullProducts = mergeListings(element.products, data[company || '']);

                        setProductForm({
                            ...productForm,
                            items: [
                                ...productForm.items.slice(0, index),
                                {
                                    company: element.company,
                                    products: fullProducts,
                                    count: fullProducts.length,
                                    total: element.total
                                },
                                ...productForm.items.slice(index + 1)
                            ]
                        });
                    }
                });
            })
            .catch((response) => {
                console.error(response);
            })
            .finally(() => dispatch(hideLoadingAction()));
    };

    const showProductsRender_ = (item: { company: string | undefined, products: ListingType[], count: number, total: number }) => {
        return (
            <React.Fragment>
                {item?.company && (<h3 className={'odt-font-family-primary-bold text-capitalize'}>{item?.company}</h3>)}
                {item?.products && item.products.map((product, index) => (
                    <div key={index} className={'col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3'}>
                        <Product data={product} mode={'private'}/>
                    </div>
                ))}
                {item?.count < item?.total ? (
                    <div className={'row odt-no-padding'}>
                        <div className={'col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}/>
                        <div className={'col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                            <Button mode={'secondary'} text={'View More'} classes={'odt-background-01'} style={{marginTop: '20px'}}
                                    onClick={() => handleSearchFilterByCompany(item?.company)}/>
                        </div>
                        <div className={'col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}/>
                    </div>) : null}
                <div style={{paddingTop: '80px'}}/>
            </React.Fragment>);
    };

    return (
        <Page mode={'private'}>
            <div className={'container-fluid'}>
                <div className={'col-xl-12 col-md-12 col-sm-12 col-12'}>

                    <div className={'row odt-card-product-canvas-filter'}>
                        <Filters type={'advance'} onSearchFilter={handleSearchFilter}/>
                    </div>

                    <div className={'row odt-card-product-canvas-items'}>
                        {productForm.items && productForm.items.map((item, index) => (
                            <React.Fragment key={index}>
                                {showProductsRender_(item)}
                            </React.Fragment>
                        ))}
                    </div>

                </div>
            </div>
        </Page>
    );
};
