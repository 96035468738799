import React from "react";
import { Provider } from "react-redux";
import { store } from "./Services/helpers/store";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { UtilityProvider } from "./Providers/UtilityProvider";
import { AuthView } from "./Containers/Authentication/AuthView";
import { SettingView } from "./Containers/Setting/SettingView";
import { ProductView } from "./Containers/Marketplace/ProductView";
import { DealsView } from "./Containers/Deal/DealsView";
import Modal from "react-modal";
import { ContactSupportView } from "./Containers/Setting/Components/ContactSupportView";
import { PreferencesView } from "./Containers/Setting/Components/PreferencesView";
import { BillingDetails } from "./Containers/Setting/Components/BillingDetails";
import { FacilityDetailView } from "./Containers/Setting/Components/FacilityDetailView";
import { ProductPublicView } from "./Containers/Public/Marketplace/ProductPublicView";
import { DetailPublicView } from "./Containers/Public/Marketplace/components/DetailPublicView";
import { PrivateNavigation } from "./Shared/Navigation/PrivateNavigation";
import { TermProcessView } from "./Containers/TermsProcess/TermProcessView";
import { TermProcessContainerView } from "./Containers/TermsProcess/TermProcessContainerView";
import { PublicNavigation } from "./Shared/Navigation/PublicNavigation";
import { DonationsView } from "./Containers/Donations/DonationsView";
import MenuView from "./Containers/Public/Marketplace/MenuView";
import UploadBulkListingWizard from "./Containers/UploadBulkListing/UploadBulkListingWizard";

const App: React.FC = () => {
  Modal.setAppElement("#root");

  const routes: { paths: string[]; component: React.FC; isPrivate: boolean }[] =
    [
      { paths: ["/", "/login"], component: AuthView, isPrivate: false },
      {
        paths: ["/redirect/view/facility/:facility"],
        component: ProductPublicView,
        isPrivate: false,
      },
      {
        paths: ["/redirect/view/custom/:facility"],
        component: MenuView,
        isPrivate: false,
      },
      {
        paths: [
          "/redirect/view/listing/:session/:listing",
          "/redirect/view/listing/:listing",
        ],
        component: DetailPublicView,
        isPrivate: false,
      },

      { paths: ["/settings"], component: SettingView, isPrivate: true },
      {
        paths: ["/settings/preference"],
        component: PreferencesView,
        isPrivate: true,
      },
      {
        paths: ["/settings/billing-details"],
        component: BillingDetails,
        isPrivate: true,
      },
      {
        paths: ["/settings/support"],
        component: ContactSupportView,
        isPrivate: true,
      },
      {
        paths: ["/settings/facility/:id"],
        component: FacilityDetailView,
        isPrivate: true,
      },
      { paths: ["/products"], component: ProductView, isPrivate: true },
      {
        paths: ["/upload-bulk-listing"],
        component: UploadBulkListingWizard,
        isPrivate: true,
      },
      { paths: ["/deals"], component: DealsView, isPrivate: true },
      { paths: ["/donations"], component: DonationsView, isPrivate: true },

      {
        paths: ["/deal/process/:token/:device/:listing/:deal?"],
        component: TermProcessView,
        isPrivate: false,
      },
      {
        paths: ["/deal/process"],
        component: TermProcessContainerView,
        isPrivate: false,
      },
    ];

  return (
    <Provider store={store}>
      <UtilityProvider>
        <BrowserRouter>
          <Switch>
            {routes &&
              routes.map((element, index) =>
                element.isPrivate ? (
                  <Route exact strict key={index} path={element.paths}>
                    <PrivateNavigation children={element.component} />
                  </Route>
                ) : (
                  <Route exact strict key={index} path={element.paths}>
                    <PublicNavigation
                      paths={element.paths}
                      children={element.component}
                    />
                  </Route>
                ),
              )}
          </Switch>
        </BrowserRouter>
      </UtilityProvider>
    </Provider>
  );
};

export default App;
