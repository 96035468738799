import axios, {ResponseType} from 'axios';

const getHeaders = (headers?: any) => {
    return {
        Accept: '*/*',
        'Content-Type': 'application/json',
        ...headers
    };
};

const getInstance = () => {
    return axios.create();
};

export const get = async (url: string, headers?:any) => {
    return getInstance()({
        url,
        method: 'GET',
        headers: headers || getHeaders()
    });
};

export const post = async (url: string, body: any, headers?: any, responseType?: ResponseType) => {
    return getInstance()({
        url,
        method: 'POST',
        data: body,
        headers: getHeaders(headers),
        responseType
    });
};
