const DIMENSIONS = [768, 800, 1024, 1280];

export const optimizeImage = (url = '') => {
    if (url) {
        const lastIndex = url.lastIndexOf('/');
        return url.substr(0, lastIndex) + '/800' + url.substr(lastIndex);
    }
    return url;
}

export const optimize = (url = '', size = 768,isCheck=false) => {
    if (url && DIMENSIONS.includes(size) && !isCheck) {
        const lastIndex = url.lastIndexOf('/');
        return url.substr(0, lastIndex) + '/' + size + url.substr(lastIndex);
    }
    return url;
}

export const getExtension = (url = '') => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}
