import React, { CSSProperties, useRef, useState } from "react";
import { AiOutlineSearch, BsSearch, FaRegCopy } from "react-icons/all";
import ReactTooltip from "react-tooltip";
import { isNumber } from "../../Utilities/NumberUtility";
import parse from "html-react-parser";

type InputType = {
  name?: string;
  classes?: string;
  type: "text" | "email" | "select" | "number" | "checkbox" | "copy" | "search";
  placeholder: string;
  helper?: string;
  value: any;
  options?: { loading?: boolean; options: { id: any; name: any }[] };
  onChange: (e: { target: any }) => void;
  onClickOption?: (value: string) => void;
};

type InputFormType = {
  mode?:
    | "default"
    | "primary"
    | "secondary"
    | "default_inline"
    | "primary_inline";
  label?: string;
  numberType?: "currency";
  type?: "text" | "password" | "email" | "checkbox" | "number" | "date";
  classes?: string;
  value: any;
  style?: CSSProperties;
  options?: { loading?: boolean; options: { id: any; name: any }[] };
  placeholder?: string;
  helper?: string;
  control: any;
  enable?: boolean;
  autocomplete?: boolean;
  errors: any;
  icon?: JSX.Element;
  onChange: (e: { target: any }) => void;
  onClickOption?: (value: string) => void;
};

type InputSearchType = {
  classes?: string;
  value: any;
  placeholder?: string;
  onChange: (e: { target: any }) => void;
};

const MODE_STYLE = {
  default: {
    label: "odt-font-15",
    input: "odt-input-text-default",
  },
  primary: {
    label: "odt-font-16 odt-font-family-primary-bold",
    input: "odt-input-text-primary odt-font-family-primary",
  },
  secondary: {
    label: "odt-font-16 odt-font-family-secondary",
    input: "odt-input-text-secondary odt-font-family-secondary",
  },
  default_inline: {
    label: "",
    input: "odt-input-text-inline odt-font-family-secondary",
  },
  primary_inline: {
    label: "odt-font-family-secondary odt-font-20 odt-font-p",
    input:
      "odt-input-text-inline-primary odt-font-family-secondary odt-font-18 odt-font-p",
  },
};

export const InputTextForm = ({
  mode = "default",
  label,
  type = "text",
  classes = "",
  value,
  placeholder,
  helper,
  control,
  errors,
  onChange,
  enable = true,
}: InputFormType) => {
  if (type === "checkbox") {
    return (
      <div className={"form-check"}>
        <input
          id={`check_${control.name}`}
          name={control.name}
          type={"checkbox"}
          className={"form-check-input"}
          value={value}
          disabled={!enable}
          {...control}
          onChange={(event) => {
            control.onChange(event);
            onChange(event);
          }}
        />
        <label
          className={"form-check-label odt-input-label-checkbox odt-font-15"}
          htmlFor={`check_${control.name}`}
        >
          {placeholder}
        </label>
      </div>
    );
  }

  return (
    <React.Fragment>
      {label && (
        <label className={`form-label text-start ${MODE_STYLE[mode].label}`}>
          {label}
        </label>
      )}

      <div className={"form-group odt-padding"}>
        <input
          className={`form-control ${classes} ${MODE_STYLE[mode].input}`}
          name={control.name}
          type={type}
          value={value}
          disabled={!enable}
          placeholder={placeholder}
          {...control}
          onChange={(event) => {
            control.onChange(event);
            onChange(event);
          }}
        />
        {/*{type && type === 'password' && (*/}
        {/*    <span className="input-group-append">*/}
        {/*    <button className={'btn odt-button-search'} type={'button'}>*/}
        {/*        <AiFillEyeInvisible size={'20'} color={'#2f4a31'}/>*/}
        {/*    </button>*/}
        {/*</span>*/}
        {/*)}*/}

        <div className={"odt-input-text-default-footer"}>
          {helper && <span className={`odt-font-13`}>{helper}</span>}

          {errors[control.name] && errors[control.name].type === "required" && (
            <span
              className={
                "text-danger odt-text-danger odt-font-family-secondary odt-font-14"
              }
            >
              This field is required.
            </span>
          )}

          {errors[control.name] && errors[control.name].type === "pattern" && (
            <span
              className={
                "text-danger odt-text-danger odt-font-family-secondary odt-font-14"
              }
            >
              Not match with format.
            </span>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export const InputTextInLineForm = ({
  mode = "primary_inline",
  type = "text",
  numberType,
  classes = "",
  label,
  value,
  placeholder,
  helper,
  control,
  errors,
  icon,
  onChange,
  enable = true,
  autocomplete = true,
}: InputFormType) => {
  const valueMask = `${numberType === "currency" ? "$ " : ""}`;
  const classNames = `${classes} ${enable ? "" : "odt-mouse-pointer"} ${
    valueMask ? "odt-padding-left-3" : ""
  } ${icon ? "d-inline odt-w-96" : ""}`;

  const handleOnChangeValue = (event: { target: any }) => {
    let isValid = true;
    if (type === "number") isValid = isNumber(event.target.value);

    if (type === "number" && !event.target.value) {
      control.onChange(event);
      onChange(event);
      return;
    }
    if (isValid) {
      control.onChange(event);
      onChange(event);
    }
  };

  return (
    <React.Fragment>
      {label && (
        <label className={`form-label text-start ${MODE_STYLE[mode].label}`}>
          {label}
        </label>
      )}

      <div className={`form-group odt-no-padding`}>
        {valueMask && (
          <div className={"d-inline-block align-top odt-mask-input-inline"}>
            <span className={`odt-font-18 odt-font-family-primary-bold`}>
              {valueMask}
            </span>
          </div>
        )}

        <div className={`${valueMask ? "d-inline-block" : ""}`}>
          {type === "date" ? (
            <input
              className={`form-control ${classNames} ${MODE_STYLE[mode].input}`}
              name={control.name}
              type="date"
              value={value}
              disabled={!enable}
              placeholder={placeholder}
              autoComplete={`${autocomplete}`}
              {...control}
              onChange={handleOnChangeValue}
            />
          ) : (
            <input
              className={`form-control ${classNames} ${MODE_STYLE[mode].input}`}
              name={control.name}
              type="text"
              value={value}
              disabled={!enable}
              placeholder={placeholder}
              autoComplete={`${autocomplete}`}
              {...control}
              onChange={handleOnChangeValue}
            />
          )}
          {icon ? (
            <span
              className={"d-inline odt-input-text-border-bottom"}
              style={{ paddingBottom: "8px" }}
            >
              {" "}
              {icon}{" "}
            </span>
          ) : null}

          <div className={"odt-input-text-default-footer"}>
            {helper && <span className={`odt-font-13`}>{helper}</span>}

            {errors[control.name] &&
              errors[control.name].type === "required" && (
                <span
                  className={
                    "text-danger odt-text-danger odt-font-family-secondary odt-font-14"
                  }
                >
                  This field is required.
                </span>
              )}

            {errors[control.name] &&
              errors[control.name].type === "pattern" && (
                <span
                  className={
                    "text-danger odt-text-danger odt-font-family-secondary odt-font-14"
                  }
                >
                  Not match with format.
                </span>
              )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const InputTextAreaForm = ({
  mode = "default",
  classes = "",
  value,
  placeholder,
  helper,
  control,
  errors,
  onChange,
  enable = true,
}: InputFormType) => {
  return (
    <React.Fragment>
      <div className={"form-group odt-padding"}>
        <textarea
          className={`form-control ${classes} ${MODE_STYLE[mode].input}`}
          name={control.name}
          value={value}
          disabled={!enable}
          placeholder={placeholder}
          {...control}
          onChange={(event) => {
            control.onChange(event);
            onChange(event);
          }}
        />
        <div className={"odt-input-text-default-footer"}>
          {helper && <span className={`odt-font-13`}>{helper}</span>}

          {errors[control.name] && errors[control.name].type === "required" && (
            <span
              className={
                "text-danger odt-text-danger odt-font-family-secondary odt-font-14"
              }
            >
              This field is required.
            </span>
          )}

          {errors[control.name] && errors[control.name].type === "pattern" && (
            <span
              className={
                "text-danger odt-text-danger odt-font-family-secondary odt-font-14"
              }
            >
              Not match with format.
            </span>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export const InputSelectForm = ({
  mode = "default",
  classes = "",
  value,
  options,
  placeholder,
  control,
  errors,
  onChange,
}: InputFormType) => {
  const MODE_SELECT_STYLE = {
    default: {
      label: "",
      input: "odt-input-select odt-font-15",
    },
    primary: {
      label: "",
      input: "odt-input-select-primary odt-font-16 odt-font-family-primary",
    },
    secondary: {
      label: "",
      input: "odt-input-select-secondary odt-font-16 odt-font-family-secondary",
    },
    default_inline: {
      label: "",
      input: "odt-input-text-inline",
    },
    primary_inline: {
      label: "",
      input: "",
    },
  };

  return (
    <div className={"form-group odt-padding"}>
      <select
        className={`form-select ${classes} ${MODE_SELECT_STYLE[mode].input}`}
        name={control.name}
        value={value}
        placeholder={placeholder}
        {...control}
        onChange={(event) => {
          control.onChange(event);
          onChange(event);
        }}
      >
        <option className={"odt-input-select-no-select"} disabled value={""}>
          {placeholder}
        </option>
        {options &&
          options.options.map((element, i) => (
            <option key={i} value={element.id}>
              {parse(`${element.name}`)}
            </option>
          ))}
      </select>
      {errors[control.name] && errors[control.name].type === "required" && (
        <p className={"text-danger odt-font-family-secondary odt-font-14"}>
          This field is required.
        </p>
      )}
    </div>
  );
};

const InputSearchForm = ({
  classes,
  value,
  options,
  placeholder,
  control,
  errors,
  onChange,
  onClickOption,
  enable = true,
}: InputFormType) => {
  const [formWizard, setFormWizard] = useState<{ search: any[] }>({
    search: [""],
  });

  const handleClickField = () =>
    onClickOption && onClickOption(formWizard.search[0]);

  const handleChangeField = (e: { target: any }) => {
    const target = e.target;
    setFormWizard({ ...formWizard, search: [target.value] });
  };

  return (
    <React.Fragment>
      <div className={"input-group odt-padding"}>
        <input
          className={
            "form-control odt-input-text odt-font-15 " +
            (classes ? classes : "")
          }
          name={control.name}
          type={"text"}
          value={value}
          disabled={enable}
          placeholder={placeholder}
          {...control}
          onChange={(event) => {
            control.onChange(event);
            onChange(event);
          }}
        />
        <span className="input-group-append">
          <button className={"btn odt-button-search"} type="button">
            <AiOutlineSearch size={"20"} color={"#2f4a31"} />
          </button>
        </span>
      </div>
      {options && !!options.options.length && (
        <select
          className={"form-select odt-input-select-multiple odt-font-15"}
          name={"search"}
          value={formWizard.search}
          placeholder={placeholder}
          multiple
          onClick={handleClickField}
          onChange={handleChangeField}
          size={options.options.length > 5 ? 5 : options.options.length}
        >
          {options.options.map((element, i) => (
            <option key={i} value={element.name}>
              {element.name}
            </option>
          ))}
        </select>
      )}
      {options && options.loading && (
        <div className="text-center">
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only" />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export const InputSearchInLineForm = ({
  mode = "primary_inline",
  type = "text",
  classes = "",
  value,
  options,
  placeholder,
  helper,
  control,
  errors,
  onChange,
  onClickOption,
  enable = true,
  autocomplete = true,
}: InputFormType) => {
  const [formWizard, setFormWizard] = useState<{ search: any[] }>({
    search: [""],
  });

  const handleClickField = () =>
    onClickOption && onClickOption(formWizard.search[0]);

  const handleChangeField = (e: { target: any }) => {
    const target = e.target;
    setFormWizard({ ...formWizard, search: [target.value] });
  };

  return (
    <React.Fragment>
      <div className={`form-group odt-padding`}>
        <input
          className={`form-control ${classes} ${MODE_STYLE[mode].input}`}
          name={control.name}
          type={type}
          value={value}
          disabled={!enable}
          placeholder={placeholder}
          autoComplete={`${autocomplete}`}
          {...control}
          onChange={(event) => {
            control.onChange(event);
            onChange(event);
          }}
        />
      </div>
      {options && !!options.options.length && (
        <select
          className={"form-select odt-input-select-multiple odt-font-15"}
          name={"search"}
          value={formWizard.search}
          placeholder={placeholder}
          multiple
          onClick={handleClickField}
          onChange={handleChangeField}
          size={options.options.length > 5 ? 5 : options.options.length}
        >
          {options.options.map((element, i) => (
            <option key={i} value={element.name}>
              {element.name}
            </option>
          ))}
        </select>
      )}
      {options && options.loading && (
        <div className="text-center">
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only" />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export const InputSearchV1 = ({
  classes,
  value,
  placeholder = "",
  onChange,
}: InputSearchType) => {
  return (
    <div className={"form-group odt-search"}>
      <span className={"odt-search-icon"}>
        <i style={{ fontSize: "16px" }}>
          <BsSearch />{" "}
        </i>{" "}
      </span>
      <input
        type={"text"}
        value={value}
        placeholder={placeholder}
        className={`form-control odt-input-text-default odt-search-input ${classes}`}
        onChange={(event) => {
          onChange(event);
        }}
      />
    </div>
  );
};

// TODO: Remove the next code.

export const InputText = ({
  name,
  type,
  classes,
  value,
  placeholder,
  onChange,
  helper,
}: InputType) => {
  const id = `${Math.floor(Math.random() * 1000000)}`;
  const inputCopyRef = useRef<HTMLInputElement>(null);

  const handleCopyToClipboard = () => {
    if (inputCopyRef.current) {
      const tempInput = document.createElement("input");
      tempInput.value = inputCopyRef.current.value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
  };

  return type === "checkbox" ? (
    <div className={"form-check"}>
      <input
        id={`check_${id}`}
        name={name}
        type={"checkbox"}
        className={"form-check-input"}
        onChange={onChange}
        defaultChecked={value}
      />
      <label
        className={"form-check-label odt-input-label-checkbox odt-font-15"}
        htmlFor={`check_${id}`}
      >
        {placeholder}
      </label>
    </div>
  ) : type === "copy" ? (
    <div className={"input-group odt-padding"}>
      <input
        ref={inputCopyRef}
        name={name}
        type={"text"}
        className="form-control odt-input-text-default odt-font-15"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={true}
      />
      <span className="input-group-append">
        <button
          className={"btn odt-button-copy"}
          type="button"
          data-tip
          data-for="tooltip-copy"
          onClick={handleCopyToClipboard}
        >
          <FaRegCopy size={"20"} color={"#2f4a31"} />
        </button>
        <ReactTooltip id="tooltip-copy" place="top" effect="solid">
          {" "}
          Copy to Clipboard{" "}
        </ReactTooltip>
      </span>
    </div>
  ) : (
    <div className={"form-group odt-padding"}>
      <input
        name={name}
        type={type}
        className={
          "form-control odt-input-text odt-font-15 " + (classes ? classes : "")
        }
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {helper && <span className={"odt-font-13"}>{helper}</span>}
    </div>
  );
};

const InputSelect = ({
  name,
  value,
  options,
  placeholder,
  onChange,
}: InputType) => {
  return (
    <select
      className={"form-select odt-input-select odt-font-15"}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required
    >
      <option className={"odt-input-select-no-select"} disabled value={""}>
        {placeholder}
      </option>
      {options &&
        options.options.map((element, i) => (
          <option key={i} value={element.id}>
            {element.name}
          </option>
        ))}
    </select>
  );
};

const InputSearch = ({
  name,
  value,
  options,
  placeholder,
  onClickOption,
  onChange,
}: InputType) => {
  const [formWizard, setFormWizard] = useState<{ search: any[] }>({
    search: [""],
  });

  const handleClickField = () =>
    onClickOption && onClickOption(formWizard.search[0]);

  const handleChangeField = (e: { target: any }) => {
    const target = e.target;
    setFormWizard({ ...formWizard, search: [target.value] });
  };

  return (
    <React.Fragment>
      <div className={"input-group odt-padding"}>
        <input
          name={name}
          type={"text"}
          className="form-control odt-input-text-default odt-font-15"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        <span className="input-group-append">
          <button className={"btn odt-button-search"} type="button">
            <AiOutlineSearch size={"20"} color={"#2f4a31"} />
          </button>
        </span>
      </div>
      {options && !!options.options.length && (
        <select
          className={"form-select odt-input-select-multiple odt-font-15"}
          name={name}
          value={formWizard.search}
          onChange={handleChangeField}
          onClick={handleClickField}
          placeholder={placeholder}
          multiple
          size={options.options.length > 5 ? 5 : options.options.length}
        >
          {options.options.map((element, i) => (
            <option key={i} value={element.name}>
              {element.name}
            </option>
          ))}
        </select>
      )}
      {options && options.loading && (
        <div className="text-center">
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
