import Modal from 'react-modal';
import React, {useEffect, useRef, useState} from 'react';
import {Tab, Tabs} from '../../../Shared/Tabs/Tabs';
import {hideLoadingAction, showLoadingAction} from '../../../Services/actions/UtilityAction';
import {getListingsDetailService} from '../../../Services/apis/ProducstsAPIs';
import {useDispatch} from 'react-redux';
import {ListingType} from './Product';
import {formatDefault} from '../../../Utilities/NumberUtility';
import {Button} from '../../../Components/Buttons/ButtonBootstrap';
import {FILTERS} from './Filters';
import {Carousel} from '../../../Shared/Carousel/Carousel';
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/all';
import {getExtension, optimize} from '../../../Utilities/ImageUtility';
import Viewer from 'react-viewer';
import {ImageDecorator} from 'react-viewer/lib/ViewerProps';
import {getExtraDataFacilityInfoService} from '../../../Services/apis/SettingsAPIs';
import {Collapse} from '../../../Shared/Collapse/Collapse';
import {ImageLoading} from '../../../Shared/Image/ImageLoading';
import {handleLaunchAppleStoreAction} from '../../../Services/actions/BuyerSellerAction';

export const translateResourcesMultimedia = (images: any[], videos: any[] | undefined, scaled: boolean = true) => {
    const multimedia: { url: string, mediaType: string, extension?: string, scaled?: boolean, urlSmall?:string }[] = [];

    images.forEach((image: { url: string,urlSmall?:string }) => {
        multimedia.push({
            url: scaled ? optimize(image.url, 768) : image.url,
            urlSmall:image?.urlSmall,
            mediaType: 'image',
            extension: getExtension(image.url),
            scaled: scaled
        });
    });

    videos && videos.forEach((video: { url: string }) => {
        multimedia.push({url: video.url, mediaType: 'video', extension: getExtension(video.url), scaled: scaled});
    });

    return multimedia;
};

export const joinResourcesMultimedia = (images: any[], videos: any[] | undefined) => {
    const multimedia: any[] = [];

    images.forEach((image: any) => {
        multimedia.push(image);
    });

    videos && videos.forEach((video: any) => {
        multimedia.push(video);
    });

    return multimedia;
};


export const Detail = ({
                           listingId,
                           facilityId,
                           close
                       }: { listingId: string, facilityId: string, close: () => void }) => {

    const dispatch = useDispatch();
    const videoReference = useRef<HTMLVideoElement>(null);

    const [viewerForm, setViewerForm] = React.useState<{ visible: boolean, position: number }>({
        visible: false,
        position: 0
    });

    const [carruselForm, setCarruselForm] = React.useState<{ index: number }>({
        index: 0
    });

    const [detailForm, setDetailForm] = useState<{
        modal: { show: boolean },
        listing: ListingType,
        facility: {
            images: { url: string, mediaType: string }[]
        }
        multimedia: { url: string, mediaType: string, extension?: string, normally?: boolean,urlSmall?:string }[],
    }>({
        modal: {
            show: false
        },
        listing: {
            listingUid: '',
            inventoryItem: {
                facilityId: '',
                facilityName: '',
                facilityLicenseNumber: '',
                productName: '',
                facilityLogo: '',
                label: '',
                packagedDate: ''
            },
            listingPrice: 0,
            availableQuantity: 0,
            quantityType: '',
            dealStatus: '',
            image: {url: '', mediaType: '',urlTiny:''},
            images: [],
            tags: [''],
            name: '',
            description: '',
            facilityBio: {
                bioHeadline: '',
                bioTextData: ''
            }
        },
        facility: {
            images: [],
        },
        multimedia: []
    });

    useEffect(() => {
        dispatch(showLoadingAction());
        Promise.all([getListingsDetailService(listingId), getExtraDataFacilityInfoService(facilityId)])
            .then((response) => {
                const listing = !!response.length ? response[0]?.data?.listing : {};
                const facility = !!response.length ? response[1]?.data?.facilityData : {};

                setDetailForm({
                    ...detailForm,
                    modal: {show: true},
                    multimedia: translateResourcesMultimedia(listing?.images, listing?.videos, true),
                    listing: {
                        ...listing,
                        images: translateResourcesMultimedia(listing?.images, listing?.videos, true),
                    },
                    facility: {
                        images: translateResourcesMultimedia(facility?.images, undefined, true),
                    },
                });
            })
            .catch((response) => {
                console.error(response);
            })
            .finally(() => dispatch(hideLoadingAction()));
    }, [dispatch]);

    const handleCloseModal = () => {
        setDetailForm({...detailForm, modal: {show: false}});
        close();
    };

    const showTagsRender_ = (tags: string[]) => {
        if (!tags)
            return null;

        const options: { key: string, label: string, icon?: string }[] = [];

        FILTERS.filter(f => {
            let find = tags.includes(f.key);
            if (find) {
                options.push({key: f.key, label: f.label, icon: f.icon});
            } else if (f.tags.length !== 0) {
                let tag: any = f.tags.filter(ff => tags.includes(ff.key));
                if (!!tag.length) {
                    for (const index in tag) {
                        if (!options.includes(tag[index].key))
                            options.push({key: tag[index].key, label: tag[index].label, icon: tag[index].icon});
                    }
                }
            }
        });
        return (
            <React.Fragment>
                {/*{options && options.map((element, index) => (*/}
                {/*    <div key={index} className={'d-inline-block width-facilityv2'}>*/}
                {/*       */}
                {/*            <div className={'odt-card-product-filter-item-xlv3'}>*/}
                {/*                <img src={`/customized/images/icons/${element.icon}`} alt={`tag-${index}`}/>*/}
                {/*            </div>*/}
                {/*  */}
                {/*        <div style={{width: '100%', height: '100%', textAlign: 'center'}}>*/}
                {/*            <span className={'odt-font-family-secondary odt-font-18'}>{element.label}</span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*))}*/}

                {options && options.map((tag, indexTag) => (
                    <div key={indexTag} className={'d-inline-block text-center odt-item-tag-v2'} >
                        <div className={`d-flex justify-content-center`}>
                            <div className={`odt-item-tag-image-v2`}>
                                <img alt={`tag-${indexTag}-facility`}
                                     src={`/customized/images/icons/${tag.icon}`} style={{
                                    width: '60%',
                                    height: '60%',
                                    marginTop: '20%'
                                }}/>
                            </div>
                        </div>
                        <div className={'odt-item-tag-description odt-font-family-secondary odt-font-18v2'}>{tag.label}</div>
                    </div>
                ))}

            </React.Fragment>);
    };

    const renderWithViewer_ = (images: { url: string; scaled?: boolean }[]) => {
        const outcome: ImageDecorator[] = [];
        let index = 0;
        images.forEach(image => {
            outcome.push({src: image.url, alt: `product-image-${++index}`});
        });
        return outcome;
    };

    return (
        <Modal className={'odt-modal'} overlayClassName={'odt-modal-overlay'} isOpen={detailForm.modal.show}
               shouldCloseOnEsc={true} shouldFocusAfterRender={true}
               onRequestClose={handleCloseModal}>

            <div className={'row'} style={{height: '100%'}}>

                <div id={'header-close-btn'} className={'position-relative'}>
                    <div className={'position-absolute top-0 end-0 odt-modal-btn-close'} onClick={handleCloseModal}>
                        <div className="text-center">
                            <img src={'/customized/images/icons/close.icon.svg'} alt={'close-icon'} width={30}/>
                        </div>
                    </div>
                </div>

                <div id={'detail-panel-left'}
                     className={'col-xl-6 col-md-6 col-sm-6 col-6 odt-scroll-verticalv2'}
                     style={{background: '#FFFFFF', borderRadius: '50px 0 0 50px', height: '100%'}}>

                    <div style={{padding: '50px 15px 0 15px'}}>
                        <h2 className={'odt-font-family-primary-bold'}>{detailForm.listing?.name}</h2>

                        <Collapse id={'detail-content'} header={((open, handleOpen) => {
                            return (
                                <p className={'odt-p'}>
                                    <span>{detailForm.listing?.inventoryItem?.facilityName}</span>
                                    <span style={{cursor: 'pointer'}} onClick={handleOpen}>
                                        {detailForm.listing?.inventoryItem?.facilityLicenseNumber}
                                        <i style={{fontSize: '30px', paddingLeft: '5px'}}> {open ? <MdKeyboardArrowDown/> : <MdKeyboardArrowUp/>} </i>
                                    </span>
                                </p>
                            );
                        })}>
                            <p className={'odt-p odt-font-family-secondary'}>
                                <span>{detailForm.listing?.inventoryItem?.productName}</span>
                                <span>{detailForm.listing?.inventoryItem?.packagedDate}</span>
                                <span>{detailForm.listing?.inventoryItem?.label}</span>
                                <span>{`${formatDefault(detailForm.listing?.availableQuantity)} ${detailForm.listing?.quantityType}`}</span>
                            </p>
                        </Collapse>
                    </div>

                    <Tabs headers={[{
                        title: 'Listing',
                        enable: true,
                        action: () => {
                            setDetailForm({
                                ...detailForm,
                                multimedia: joinResourcesMultimedia(detailForm.listing?.images, detailForm.listing?.videos)
                            });
                            setCarruselForm({index: 0});
                        }
                    }, {
                        title: 'Facility',
                        enable: true,
                        action: () => {
                            setDetailForm({
                                ...detailForm,
                                multimedia: joinResourcesMultimedia(detailForm.facility?.images, undefined)
                            });
                            setCarruselForm({index: 0});
                        }
                    }]}>
                        <Tab>
                            <h3 className={'odt-font-family-secondary-bold'}>Test</h3>

                            <p className={'odt-p'}>
                                <span>{detailForm.listing?.description}</span>
                            </p>

                            <div className={'odt-panel-button-v2'}>
                                <Button classes={'odt-font-family-primary-bold'} mode={'primary'}
                                        text={'request a sample'} onClick={() => handleLaunchAppleStoreAction()} />

                                <Button classes={'odt-font-family-primary-bold'} mode={'primary'} text={'make an offer'}
                                        style={{background: '#000000'}}/>
                            </div>
                        </Tab>
                        <Tab>
                            <h3 className={'odt-font-family-secondary-bold'}>{detailForm.listing?.inventoryItem?.facilityName}</h3>

                            <p className={'odt-p'}>
                                <span>{detailForm.listing?.facilityBio?.bioHeadline}</span>
                            </p>

                            <p className={'odt-p'}>
                                <span>{detailForm.listing?.facilityBio?.bioTextData}</span>
                            </p>

                            <div style={{paddingTop: '5px'}}>
                                {showTagsRender_(detailForm.listing?.tags)}
                            </div>
                        </Tab>
                    </Tabs>

                </div>

                <div id={'detail-panel-right'}
                     className={'col-xl-6 col-md-6 col-sm-6 col-6'}
                     style={{borderRadius: '50px 0 0 50px', paddingLeft: 'inherit', height: '100%'}}>
                    <Carousel startIndex={carruselForm.index}>
                        {detailForm.multimedia && detailForm?.multimedia.map((resource, index) => (
                            <div key={index} style={{width: '100%', height: '100%', borderRadius: '50px 0 0 50px'}}>

                                {resource.mediaType === 'image' ? (
                                    <ImageLoading src={resource?.urlSmall || resource?.url} alt={`img-${index}`}
                                                  style={{
                                                   width: '100%', height: '100%',
                                                   objectFit: 'cover',
                                                   borderRadius: '0 50px 50px 0',
                                                   cursor: 'pointer'
                                               }}
                                                  onClick={() => setViewerForm({
                                                   visible: true, position: index
                                               })}/>) : null}

                                {resource.mediaType === 'video' ? (
                                    <div style={{padding: '0% 10% 0% 10%', height: '100%'}}>
                                        <video ref={videoReference} className={'align-middle'} width={'100%'}
                                               height={'100%'} controls>
                                            <source src={resource.url} type={`video/${resource.extension}`}/>
                                        </video>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </Carousel>

                    <Viewer visible={viewerForm.visible}
                            onClose={() => setViewerForm({visible: false, position: 0})}
                            images={renderWithViewer_(detailForm.multimedia)}
                            activeIndex={viewerForm.position}
                            noNavbar={false}
                            noImgDetails={true}
                            noFooter={false}
                            onMaskClick={() => setViewerForm({visible: false, position: 0})}/>
                </div>

            </div>

        </Modal>
    );
};
