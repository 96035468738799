import React from 'react';

export interface LinkType {
    mode?: 'primary' | 'secondary',
    text: string,
    className?: string,
    onClick?: () => void,
    disabled?: boolean
}

const MODE_STYLE = {
    primary: {
        button: 'odt-font-family-primary'
    },
    secondary: {
        button: 'odt-font-family-secondary'
    }
};

export const Link = ({mode = 'primary', text, className, disabled = false, onClick}: LinkType) => {
    return (
        <div className={`${className}`}>
            <button type={'button'} style={{color: '#183E43'}} onClick={onClick}
                    className={`btn odt-link odt-font-17 odt-font-p ${MODE_STYLE[mode].button}`}>{text}</button>
        </div>
    );
};
