import React, {useEffect, useState} from 'react';
import {ListingType, Product} from '../../Marketplace/components/Product';
import {FacilityType, Page} from '../../../Shared/Page/Page';
import {searchListingsPublicService} from '../../../Services/apis/ProducstsAPIs';
import {useDispatch} from 'react-redux';
import {hideLoadingAction, showLoadingAction,} from '../../../Services/actions/UtilityAction';
import {Button} from '../../../Components/Buttons/ButtonBootstrap';
import {useHistory, useLocation, useParams} from 'react-router-dom';

export const ProductPublicView = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {facility} = useParams<{ facility: string }>();
    const search = useLocation().search;
    const session = new URLSearchParams(search).get('session') || '';

    const [productForm, setProductForm] = useState<{
        facility: FacilityType | undefined;
        items: { products: ListingType[]; count: number; total: number }[];
    }>({
        facility: undefined,
        items: []
    });

    useEffect(() => {
        dispatch(showLoadingAction());
        searchListingsPublicService(session, facility).then((response: {
                data: {
                    facility: {
                        logoUrl: string;
                        name: string;
                        facilityDba: string;
                        licenseNumber: string;
                        bioBody: string;
                        bioHeadline: string;
                        images: { url: string }[];
                        tags: string[];
                    };
                    listings: any;
                };
            }) => {
                setProductForm({
                    ...productForm,
                    facility: {
                        logo: response.data?.facility?.logoUrl,
                        image: !!response.data?.facility?.images?.length ? response.data?.facility?.images[0].url : '',
                        name: response.data?.facility?.name,
                        licenseNumber: response.data?.facility?.licenseNumber,
                        bioBody: response.data?.facility?.bioBody,
                        bioHeadline: response.data?.facility?.bioHeadline,
                        tags: response.data?.facility?.tags,
                        facilityDba: response.data?.facility?.facilityDba
                    },
                    items: [
                        {
                            products: response.data?.listings,
                            count: response.data?.listings?.length || 0,
                            total: response.data?.listings?.length || 0
                        }
                    ]
                });
            }
        )
            .catch((response: any) => {
                console.error(response);
                history.push('/login');
            })
            .finally(() => dispatch(hideLoadingAction()));
    }, [dispatch]);

    const showProductsRender_ = (item: { products: ListingType[]; count: number; total: number }) => {
        return (
            <React.Fragment>
                {item?.products &&
                    item.products.map((product, index) => (
                        <div key={index} className={'col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3'}>
                            <Product data={product} mode={'public'}/>
                        </div>
                    ))}
                {item?.count < item?.total ? (
                    <div className={'row odt-no-padding'}>
                        <div className={'col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}/>
                        <div className={'col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                            <Button mode={'secondary'} text={'View More'} classes={'odt-background-01'}
                                    style={{marginTop: '20px'}}/>
                        </div>
                        <div className={'col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'}/>
                    </div>
                ) : null}
                <div style={{paddingTop: '80px'}}/>
            </React.Fragment>
        );
    };

    return (
        <Page mode={'public'} data={productForm.facility}>
            <div className={'container-fluid'}>
                <div className={'col-xl-12 col-md-12 col-sm-12 col-12'}>

                    <div className={'row odt-card-product-canvas-items'}>
                        {productForm.items && productForm.items.map((item, index) => (
                            <React.Fragment key={index}>
                                {showProductsRender_(item)}
                            </React.Fragment>
                        ))}
                    </div>

                </div>
            </div>
        </Page>
    );
};
