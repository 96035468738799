const TAG_ACCESS_TOKEN = "access";
const TAG_TOKEN_BUYER_SELLER_PROCESS = "process-access";

export const getHttpHeaders = () => {
  const metadata = JSON.parse(localStorage.getItem(TAG_ACCESS_TOKEN) || "{}");
  return {
    Authorization: `Bearer ${metadata.token}`,
    "X-device-id": `${metadata.device}`
  };
};

export const getNoAuthHttpHeaders = () => {
  const metadata = JSON.parse(localStorage.getItem(TAG_ACCESS_TOKEN) || "{}");
  return {
    "X-device-id": `${metadata.device}`
  };
};

export const getHttpHeaderAuthorizationWithoutBearer = () => {
  return getHttpHeaders()
    ["Authorization"].replace("Bearer", "")
    .trim();
};

export const getHttpHeaderAuthorization = () => {
  return getHttpHeaders()["Authorization"];
};

export const getHttpHeaderXDeviceId = () => {
  return getHttpHeaders()["X-device-id"];
};

export const getBuyerSellerHttpHeaders = () => {
  const metadata = JSON.parse(localStorage.getItem(TAG_TOKEN_BUYER_SELLER_PROCESS) || "{}");
  return {
    Authorization: `Bearer ${metadata.token}`,
    "X-device-id": `${metadata.device}`
  };
};
