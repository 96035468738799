import React, {CSSProperties} from 'react';
import {useSelector} from 'react-redux';
import {AuthenticationData} from '../../Services/reducers/states/AuthenticationData';
import {BiMenu} from 'react-icons/all';

export type AvatarProperties = {
    className: string,
    style?: CSSProperties,
    onClick: () => void,
    size?: number,
    title?: 'left' | 'bottom',
    text?: string
}

export const Avatar = ({onClick, text = '', size = 10, className = '', title = 'left'}: AvatarProperties) => {

    const store = useSelector((state: AuthenticationData) => state.auth.user);

    return (
        <div className={'text-center'} style={{paddingRight: `${title === 'bottom' ? '0px' : '15px'}`}}>
            <div className={`d-flex justify-content-center`}>
                <div className={`${className}`}>
                    {title === 'left' && (
                        <span className={'odt-font-family-primary-bold'}
                              style={{paddingRight: '15px', paddingTop: '10px'}}>{store.fullname}</span>)}

                    {store?.facilities[0]?.logo ? (
                        <img alt={'avatar-user'} style={{width: `${size}px`, height: `${size}px`}}
                             className={'odt-img-avatar'} onClick={onClick} src={store?.facilities[0]?.logo || ''}/>
                    ) : (
                        <span className="odt-img-avatar" style={{padding: `18px ${title === 'bottom'? '18':'16'}px`}}
                              onClick={onClick}>
                            <i style={{fontSize: `25px`}}><BiMenu/></i>
                        </span>
                    )}

                    {title === 'bottom' && (
                        <React.Fragment>
                            {text && (
                                <p className={'odt-font-14'} style={{paddingTop: '15px', opacity: '0.5'}}>{text}</p>)}
                            <p className={'odt-font-family-primary-bold odt-font-19'}
                               style={{paddingTop: (text ? '0' : '25px'), paddingBottom: '50px'}}>{store.fullname}</p>
                        </React.Fragment>)}
                </div>
            </div>
        </div>
    );
};
