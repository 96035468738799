import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../Components/Buttons/ButtonBootstrap";
import { AuthenticationData } from "../../Services/reducers/states/AuthenticationData";
import { CSVLink } from "react-csv";
import { getConfirmBulkUploadResponse, getValidateBulkUploadResponse } from "../../Services/apis/bulkUploadAPIs";
import { CLOSE_LOADING, OPEN_LOADING } from "../../Services/actions/UtilityAction";

const UploadBulkListingWizard = ({ handleClose }: any) => {
  const [facilitiesList, setFacilitiesList] = useState<any>([]);
  const [facilityData, setFacilityData] = useState<{
    facilityId: string;
    facilityLicense: string;
    csvFile: any;
    csvFileName: string;
    name: string;
  }>({
    facilityId: "",
    facilityLicense: "",
    csvFile: "",
    csvFileName: "",
    name: ""
  });
  const [isValidate, setIsValidate] = useState<{
    validate: boolean;
    confirmMsg: string;
    errors: any[];
  }>({
    validate: false,
    confirmMsg: "",
    errors: []
  });
  let facilitiesData = useSelector((state: AuthenticationData) => state.auth.user?.facilities);
  const dispatch = useDispatch();

  useEffect(() => {
    if (facilitiesData && facilitiesData.length) {
      if (facilitiesData.length > 1) {
        let list: any[] = [];
        facilitiesData.forEach(item => {
          list.push({
            name: item?.name,
            id: item?.id,
            licenseNumber: item?.licenseNumber
          });
        });
        setFacilitiesList(list);
      } else {
        setFacilityData({
          ...facilityData,
          facilityId: facilitiesData[0]?.id,
          facilityLicense: facilitiesData[0]?.licenseNumber,
          name: facilitiesData[0]?.name
        });
      }
    }
  }, [facilitiesData]);

  const callValidateAPI = (type: string) => {
    const { facilityId, facilityLicense, csvFile } = facilityData;

    const formData = new FormData();
    formData.append("facilityId", facilityId);
    formData.append("facilityLicense", facilityLicense);
    formData.append("csvFile", csvFile);

    dispatch({ type: OPEN_LOADING, payload: {} });

    getValidateBulkUploadResponse(formData)
      .then(response => {
        if (response?.data?.success) {
          if (response?.data?.messages && response?.data?.messages.length) {
            setIsValidate({
              ...isValidate,
              errors: response?.data?.messages
            });
          } else {
            if (type === "validate") {
              setIsValidate({ ...isValidate, validate: true, errors: [] });
            } else {
              handleCancelAndConfirm();
              setIsValidate({
                ...isValidate,
                validate: false,
                confirmMsg: "Successfully uploaded....."
              });
            }
          }
          dispatch({ type: CLOSE_LOADING, payload: {} });
        } else {
          setIsValidate({
            ...isValidate,
            errors: response?.data?.newListings || []
          });
          dispatch({ type: CLOSE_LOADING, payload: {} });
        }
      })
      .catch(error => {
        setIsValidate({
          ...isValidate,
          errors: ["Something went wrong", `${error?.message}`]
        });
        dispatch({ type: CLOSE_LOADING, payload: {} });
      });
  };

  const callConfirmAPI = () => {
    const { facilityId, facilityLicense, csvFile } = facilityData;

    const formData = new FormData();
    formData.append("facilityId", facilityId);
    formData.append("facilityLicense", facilityLicense);
    formData.append("csvFile", csvFile);

    getConfirmBulkUploadResponse(formData)
      .then(response => {
        if (response?.status === 200) {
          handleClose();
        }
      })
      .catch(error => {
        setIsValidate({
          ...isValidate,
          errors: ["Something went wrong", `${error?.message}`]
        });
        dispatch({ type: CLOSE_LOADING, payload: {} });
      });
  };

  const handleValidate = () => {
    callValidateAPI("validate");
  };

  const handleConfirm = () => {
    callConfirmAPI();
  };

  const handleCancelAndConfirm = () => {
    setIsValidate({ ...isValidate, validate: false, errors: [] });
    if (facilitiesData.length > 1) {
      setFacilityData({
        ...facilityData,
        facilityId: "",
        facilityLicense: "",
        csvFile: "",
        csvFileName: ""
      });
    } else {
      setFacilityData({
        ...facilityData,
        csvFile: "",
        csvFileName: ""
      });
    }
  };

  const handleFacility = (event: any) => {
    const { value } = event.target;
    const data = facilitiesList.find((item: any) => item.id === value);
    if (data) {
      setFacilityData({
        ...facilityData,
        facilityId: data.id,
        facilityLicense: data.licenseNumber
      });
    } else {
      setFacilityData({
        ...facilityData,
        facilityId: "",
        facilityLicense: ""
      });
    }
  };

  const headers = [
    { label: "Listing Name", key: "listingName" },
    { label: "Listing Description", key: "listingDescription" },
    { label: "Price", key: "price" },
    { label: "Price Unit", key: "priceUnit" },
    { label: "Quantity", key: "quantity" },
    { label: "Quantity Unit", key: "quantityUnit" },
    { label: "Image Link", key: "imageLink" },
    { label: "Metrc Tag", key: "metrcTag" },
    { label: "Category", key: "category" },
    { label: "Category Type", key: "categoryType" },
    { label: "Metrc Item Name", key: "metrcItem" },
    { label: "Brand", key: "brand" },
    { label: "Listing Facility License", key: "listingFacility" }
  ];
  //const csvData: any[] = [];
  const csvData: any[] = [
    {
      listingName: "Listing name is required",
      listingDescription: "Listing description is required",
      price: 100,
      priceUnit: "each",
      quantity: 2,
      quantityUnit: "cases",
      imageLink: "https://link.to.image1,https://link.to.image2 (optional comma separated links)",
      metrcTag: "Metrc tag is optional",
      category: "category is required",
      categoryType: "category type is optional",
      metrcItem: "metrc item name is required for cannabis items",
      brand: "brand name is optional",
      listingFacility: "listing facility will default to current"
    }
  ];

  const handleFile = (e: any) => {
    setFacilityData({
      ...facilityData,
      csvFile: e.target.files[0],
      csvFileName: e.target.files[0]?.name
    });
    setIsValidate({ ...isValidate, validate: false, errors: [] });
  };

  useEffect(() => {
    if (isValidate.confirmMsg) {
      const getData = setTimeout(() => {
        setIsValidate({
          ...isValidate,
          confirmMsg: ""
        });
      }, 3000);

      return () => clearTimeout(getData);
    }
  }, [isValidate.confirmMsg]);
  return (
    <React.Fragment>
      {isValidate.confirmMsg ? (
        <div className="container">
          <p className="bulk-success bulk-upload bulk-box odt-font-family-primary-bold success-box">{isValidate.confirmMsg}</p>
        </div>
      ) : null}

      <div className="container" style={{ textAlign: "center" }}>
        {" "}
        <>
          <p>
            Please create a file with the details of the listings to be added.
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"listo-bulk-upload-template.csv"}
              className="odt-font-family-primary-bold bulk-listing"
              style={{
                width: "100%",
                fontSize: 18,
                lineHeight: "1.5",
                padding: "10px",
                borderRadius: "15px",
                textAlign: "center"
              }}>
              Use The Attached Csv File As A Template
            </CSVLink>
            and fill in the rows for the new listings. The system will validate the data and create the listings as specified.
          </p>
          <input id="upload" type="file" accept=".xlsx, .xls, .csv" className="bulk-upload" onChange={handleFile} />

          {facilityData.csvFileName ? (
            <p className="selected-file">
              <span className={"odt-font-family-primary-bold"}>Selected file is :</span> {facilityData.csvFileName}
            </p>
          ) : null}
          {facilitiesData && facilitiesData.length ? (
            facilitiesData.length > 1 ? (
              <div className="bulk-upload">
                <label className="odt-font-family-primary-bold" style={{ marginBottom: 20 }}>
                  Upload to facility :
                </label>
                <select name="facility" onChange={handleFacility} className="bulk-upload bulk-box bul-select" value={facilityData?.facilityId}>
                  <option value={""}>Select Facility</option>
                  {facilitiesList.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              <label className="odt-font-family-primary-bold">
                Upload to facility :{" "}
                <span className="odt-font-family-primary">
                  {facilityData.name || ""} / {facilityData.facilityLicense || ""}
                </span>
              </label>
            )
          ) : null}

          {isValidate.errors && isValidate.errors.length ? (
            <ul className="bulk-upload bulk-box">
              <h6 className="error-heading odt-font-family-primary-bold">{isValidate.errors.length > 1 ? "Errors" : "Error"}</h6>
              {isValidate.errors.map((error: any) =>
                Array.isArray(error?.errors) ? error?.errors?.map((item: any) => <li className="bulk-error">{item}</li>) : <li className="bulk-error">{error}</li>
              )}
            </ul>
          ) : null}
          {isValidate.validate ? (
            <p className="bulk-upload bulk-box bulk-success odt-font-family-primary-bold" style={{ marginTop: 13 }}>
              Validation Passed
            </p>
          ) : null}
          <div className="bulk-buttons">
            <Button
              mode={"primary"}
              text={"Cancel"}
              classes={"odt-font-family-primary-bold bulk-listing"}
              style={{
                background: "#183E43"
              }}
              align={"text-start"}
              onClick={handleClose}
            />
            {isValidate?.validate ? (
              <Button
                mode={"primary"}
                text={"Confirm"}
                classes={"odt-font-family-primary-bold bulk-listing"}
                style={{
                  background: "#183E43"
                }}
                align={"text-start"}
                disabled={!facilityData?.csvFile}
                onClick={handleConfirm}
              />
            ) : (
              <Button
                mode={"primary"}
                text={"validate"}
                classes={"odt-font-family-primary-bold bulk-listing"}
                style={{
                  background: "#183E43"
                }}
                align={"text-start"}
                disabled={!facilityData?.csvFile}
                onClick={handleValidate}
              />
            )}
          </div>
        </>
      </div>
    </React.Fragment>
  );
};

export default UploadBulkListingWizard;
