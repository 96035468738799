import { CLOSE_LOADING, OPEN_LOADING } from "./UtilityAction";
import {
  addCryptoToWalletByBioFacilityService,
  addNewBillingForm,
  addTagBioFacilityService,
  getBillingDetailsList,
  getBioFacilityInfoService,
  getBioFacilityTypeaheadSearch,
  getCityState,
  getCryptoWalletByBioFacilityService,
  getExtraDataFacilityInfoService,
  getTagsByBioFacilityInfoService,
  removeCryptoToWalletByBioFacilityService,
  removeTagBioFacilityService,
  updateBioFacilityInfo,
  updatePreferencesService,
  uploadFilesBioFacilityInfoService,
} from "../apis/SettingsAPIs";
import { createMessageContactSupportService } from "../apis/BuyerSellerAPIs";

export const SETTINGS_RENDER_ERROR = "SETTINGS_RENDER_ERROR";

export const getBioFacilityInfoAction = (facilityId) => {
  return [
    getBioFacilityInfoService(facilityId),
    getExtraDataFacilityInfoService(facilityId),
  ];
};

export const uploadFileBioFacilityInfoAction = (
  facilityId,
  files,
  callback,
) => {
  const info = {
    id: facilityId,
    file: files,
  };
  return (dispatch) => {
    dispatch({ type: OPEN_LOADING, payload: {} });
    uploadFilesBioFacilityInfoService(info)
      .then((response) => {
        callback();
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      })
      .finally(() => {
        dispatch({ type: CLOSE_LOADING, payload: {} });
      });
  };
};

export const updateBioFacilityInfoAction = (facility, callback) => {
  return (dispatch) => {
    dispatch({ type: OPEN_LOADING, payload: {} });
    updateBioFacilityInfo(facility)
      .then((response) => {
        callback();
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      })
      .finally(() => {
        dispatch({ type: CLOSE_LOADING, payload: {} });
      });
  };
};

export const getTagsByBioFacilityInfoAction = (facilityId, callback) => {
  return (dispatch) => {
    getTagsByBioFacilityInfoService(facilityId)
      .then((response) => {
        callback(response.data);
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      });
  };
};

export const addTagBioFacilityAction = (facilityId, tag, callback) => {
  return (dispatch) => {
    addTagBioFacilityService(facilityId, tag)
      .then((response) => {
        callback(response.data);
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      });
  };
};

export const removeTagBioFacilityAction = (facilityId, tag, callback) => {
  return (dispatch) => {
    removeTagBioFacilityService(facilityId, tag)
      .then((response) => {
        callback(response.data);
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      });
  };
};

export const getCryptoWalletByBioFacilityAction = (facilityId, callback) => {
  return (dispatch) => {
    getCryptoWalletByBioFacilityService(facilityId)
      .then((response) => {
        callback(response.data);
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      });
  };
};

export const addCryptoToWalletByBioFacilityAction = (data, callback) => {
  return (dispatch) => {
    addCryptoToWalletByBioFacilityService(data)
      .then((response) => {
        callback(true);
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      });
  };
};

export const removeCryptoToWalletByBioFacilityAction = (facilityId, tag) => {
  return (dispatch) => {
    removeCryptoToWalletByBioFacilityService(facilityId, tag)
      .then((response) => {})
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      });
  };
};

export const getBioFacilityTypeaheadSearchAction = (keyword) => {
  return getBioFacilityTypeaheadSearch(keyword);
};

export const createMessageContactSupportAction = (
  comments,
  listingId,
  facilityId,
  supportId,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: OPEN_LOADING, payload: {} });
    createMessageContactSupportService(
      comments,
      listingId,
      facilityId,
      supportId,
    )
      .then((response) => {
        callback(true);
      })
      .catch((response) => {
        callback(false);
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      })
      .finally(() => {
        dispatch({ type: CLOSE_LOADING, payload: {} });
      });
  };
};

export const updatePreferencesAction = (preferences, callback) => {
  return (dispatch) => {
    dispatch({ type: OPEN_LOADING, payload: {} });
    updatePreferencesService(preferences)
      .then((response) => {
        callback();
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      })
      .finally(() => {
        dispatch({ type: CLOSE_LOADING, payload: {} });
      });
  };
};

export const getCityStateAction = (body, callback) => {
  return (dispatch) => {
    dispatch({ type: OPEN_LOADING, payload: {} });
    getCityState(body)
      .then((response) => {
        callback(response?.data);
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      })
      .finally(() => {
        dispatch({ type: CLOSE_LOADING, payload: {} });
      });
  };
};

export const addNewBillingFormAction = (body, callback) => {
  return (dispatch) => {
    dispatch({ type: OPEN_LOADING, payload: {} });
    addNewBillingForm(body)
      .then((response) => {
        callback();
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      })
      .finally(() => {
        dispatch({ type: CLOSE_LOADING, payload: {} });
      });
  };
};
export const getBillingFormAction = (body, callback) => {
  return (dispatch) => {
    dispatch({ type: OPEN_LOADING, payload: {} });
    getBillingDetailsList(body)
      .then((response) => {
        callback(response?.data);
      })
      .catch((response) => {
        console.error(response);
        dispatch({ type: SETTINGS_RENDER_ERROR, payload: {} });
      })
      .finally(() => {
        dispatch({ type: CLOSE_LOADING, payload: {} });
      });
  };
};
