import React, {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import * as queryString from 'querystring';

export const useQueryParamsHook = (defaults: { [key: string]: string }): { [key: string]: string } => {
    const queries: { [key: string]: string } = defaults;
    const {search} = useLocation();
    const parse = queryString.parse(search);
    for (const [key, value] of Object.entries(parse)) {
        let key_clean = key.replace('?', '');
        queries[key_clean] = !!value.length ? value[0] : queries[key_clean] || '';
    }
    return queries;
};


export function usePrevious<T = any>(value: T) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = React.useRef<T>();

    // Store current value in ref
    React.useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
};

export const AuthContext = React.createContext<{ authenticated: boolean, setAuthenticated: (isAuthenticated: boolean) => void }>({
    authenticated: false,
    setAuthenticated: () => {
    }
});

export const useAuthContext = () => {
    return useContext(AuthContext);
};
