import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';

const uuid = require('react-uuid');

export const Collapse = ({
                             id = `${uuid()}`,
                             className = '',
                             header,
                             children
                         }: { id: string, className?: string, header: (open: boolean, handleOpen: () => void) => JSX.Element, children: JSX.Element[] | JSX.Element }) => {

    const [detailsForm, setDetailsForm] = React.useState<{ options: { show: boolean } }>({
        options: {
            show: false,
        }
    });

    const handleOpenAndClose = () => {
        setDetailsForm({
            ...detailsForm,
            options: {...detailsForm.options, show: !detailsForm.options.show}
        });
    };

    const headerPanelRender_ = () => {
        return (header(detailsForm.options.show, handleOpenAndClose));
    };

    return (
        <div className={`${className}`}>

            {headerPanelRender_()}

            <AnimatePresence initial={false}>
                {detailsForm.options.show && (
                    <motion.div key={`${id}`}
                                initial={'collapsed'}
                                animate={'open'}
                                exit={'collapsed'}
                                variants={{
                                    open: {opacity: 1, height: 'auto'},
                                    collapsed: {opacity: 0, height: 0}
                                }}
                                transition={{duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98]}}>
                        {children}
                    </motion.div>)}
            </AnimatePresence>

        </div>
    );
};
