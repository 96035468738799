import React, {useState} from 'react';
import {Detail} from './Detail';
import {currencyFormatDefault, formatDefault} from '../../../Utilities/NumberUtility';
import {FILTERS} from './Filters';
import {useHistory} from 'react-router-dom';
import {optimize} from '../../../Utilities/ImageUtility';

export interface ListingType {
    listingUid: string,
    inventoryItem: {
        facilityId: string, facilityName: string, facilityLicenseNumber: string, facilityLogo: string, productName: string, label: string, packagedDate: string, productCategoryName?: string,
    },
    listingPrice: number,
    availableQuantity: number,
    quantityType: string,
    dealStatus: string,
    tags: string[],
    name: string,
    description: string,
    facilityBio: {
        bioHeadline: string,
        bioTextData: string
    },
    image: { url: string, mediaType: string, urlTiny:string },
    images: { url: string, mediaType: string,urlTiny:string }[],
    videos?: { url: string, active: boolean }[],
    publicUrl?: string,
    packagedDate?: string,
    facilityName?: string,
    facilityLicenseNumber?: string,
    itemStrainName?: string,
    facilityLogo?: string
}

export const Product = ({data, mode,show}: { data: ListingType, mode: 'public' | 'private',show? : Boolean | undefined }) => {

    const history = useHistory();
    const [productForm, setProductForm] = useState<{ showDetail: boolean }>({
        showDetail: false
    });

    const showTagsRender_ = (tags: string[]) => {
        if (!tags)
            return null;

        const options: { key: string, icon?: string }[] = [];

        FILTERS.filter(f => {
            let find = tags.includes(f.key);
            if (find) {
                options.push({key: f.key, icon: f.icon?.replace('.svg', '-white.svg')});
            } else if (f.tags.length !== 0) {
                let tag: any = f.tags.filter(ff => tags.includes(ff.key));
                if (!!tag.length) {
                    for (const index in tag) {
                        if (!options.includes(tag[index].key))
                            options.push({key: tag[index].key, icon: (tag[index].icon).replace('.svg', '-white.svg')});
                    }
                }
            }
        });
        return (
            <React.Fragment>
                {options && options.map((element, index) => (
                    <div key={index} className={'odt-card-product-icon'} style={{display: 'inline-block'}}>
                        <img src={`/customized/images/icons/${element.icon}`} style={{width: '100%', height: '100%'}}/>
                    </div>
                ))}
            </React.Fragment>);
    };

    return (
        <React.Fragment>
            {productForm.showDetail ? (<Detail listingId={data?.listingUid} facilityId={data?.inventoryItem?.facilityId} close={() => setProductForm({
                ...productForm,
                showDetail: false
            })}/>) : null}
            <div className={'odt-card-product'} style={{cursor: 'pointer'}}
                 onClick={() => {
                     if (data?.publicUrl) {
                         const target = new URL(data.publicUrl);
                         history.push(target.pathname);
                     } else {
                         setProductForm({...productForm, showDetail: true});
                     }
                 }}>

                <div className={'odt-card-product-header'}>

                    <img src={optimize((!!data?.images?.length && (data?.images[0]?.urlTiny || data?.images[0]?.url)) || (data?.image?.urlTiny || data?.image?.url),768, (!!data?.images?.length && !!data?.images[0]?.urlTiny) || !!data?.image?.urlTiny)}
                         alt={'Product'} style={{
                        height: '200px',
                        width: '100%',
                        borderStartStartRadius: '8%',
                        borderStartEndRadius: '8%',
                        objectFit: 'cover'
                    }}/>

                    <span className={'text-center odt-font-18 odt-font-family-primary-bold odt-card-product-header-tag'}>{currencyFormatDefault(data?.listingPrice)}</span>
                </div>

                <div className={'odt-card-product-body odt-font-family-secondary'}>

                    <span className={'text-start odt-font-family-secondary-bold'} style={{overflowWrap: 'break-word'}}> {data?.name} </span>

                    <div className={'odt-card-product-separator'}/>

                    <div className={'odt-card-product-detail'}>

                        <div className={'d-inline-block text-end'} style={{width: '100%'}}>
                            <div className={'d-flex justify-content-between'}>
                                <span className={'odt-card-product-text'}>{mode === 'public' ? data?.inventoryItem?.productCategoryName : data?.inventoryItem?.productName}</span>
                                <span className={'odt-card-product-text'}>{data?.packagedDate}</span>
                            </div>
                        </div>
                        {show && (
                            <div className='odt-card-product-icon float-end mb-0'>
                                <img src={data?.facilityLogo} alt={'FacilityLogo'} />
                            </div>
                        )}
                        <div className={'d-inline-block'} style={{paddingBottom: '5px'}} >
                            <span className={'odt-card-product-text'}>{data?.inventoryItem?.facilityName}</span>
                            {show && (
                                    <>
                                        <span className={'odt-card-product-text'}>{data?.facilityName || ''}</span>
                                        <span className={'odt-card-product-text'}>{data?.facilityLicenseNumber || ''}</span>
                                        <span className={'odt-card-product-text'}>{data?.itemStrainName || ''}</span>
                                    </>
                                    )
                            }
                            <span className={'odt-card-product-text'}>{`${formatDefault(data?.availableQuantity)} ${data?.quantityType}`}</span>
                        </div>

                        <div className={'d-inline-block text-end'} style={{width: '100%'}}>
                            {data?.tags && (showTagsRender_(data.tags))}
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
};
