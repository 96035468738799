import { post } from "../../Utilities/HttpClientUtility";
import { getHttpHeaders, getNoAuthHttpHeaders } from "./CommonsAPIs";

export const userAuthService = (username = "", password = "", key = "") => {
  const body = {
    userName: username,
    password: password
  };
  const headers = {
    "X-device-id": `${key}`
  };
  return post(`/api/login`, body, headers);
};

export const forgotPasswordAuthentication = (username = "") => {
  const body = {
    userName: username
  };
  const headers = {
    ...getNoAuthHttpHeaders()
  };
  return post(`/forgot_password`, body, headers);
};

export const refreshAuthService = (headers?: { Authorization: string; "X-device-id": string }) => {
  const headersFinally = {
    ...getHttpHeaders(),
    ...headers
  };
  return post(`/api/checkValidity`, {}, headersFinally);
};

export const refreshTemporaryAuthenticationService = (token: string, device: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "X-device-id": `${device}`
  };
  return post(`/api/checkValidity`, {}, headers);
};
